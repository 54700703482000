import styled from 'styled-components';
import colors from 'constants/colors';

export const HistoryTable = styled.table`
  width: calc(100% + 30px);
  color: ${colors.white1};
  border-bottom: 15px solid ${colors.grey2};
  margin: 0 -15px 0 -15px;

  thead{
    th {
      color: ${colors.grey5};
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 2.5px;
      padding: 5px 5px;
      background-color: ${colors.grey2};
    }

    .arrival {
      width: 30%;
      padding-left: 15px;
    }
    .departure {
      width: 30%;
    }
    .roomType {
      width: 25%;
    }
    .details {
      width: 15%;
      padding-right: 15px;
    }
  }

  tbody{

    tr:nth-child(odd) {
      background-color: ${colors.grey3};
    }
    tr:nth-child(even) {
      background-color: ${colors.black4};
    }
    
    td {
      color: ${colors.white1};
      padding: 7px 5px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    td:first-child {
      padding-left: 15px;
    }

    td:last-child {
      text-align: right;
      padding-right: 15px;

      a {
        display: flex;
        color: ${colors.blue2};
        justify-content: center;
      }
    }
  }
`;

export const HistoryContainer = styled.div`
  width: 100%;
`;

export const NavigatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
`;

export const Navigator = styled.div`
  color: ${colors.blue1};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  ${(props) => props.disabled ? `
    pointer-events:none;
    color: ${colors.grey8}
  `: null }
`;