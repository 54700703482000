import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import CheckInError from '../CheckInError';
import EarlyCheckIn from '../EarlyCheckIn';
import ConfirmIdentity from '../ConfirmIdentity';
import CustomerIdentification from '../CustomerIdentification';

const CheckInContent = ({bookings}) => {

  return (
    <Switch>
      <Route
        path={'/group-check-in/early-check-in/:groupId'}
        render={() => <EarlyCheckIn bookings={bookings}/>} 
      />
      <Route
        path={'/group-check-in/confirm-identity/:groupId'}
        render={() => <ConfirmIdentity />} 
      />
      <Route
        path={'/group-check-in/customer-identification/:groupId'}
        render={() => <CustomerIdentification />} 
      />
      <Route
        path={'/group-check-in/:groupId'}
        render={() => <CheckInError />} 
      />
      <Redirect to={'/group-check-in/:groupId'} />
    </Switch>
  );
};

export default CheckInContent;