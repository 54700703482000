import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.span`
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: ${colors.white1};
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-top: 15px;
    margin-bottom: 10px;
    color: ${colors.white1};
  }
`;

export const Footer = styled.div`
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;
`;
