import React from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Notes from 'components/Notes';
import { RoomInputRow, RoomInputContainer, NoteHeader } from './styles';
import { DELETE_ROOM_NOTE_BY_ROOM_NOTE_ID } from 'graphql/service';
import { useMutation } from '@apollo/client';
import { message } from 'antd';
import { ADVANCED, RESERVATION } from 'constants/ratePlanId';
import { getMessageTimeOut } from 'utils/util';
import colors from 'constants/colors';

const Room = ({
  roomNotes,
  roomType,
  ratePlanId,
  roomNumber,
  roomPrice,
  addNoteHidden,
  refetch,
  disabled,
}) => {

  const pageSize=3

  const [removeNote] = useMutation(DELETE_ROOM_NOTE_BY_ROOM_NOTE_ID, {
    onCompleted: refetch,
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const onRemoveNote = async (noteId) => {
    removeNote({ variables: { noteId: noteId } })
  }

  return (
    <>
      <Card>
        <CardHeader>
          <div className="type">ROOM</div>
        </CardHeader>
        <RoomInputContainer>
          <RoomInputRow>
            <Input
              disabled={disabled}
              mainPage
              title="ROOM #"
              value={roomNumber}
              additionalstyle={`
                width: 20%;
              `}
            />
            <Dropdown
              disabled={disabled}
              mainPage
              value={roomType.name}
              title="ROOM TYPE"
              additionalstyle={`
                width: 80%;
              `}
            />
          </RoomInputRow>
          <RoomInputRow>
            <Dropdown
              disabled={disabled}
              mainPage
              options={[
                { label: "Advanced", value: ADVANCED },
                { label: "Reservation", value: RESERVATION },
              ]}
              value={ratePlanId === ADVANCED ? "Advanced" : "Reservation"}
              title="RATE PLAN"
              additionalstyle={`
                width: 68%;
              `}
            />
            <Input
              disabled={disabled}
              mainPage
              title="PRICE"
              value={roomPrice.toFixed(2)}
              additionalstyle={`
                width: 32%;
                input {
                  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='2' y='13' fill='${disabled? colors.grey30.replace("#", "%23"): colors.white1.replace("#", "%23")}' font-size='14' font-weight='600' font-family='Titillium Web, sans-serif'>%24</text></svg>");
                  background-repeat: no-repeat;
                  background-position: 9px 50%;
                  padding-left: 21px;

                  :disabled {
                    padding-left: 21px;
                  }
                }
              `}
            />
          </RoomInputRow>
        </RoomInputContainer>
        <NoteHeader>NOTES</NoteHeader>
        <Notes
          notes={roomNotes}
          onRemoveNote={onRemoveNote}
          pageSize={pageSize}
          addNoteHidden={addNoteHidden}
        />
      </Card>
    </>
  );
};

export default Room;
