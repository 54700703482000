import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { Container } from './styles';
import { TooltipIcon } from 'assets/icons';
import moment from 'moment';

const TransactionTooltip = ({
  createdAt,
  amount,
  warning,
  originator,
  cardNumber,
  description,
  isVoided,
  voidedAt,
  voidOriginator,
  voidDescription
}) => {

  return (
    <Tooltip
      placement="right"
      trigger={['hover']}
      overlay={
        <Container>
          <div className="item">
            <span className='description'>Date: </span>
            <span className='createdAt'>
              {moment(createdAt).format('M/DD/YYYY h:mm A')}
            </span>
          </div>
          <div className="item">
            <span className='itemTitle'>Amount: </span>
            <span className={"amount"+ (warning? " highlight": "")}>{amount}</span>
          </div>
          <div className="item">
            <span className='itemTitle'>Card: </span>
            <span className="cardNumber"> {'XXXX XXXX XXXX ' + cardNumber}</span>
          </div>
          <div className="item">
            <span className='itemTitle'>Originator: </span>
            <span className="originator">{originator}</span>
          </div>
          <div className="item">
            <span className='itemTitle'>Details: </span>
            <span className="description">{description}</span>
          </div>
          {isVoided && (
            <>
              <div className="item">
                <span className='itemTitle'>Voided At: </span>
                <span className='voidedAt'>
                  {moment(voidedAt).format('M/DD/YYYY h:mm A')}
                </span>
              </div>
              <div className="item">
                <span className='itemTitle'>Void Originator: </span>
                <span className="voidOriginator">{voidOriginator}</span>
              </div>
              <div className="item">
                <span className='itemTitle'>Void Description: </span>
                <span className="voidDescription">{voidDescription}</span>
              </div>
            </>
          )}
        </Container>
      }
    >
      <TooltipIcon />
    </Tooltip>
  );
}

export default TransactionTooltip;
