import styled from 'styled-components';
import colors from 'constants/colors';
import { SubHeaderContainer } from 'screens/Reports/DailyReconciliation/style';

export const PriceSubHeaderContainer = styled(SubHeaderContainer)``;

export const PriceTableContainer = styled.table`
  width: 100%;
  height: 200px;
  color: ${colors.white1};
  font-size: 14px;

  th {
    border-top: 1px solid ${colors.grey23};
    border-right: 1px solid ${colors.grey23};
    border-bottom: 1px solid ${colors.white1};
    padding: 10px 10px;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1.5px;
  }

  td {
    height: 44px;
    padding: 5px 10px;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: ${colors.black7}
    }
    tr:nth-child(even) {
      background-color: ${colors.grey3}
    }
  }

  .titleColumn {
    width: 12%;
    border-right: 1px solid ${colors.white1};
  }
  .dataColumn {
    width: 6%;
  }

  .title {
    text-align: right;
    font-weight: bold;
  }

  .titleBorder {
    border-right: 1px solid ${colors.white1};
    border-bottom: 1px solid ${colors.grey23};
  }

  .subTitle {
    font-weight: normal;
  }

  .commonColumn {
    border: 1px solid ${colors.grey23};
  }

  .bottomRow {
    border-bottom: 1px solid ${colors.white1};
  }

  .bottomColumn {
    border-right: 1px solid ${colors.grey23};
  }

  .bottomBorder {
    border-right: 1px solid ${colors.white1};
  }
`;
