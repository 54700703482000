import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { cleanGuest, cleanSelection } from 'redux/actions'
import { useSwipeable } from 'react-swipeable';
import MessagesScreen from 'screens/MessagesScreen';
import AlertsScreen from 'screens/AlertsScreen';
import SearchResults from 'screens/SearchResults';
import { Footer, Navigator } from './styles';
import HomeIcon from 'assets/svg/Home.svg';
import BellIcon from 'assets/svg/Bell.svg';
import MessageIcon from 'assets/svg/Message.svg';
import SearchIcon from 'assets/svg/Search.svg'

const FooterBar = () => {

  const dispatch = useDispatch()
  const [activeStatus, setActiveStatus] = useState(0)

  useEffect(()=>{
    if(activeStatus===1 || activeStatus===2 || activeStatus===3) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    if(activeStatus) {
      dispatch(cleanSelection())
      dispatch(cleanGuest())
    }
  },[activeStatus])

  const nextPage = () => {
    if(activeStatus<3) {
      setActiveStatus(activeStatus+1)
    }
  }

  const previousPage = () => {
    if(activeStatus>0) {
      setActiveStatus(activeStatus-1)
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => nextPage(),
    onSwipedRight: () => previousPage(),
    trackTouch: true,
    trackMouse: true
  })

  useEffect(() => {
    document.querySelector('#root').onmousedown = handlers.onMouseDown
    handlers.ref(document.querySelector('#root'))
    return () => {
      document.querySelector('#root').onmousedown = null
      handlers.ref(undefined)
    }
  },[])

  return (
    <>
      <Footer>
        <Navigator
          active={activeStatus===0}
          onClick={()=>{
            setActiveStatus(0)
          }}
        >
          <img src={HomeIcon} alt={'homeIcon'} />
          <div>HOME</div>
        </Navigator>
        <Navigator
          active={activeStatus===1}
          onClick={()=>{
            setActiveStatus(1)
          }}
        >
          <img src={BellIcon} alt={'alertsIcon'} />
          <div>ALERTS</div>
        </Navigator>
        <Navigator
          active={activeStatus===2}
          onClick={()=>{
            setActiveStatus(2)
          }}
        >
          <img src={MessageIcon} alt={'messagesIcon'} />
          <div>MESSAGES</div>
        </Navigator>
        <Navigator
          active={activeStatus===3}
          onClick={()=>{
            setActiveStatus(3)
          }}
        >
          <img src={SearchIcon} alt={'searchIcon'} />
          <div>SEARCH</div>
        </Navigator>
      </Footer>
      <AlertsScreen mobile open={activeStatus===1}/>
      <MessagesScreen mobile open={activeStatus===2}/>
      <SearchResults mobile open={activeStatus===3}/>
    </>
  )
}

export default FooterBar;