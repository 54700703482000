import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import { Container, Footer, Head } from './styles';

const ModalBookingCancel = ({
  hidden,
  danger,
  buttonStyle,
  buttonLabel="Cancel This Room",
  setCancelRoomParams,
  setConfirmIsOpen,
  checkedIn,
}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [cancellationOption, setCancellationOption] = useState('-Select-') 
  const [cancellationReason, setCancellationReason] = useState('')  
  const [invalidateRoomCard, setInvalidateRoomCard] = useState(true)
  const [cancelPayment, setCancelPayment] = useState(true)
  const [cancellationFee, setCancellationFee] = useState(true)
  const [dropDownError, setDropDownError] = useState(false)
  const [textAreaError, setTextAreaError] = useState(false)

  const handleSubmit = () => {
    if(cancellationOption === "-Select-") {
      setDropDownError(true)
    } else if(cancellationOption === "Other" && !cancellationReason) {
      setTextAreaError(true)
    } else {
      setCancelRoomParams({
        cancellationReason: cancellationOption==='Other'? cancellationReason: cancellationOption,
        cancellationFee: cancellationFee,
        invalidateRoomCard: invalidateRoomCard,
        cancelPayment: cancelPayment
      })
      setConfirmIsOpen(true)
      setIsOpen(false)
    }
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-danger"
      buttonLabel={buttonLabel}
      danger={danger}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          .close {
            z-index: 200;
          }
          .modal-header {
            .modal-title {
              font-size: 22px;
              font-weight: 600;
            }
          }          
          .modal-body {
            height: 100%;
            padding: 24px;
          }
        }
        top: 136px;
      `}
    >
      <Head>Cancel this room?</Head>
      <Container>
        {checkedIn? (
          <div className="groupTitle">
            <span className='warning'>Warning: </span> This room is currently checked in, are you sure you want to cancel?
          </div>
        ): (
          <div className="groupTitle">
            What is the reason for the cancellation?
          </div>
        )}
        <Dropdown
          name="reason"
          options={[
            "Change of Plans",
            "Personal Reasons",
            "Found a Better Option",
            "Scheduling Conflict",
            "Health Issues",
            "Other",
          ]}
          error={dropDownError}
          value={cancellationOption}
          title="REASON FOR CANCELLATION"
          onChange={(e) => {
            setCancellationReason("");
            setCancellationOption(e);
            setDropDownError(false);
          }}
          additionalstyle={`
            width: 100%;
          `}
        />
        {cancellationOption === "Other" && (
          <TextArea
            title="TYPE A REASON HERE:"
            error={textAreaError}
            value={cancellationReason}
            onChange={(e) => {
              setCancellationReason(e.target.value);
              setTextAreaError(false);
            }}
            additionalstyle={`
              width: 100%;
              
              textarea {
                min-height: 54px;
                height: 54px;
            }
          `}
          />
        )}
        <div className='checkboxes'>
          <Checkbox
            checked={invalidateRoomCard}
            onChange={() => setInvalidateRoomCard(!invalidateRoomCard)}
            title="Invalidate room card"
          />
          <Checkbox
            checked={cancelPayment}
            onChange={() => setCancelPayment(!cancelPayment)}
            title="Cancel the customer’s payment"
          />
          <Checkbox
            checked={cancellationFee}
            onChange={() => setCancellationFee(!cancellationFee)}
            title="Apply cancellation fee"
          />
        </div>
      </Container>
      <Footer>
        <Button
          danger
          additionalstyle={`
            width: 84px;
          `}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  );
}

export default ModalBookingCancel;