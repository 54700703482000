import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import ModalTransactionSuccessfully from 'screens/ModalTransactionSuccessfully';
import ModalTransactionFailed from 'screens/ModalTransactionFailed';
import { useMutation } from '@apollo/client';
import { CREATE_REFUND } from 'graphql/service';
import { Head, Container, Footer } from './styles';

const ModalRefund = ({
  hidden,
  disabled,
  buttonStyle,
  transactionId,
  cardNumber,
  max,
  refetch
}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [successfullyIsOpen, setSuccessfullyIsOpen] = useState(false)
  const [failedIsOpen, setFailedIsOpen] = useState(false)
  const [refundDetails, setRefundDetails] = useState('')
  const [refundAmount, setRefundAmount] = useState('')
  const [textAreaError, setTextAreaError] = useState(false)
  const [inputError, setInputError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [createRefund, {loading}] = useMutation(CREATE_REFUND, {
    onCompleted: ()=>{
      setSuccessfullyIsOpen(true)
      setIsOpen(false)
    },
    onError: (error) => {
      setFailedIsOpen(true)
      setIsOpen(false)
    }
  })

  const handleRefund = () => {
    if(loading) return
    let error = false
    if(refundDetails === '') {
      setTextAreaError(true)
      error = true
    }
    if(refundAmount === '') {
      setInputError(true)
      setErrorMessage('Refund amount is required.')
      error = true
    } else if(Number(refundAmount) > max) {
      setInputError(true)
      setErrorMessage('Refund cannot be more than the total amount.')
      error = true
    }

    if(error) return 
    createRefund({
      variables: {
        input: {
          transactionId,
          refundAmount: Number(refundAmount),
          reason: refundDetails
        },
      },
    });
  }

  return (
    <>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="modal-success"
        buttonLabel="Refund"
        buttonStyle={buttonStyle}
        disabled={disabled}
        hidden={hidden}
        additionalstyle={`
          max-width: 500px;
          .modal-content{
            .close {
              z-index: 200;
            }
            .modal-body {
              height: 100%;
              padding: 24px;
            }
          }
          top: 139px;
        `}
      >
        <Head>Refund</Head>
        <Container>
          <TextArea      
            title="REFUND DETAILS"
            required
            rows={4}
            error={textAreaError}
            value={refundDetails}
            onChange={(e) => {
              setRefundDetails(e.target.value)
              setTextAreaError(false)
            }}
            additionalstyle={`
              flex-basis: 100%;
            `}
          />
        </Container>
        <Footer>
          <div className="form">
            <Input
              title="REFUND AMOUNT"
              width="100%"
              required
              error={inputError}
              errorMessage={errorMessage}
              value={refundAmount}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
                  setRefundAmount(inputValue);
                  setInputError(false)
                }
              }}
              additionalstyle={`
                width: 31%;

                input {
                  padding-left: 22px;
                }

                ::before {
                  content: '$';
                  font-size: 16px;
                  font-weight: 600;
                  position: absolute;
                  left: 12px;
                  top: 32px;
                }

                .warning {
                  white-space: nowrap;
                }
              `}
            />
            <Button
              success
              loading={loading}
              additionalstyle={`
                width: 84px;
                margin-top: 25px;
              `}
              onClick={handleRefund}
            >
              {loading? null: 'Submit'}
            </Button>
          </div>
          <div className="text">{`You may refund up to a maximum of the total for this booking: $${Number(max).toFixed(2)}`}</div>
        </Footer>
      </MainModal>
      <ModalTransactionSuccessfully
        isOpen={successfullyIsOpen}
        setIsOpen={setSuccessfullyIsOpen}
        amount={refundAmount}
        cardNumber={cardNumber}
        setAmount={setRefundAmount}
        setDetails={setRefundDetails}
        refetch={refetch}
        type='refund'
      />
      <ModalTransactionFailed
        isOpen={failedIsOpen}
        setIsOpen={setFailedIsOpen}
        amount={refundAmount}
        cardNumber={cardNumber}
        loading={loading}
        retryFunction={handleRefund}
        type='refund'
      />
    </>
  )
}

export default ModalRefund;
