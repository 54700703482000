import styled from 'styled-components'
import Search from 'assets/svg/Search.svg'
import colors from 'constants/colors'

export const Container = styled.div`
  position: absolute;
  top: 1px;
  right: 210px;
  width: 100%;
  max-width: 423px;
  align-items: center;
  flex-direction: column;  
  overflow-y: hidden;
  z-index: 200;
  margin-right: 3px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

export const StyledInput = styled.input`
  width: 100%;
  height: 48px;
  color: ${colors.white1};
  font-size: 18px;
  font-weight: 600;
  display: block;
  background-image: url(${Search});
  background-color: ${colors.grey28};
  background-repeat: no-repeat;
  background-position: 2% 50%;
  padding: 0 25px 0 40px;
  box-sizing: border-box;
  border: none;
  outline: none;

  :focus {
    border: solid ${colors.white1};
    border-width: 2px 2px 2px 2px;
  }
`;

export const StyledList = styled.div`
  border: solid ${colors.white1};
  border-width: 0px 2px 2px 2px;
`;

export const StyledItem = styled.div`
  width: 100%;
  height: 36px;
  line-height: 35px;
  font-size: 14px;
  font-weight: normal;
  padding-left: 20px;
  color: ${colors.grey5};
  background-color: ${colors.black5};
  overflow: hidden;
  cursor: pointer;

  .type {
    font-style: italic;
  }

  .highlight {
    color: ${colors.white1};
  }

  :hover {
    color: ${colors.blue1};
    border-left: 5px solid ${colors.blue1};
    background-color: ${colors.black9};
    padding-left: 15px;

    .highlight {
      font-weight: bold;
      color: ${colors.blue1};
    }
  }
`;