import styled from 'styled-components';
import Search from 'assets/svg/Search.svg'
import colors from 'constants/colors';

export const StaffsContainer = styled.div`
  position: fixed;
  top: 100px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.black5};
  height: calc(100vh - 50px);
  padding: 20px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    height: calc(100vh - 110px);
  }

  @media only screen and (max-width: 767px) {
    height: calc(100vh - 95px);
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.grey2};

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    padding: 0 15px;

    .header {
      padding-left: 0px;
      height: 100px;
    }
  }

  @media only screen and (max-width: 767px) {

    padding: 0 15px;

    .header {
      padding-left: 0px;
      height: 63px;
    }
  }
`;

export const StyledInput = styled.input`
  width: 258px;
  height: 40px;
  color: ${colors.white1};
  font-size: 18px;
  font-weight: 600;
  display: block;
  background-image: url(${Search});
  background-color: ${colors.grey2};
  background-repeat: no-repeat;
  background-position: 15px 50%;
  padding: 0 25px 0 45px;
  border: 2px solid ${colors.grey9};
  outline: none;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    width: 60%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  background-color: ${colors.grey2};

  thead {
    height: 49px;
    vertical-align: bottom;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.white1};
    border-bottom: 1px solid ${colors.white1};

    .name {
      width: 12%;
      padding: 10px 20px;
    }

    .role {
      width: 12%;
      padding: 10px 20px;
    }

    .phone {
      width: 12%;
      padding: 10px 20px;
    }

    .email {
      width: 18%;
      padding: 10px 20px;
    }

    .status {
      width: 10%;
      padding: 10px 20px;
    }

    .created {
      width: 13%;
      padding: 10px 20px;
    }

    .lastLogin {
      width: 13%;
      padding: 10px 20px;
    }

    .action {
      width: 10%;
      padding: 10px 20px;
    }
  }
`;

export const StyledItem = styled.tr`
  width: 100%;
  height: 51px;
  line-height: 36px;
  font-size: 11px;
  font-weight: 600;
  color: ${colors.white1};
  background-color: ${colors.grey2};
  overflow: hidden;
  border-bottom: 1px solid ${colors.grey23};
  padding: 16px 8px;

  td {
    position: relative;
    padding: 0 0 0 20px;

    .iconContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 24px;

      .icon {
        cursor: pointer;
      }
    }
  }
  
  .link {
    color: ${colors.blue1};
    display: none;
  }
`;

export const NavigatorContainer = styled.div`
  width: 100%;
  display: flex;
  height: 47px;
  justify-content: space-between;
  align-items: center;
  padding: 0 40%;
  background-color: ${colors.grey2};

  .pageControl {
    display: flex;
  }

  .totalResults {
    position: absolute;
    left: 20px;
    font-size: 11px;
    font-weight: 600;
    color: ${colors.white1};
  }

  .totalPages {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.blue1};
  }

  input {
    width: 25px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    margin-right: 5px;
    border: none;

    :focus-visible {
      outline: none;
    }

    ::-webkit-inner-spin-button, 
    ::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    padding: 0 30%;

    .totalResults {
      left: 35px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 0 20%;
    .totalResults {
      display: none;
    }
  }
`;

export const Navigator = styled.div`
  color: ${colors.blue1};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  ${(props) => props.disabled ? `
    pointer-events:none;
    color: ${colors.grey8}
  `: null }
`;
