import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import { useMutation } from '@apollo/client';
import { DELETE_BOOKING_BY_BOOKING_ID, DELETE_GROUP_BY_GROUP_ID } from 'graphql/service';
import colors from 'constants/colors';
import { Container } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalCancelConfirm  = ({
  isOpen,
  setIsOpen,
  refetch,
  cancelRoomParams,
  setCancelRoomParams,
  roomNumber,
  bookingId,
  groupId,
  fullName
}) => {

  const [deleteBookingByBookingId] = useMutation(
    DELETE_BOOKING_BY_BOOKING_ID,
    {
      onCompleted: (data) => {
        if(data.deleteBooking.status === 'CANCELLED') {
          message.success('Booking has been deleted')
          setCancelRoomParams({})
          setIsOpen(false)
          if(refetch) {
            refetch()
          }
        } else {
          const content = 'Delete booking failed'
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  const [deleteGroupByGroupId] = useMutation(
    DELETE_GROUP_BY_GROUP_ID,
    {
      onCompleted: (data) => {
        if(data.deleteGroup.isDeleted === true) {
          message.success('Group has been deleted')
          setCancelRoomParams({})
          setIsOpen(false)
          if(refetch) {
            refetch()
          }
        } else {
          const content = 'Delete group failed'
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  const handleCancelRoom = () => {
    const cancellationOptionIds =[]
    if(cancelRoomParams.cancellationFee) {
      cancellationOptionIds.push("cf8c0cda-2635-47bf-8ebc-476c8b7a76be")
    }
    if(cancelRoomParams.invalidateRoomCard) {
      cancellationOptionIds.push("06c716ee-82aa-456f-afad-e8adbcf2ad7d")
    }
    if(cancelRoomParams.cancelPayment) {
      cancellationOptionIds.push("07ae7494-4c67-4d67-8d6f-7a268de0b5fa")
    }

    if(bookingId) {
      deleteBookingByBookingId({
        variables: {
          bookingId: bookingId,
          input: {
            cancellationReason: cancelRoomParams.cancellationReason,
            cancellationOptionIds: cancellationOptionIds
          }
        },
      }) 
    } else if(groupId) {
      deleteGroupByGroupId({
        variables: {
          groupId: groupId,
          input: {
            cancellationReason: cancelRoomParams.cancellationReason,
            cancellationOptionIds: cancellationOptionIds
          }
        }
      })
    } else {
      message.error('No bookingId or groupId provided')
    }
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-danger"
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
    >
      <Container>
        <div className='text'>Are you sure you want to cancel{bookingId?' room ' + roomNumber: ' the entire booking for ' + fullName}?</div>
        <Button
          danger
          onClick={handleCancelRoom}
          additionalstyle={`
            width: 125px;
            margin-top: 24px;
          `} 
        >
          Cancel Room
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalCancelConfirm;
