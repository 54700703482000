import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import { Container } from './styles';
import { FailIcon } from 'assets/icons';

const ModalMakeCardPrimarySuccessfully = ({
  isOpen,
  setIsOpen,
  originCardNumber,
  newCardNumber,
  amount
}) => {

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-danger"
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            height: 100%;
            padding: 24px;
          }
        }
        top: 179px;
      `}
    >
      <Container>
        <FailIcon />
        <div className='title'>Failed to update the primary card.</div>
        <div className='text'>{`We were unable to place a hold for $${Number(amount).toFixed(2)} on the card ending in ${newCardNumber?.slice(-4)}. The primary card is still set to the card ending in ${originCardNumber?.slice(-4)}.`}</div>
        <Button
          danger
          onClick={() => setIsOpen(false)}
          additionalstyle={`
            width: 116px;
            margin-top: 24px;
          `} 
        >
          OK
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalMakeCardPrimarySuccessfully;
