import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCustomerInfo } from 'redux/actions'
import Button from 'components/Button';
import Header from 'components/Header';
import StatusBar from 'components/StatusBar';
import StatusTooltip from 'components/StatusTooltip';
import FooterBar from 'components/FooterBar';
import ModalAddRoom from 'screens/ModalAddRoom';
import ModalGroupCancel from 'screens/ModalGroupCancel';
import ModalCancelConfirm from 'screens/ModalCancelConfirm';
import ModalCheckOut from 'screens/ModalCheckOut';
import ModalCheckOutConfirm from 'screens/ModalCheckOutConfirm';
import ModalSettlePayment from 'screens/ModalSettlePayment';
import CreditCards from 'components/BookingMain/CreditCards';
import AddNewCreditCard from 'components/BookingMain/AddNewCreditCard';
import BookingInformation from './BookingInformation';
import GuestInformation from 'components/BookingMain/GuestInformation';
import GuestInformationImages from 'components/BookingMain/GuestInformationImages';
import GeneralGroupNotes from './GeneralGroupNotes';
import MetaInformation from 'components/BookingMain/MetaInformation';
import ActivityHistory from 'components/BookingMain/ActivityHistory';
import BillOfSale from 'components/BookingMain/BillOfSale';
import { useQuery } from '@apollo/client';
import {
  GET_GROUP_DETAILS_BY_GROUP_ID
} from 'graphql/service';
import { useCustomerForm } from 'customHook/useCustomerForm';
import {
  Content,
  ContentColumn,
  Container,
} from 'components/BookingMain/styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';
import moment from 'moment';

const GroupDetails = () => {

  const history = useHistory()
  const dispatch = useDispatch()

  const groupId = useParams().groupId

  const [groupStatus, setGroupStatus] = useState('')

  //ModalGroupCancel
  const [cancelConfirmIsOpen, setCancelConfirmIsOpen] = useState(false)
  const [cancelRoomParams, setCancelRoomParams] = useState({})
  const [primaryTenantName, setPrimaryTenantName] = useState('')

  //ModalCheckOut
  const [checkOutConfirmIsOpen, setCheckOutConfirmIsOpen] = useState(false)
  const [checkOutBookings, setCheckOutBookings] = useState([])

  //GuestInformation
  const [customerInfoError, setCustomerInfoError] = useState({})
  const { updateCustomerInfo } = useCustomerForm(setCustomerInfoError)

  const handleSaveChanges = () => {
    updateCustomerInfo()
  }

  //CreditCards
  const [paymentRecords, setPaymentRecords] = useState([])
  const [balance, setBalance] = useState(0)

  //BillOfSale
  const [bill, setBill] = useState({})

  //TransactionHistory
  const [transactions, setTransactions] = useState([])

  //GeneralGroupNotes
  const [groupNotes, setGroupNotes] = useState([])
  const [groupName, setGroupName] = useState([])

  //bookingInformation
  const [bookings, setBookings] = useState([])

  const { data: groupData, loading, error, refetch } = useQuery(GET_GROUP_DETAILS_BY_GROUP_ID, {
    variables: { groupId: groupId },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (groupData) => {
      if(groupData.groupByID) {
        const {
          name,
          primaryBookingStatus,
          bookings,
          tenants,
          groupNotes,
          paymentCards,
          bills
        } = groupData.groupByID
        setGroupStatus(primaryBookingStatus)
        setGroupNotes(groupNotes)
        setGroupName(name)
        setPaymentRecords(paymentCards || [])
        setBill(bills[0] || {})
        setTransactions(bills[0]?.transactions || [])
        setBalance(bills[0]?.balance || 0)
        setBookings(bookings)
        setPrimaryTenantName(bookings[0]?.fullName)
        let newCustomer = {}
        newCustomer.userId = tenants[0]?.tenant?.user?.userId || ''
        newCustomer.tenantId = tenants[0]?.tenant?.tenantId || ''
        newCustomer.fullName = tenants[0]?.tenant?.user?.fullName || ''
        newCustomer.email = tenants[0]?.tenant?.user?.email || ''
        newCustomer.phoneNumber = tenants[0]?.tenant?.user?.phoneNumber || ''
        newCustomer.billingAddress = tenants[0]?.tenant?.user?.address || ''
        //FIXME: document value
        newCustomer.travelDocumentNumber = ''
        newCustomer.travelDocumentType = ''
        dispatch(setCustomerInfo(newCustomer))
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const earlyCheckIn = bookings.find(booking => (moment() < moment(booking.checkInDate).set('hour', 15)) && (moment(booking.checkInDate)).set('hour', 3) <= moment())

  const checkedInBookings = bookings.filter(booking => booking.status === 'CHECKED_IN')

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const bookingInformationArray = []
  bookings.forEach((booking, index)=> {
    bookingInformationArray.push(      
      <Container
        key={booking.bookingId}
      >
        <BookingInformation
          booking={booking}
          refetch={refetch}
          index={index}
        />
      </Container>      
    )
  })

  return (
    <>
      <Helmet>
        <title>Group Details - Rook Hotel PMS</title>
      </Helmet>
      <Header
        subHeader={
          <>
            <div className="title">
              <span
                className="highlight"
                onClick={() => {
                  history.push("/bookings");
                }}
              >
                Bookings
              </span>
              {` > ${groupName}`}
              <StatusBar status={groupStatus} />
              {groupStatus === "CANCELLED" ? (
                <StatusTooltip
                  createdAt={groupData.groupByID.cancelledAt}
                  createdBy={groupData.groupByID.cancelledBy?.fullName}
                  type={"Cancellation"}
                  reason={groupData.groupByID.cancellationReason}
                  amount={groupData.groupByID.cancellationFee}
                />
              ) : null}
            </div>
            <div className="buttonContainer">
              {(groupStatus === "PENDING" || groupStatus === "CHECKED_IN") ?(<ModalAddRoom
                groupId={groupId}
                refetch={refetch}
                buttonStyle={`
                  width: 96px;
                `}
              />) : null
              }
              {groupStatus === "PENDING" ? (
                <Button
                  mainPage
                  additionalstyle={`
                      width: 126px;
                      margin-left: 8px;
                    `}
                  onClick={() => {
                    if (earlyCheckIn) {
                      history.push(`/group-check-in/early-check-in/${groupId}`);
                    } else {
                      history.push(
                        `/group-check-in/confirm-identity/${groupId}`
                      );
                    }
                  }}
                >
                  Group Check In
                </Button>
              ) : null}
              {groupStatus === "CHECKED_OUT" ? (
                <ModalSettlePayment
                  groupId={groupId}
                  primaryCard={
                    paymentRecords.find((paymentCard) => paymentCard.isPrimary) ||
                    {}
                  }
                  balance={balance}
                  refetch={refetch}
                  buttonStyle={`
                      width: 204px;
                      margin-left: 8px;
                    `}
                />
              ) : null}
              {(groupStatus === "PENDING" || groupStatus === "CHECKED_IN") ? (
                <ModalGroupCancel
                  className="cancelButton"
                  danger
                  checkedIn={checkedInBookings.length > 0}
                  buttonLabel={"Cancel Entire Group"}
                  buttonStyle={`
                      width: 144px;
                      margin-left: 8px;
                    `}
                  setCancelRoomParams={setCancelRoomParams}
                  setConfirmIsOpen={setCancelConfirmIsOpen}
                />
              ) : null}
              {checkedInBookings.length > 1 ? (
                <ModalCheckOut
                  buttonStyle={`
                    width: 138px;
                    margin-left: 8px;
                  `}
                  checkedInBookings={checkedInBookings}
                  checkOutBookings={checkOutBookings}
                  setCheckOutBookings={setCheckOutBookings}
                  setCheckOutConfirmIsOpen={setCheckOutConfirmIsOpen}
                />
              ) : null}
              {checkedInBookings.length === 1 ? (
                <ModalCheckOutConfirm
                  buttonStyle={`
                      width: 138px;
                      margin-left: 10px;
                    `}
                  buttonLabel={"Group Check Out"}
                  isOpen={checkOutConfirmIsOpen}
                  setIsOpen={setCheckOutConfirmIsOpen}
                  checkOutBookings={checkedInBookings}
                  refetch={refetch}
                />
              ) : null}
            </div>
          </>
        }
      />
      <ModalCancelConfirm
        isOpen={cancelConfirmIsOpen}
        setIsOpen={setCancelConfirmIsOpen}
        cancelRoomParams={cancelRoomParams}
        setCancelRoomParams={setCancelRoomParams}
        fullName={primaryTenantName}
        groupId={groupId}
        refetch={refetch}
      />
      {checkedInBookings.length > 1 ? (
        <ModalCheckOutConfirm
          isOpen={checkOutConfirmIsOpen}
          setIsOpen={setCheckOutConfirmIsOpen}
          checkOutBookings={checkOutBookings}
          refetch={refetch}
        />
      ) : null}
      <Content>
        <ContentColumn>
          <Container>
            <GuestInformation
              primary
              customerInfoError={customerInfoError}
              setCustomerInfoError={setCustomerInfoError}
              btnInfo={
                <Button
                  mainPage
                  success
                  onClick={handleSaveChanges}
                  additionalstyle={`
                    width: 113px;
                  `}
                >
                  Save Changes
                </Button>
              }
            />
          </Container>
          <Container>
            <GuestInformationImages />
          </Container>
          <Container>
            <BillOfSale
              bill={bill}
              primaryCard={
                paymentRecords.find((paymentCard) => paymentCard.isPrimary) ||
                {}
              }
              refetch={refetch}
              groupId={groupId}
            />
          </Container>
          <Container>
            <CreditCards
              paymentRecords={paymentRecords}
              balance={balance}
              refetch={refetch}
            />
            <AddNewCreditCard groupId={groupId} />
          </Container>
        </ContentColumn>
        <ContentColumn>
          {bookingInformationArray}
          <Container>
            <GeneralGroupNotes
              notes={groupNotes}
              groupId={groupId}
              headerString={"GENERAL GROUP NOTES"}
              refetch={() => {
                refetch().then((groupData) => {
                  setGroupNotes(groupData.data.groupByID?.groupNotes);
                });
              }}
            />
          </Container>
          <Container>
            <MetaInformation
              created={Date.now()}
              origin={"Booking.com"}
              confirmationNumber={"#########"}
              channel={"Lorem Ipsum"}
              campaignTag={"Christmas Promo 2020"}
            />
          </Container>
          <Container>
            {/* FIXME: should be group id
            <ActivityHistory
              bookingId={bookings[0]?.bookingId || ""}
            /> */}
          </Container>
        </ContentColumn>
      </Content>
      <FooterBar />
    </>
  );
}

export default GroupDetails;