import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { setCustomerInfo } from "redux/actions";
import PhoneNumberInput from "components/PhoneNumberInput";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import Card from "components/Card";
import CardHeader from "components/CardHeader";
import FormContainer from "components/FormContainer";
import SubscribeSlider from "components/SubscribeSlider";
import { usePlacesWidget } from "react-google-autocomplete";
import { InputRow } from "./styles";
import { OpenNewPageIcon } from 'assets/icons';

const GuestInformation = ({
  btnInfo,
  disabled,
  primary,
  mini,
  customerInfoError,
  setCustomerInfoError,
}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const customerInfo = useSelector(
    (state) => state.customerInfoReducer.customerInfo
  );
  const currentYear = new Date().getFullYear()
  const [phoneNumber, setPhoneNumber] = useState(customerInfo.phoneNumber);
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false);
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_APIKEY,
    onPlaceSelected: (place) => {
      dispatch(
        setCustomerInfo((customerInfo) => ({
          ...customerInfo,
          billingAddress: place.formatted_address,
        }))
      );
    },
    options: {
      types: ["address"],
    },
  });

  useEffect(() => {
    if (phoneNumberValidity) {
      if (phoneNumber !== customerInfo.phoneNumber) {
        setCustomerInfoError({ ...customerInfoError, phoneNumberError: false });
        dispatch(
          setCustomerInfo((customerInfo) => ({
            ...customerInfo,
            phoneNumber: phoneNumber,
          }))
        );
      }
    }
  }, [phoneNumberValidity]);

  return (
    <Card>
      <CardHeader
        additionalstyle={
          disabled
            ? null
            : `
          @media only screen and (max-width: 767px) {
            
            .type {
              width: 100%;
              margin-bottom: 10px; 
            }

            .buttonContainer {
              width: 100%;
              justify-content: space-between;
            }
            
            flex-direction: column;
            justify-content: center;
          }
        `
        }
      >
        <div className="type">
          <div>{(primary ? "PRIMARY " : "") + "GUEST INFORMATION"}</div>
          {customerInfo.tenantId ? (
            <div
              className="icon"
              onClick={() => {
                window.open(`https://pms.rookhotel.com/tenant-details/${customerInfo.tenantId}`);
              }}
            >
              <OpenNewPageIcon />
            </div>
          ) : null}
        </div>
      </CardHeader>
      <FormContainer>
        <InputRow>
          <Input
            disabled={disabled}
            mainPage
            title="NAME"
            value={customerInfo.fullName}
            error={customerInfoError.fullNameError}
            onChange={(e) => {
              dispatch(
                setCustomerInfo({ ...customerInfo, fullName: e.target.value })
              );
            }}
            additionalstyle={`
              width: 100%;

              @media only screen and (max-width: 767px) {
                width: 100%;
              }
            `}
          />
          <Input
            disabled={disabled}
            hidden={mini}
            mainPage
            title="ORGANIZATION"
            value={customerInfo.organization}
            error={customerInfoError.organizationError}
            onChange={(e) => {
              dispatch(
                setCustomerInfo({
                  ...customerInfo,
                  organization: e.target.value,
                })
              );
            }}
            additionalstyle={`
              width: 100%;

              @media only screen and (max-width: 767px) {
                width: 100%;
              }
            `}
          />
        </InputRow>
        <Input
          disabled={disabled}
          hidden={mini}
          mainPage
          title="ADDRESS"
          inputFieldRef={ref}
          value={customerInfo.billingAddress}
          error={customerInfoError.billingAddressError}
          onChange={(e) => {
            dispatch(
              setCustomerInfo({
                ...customerInfo,
                billingAddress: e.target.value,
              })
            );
          }}
          additionalstyle={`
            width: 100%;
          `}
        />
        <InputRow>
          <div className="email">
            <Input
              disabled={disabled}
              mainPage
              title="EMAIL"
              value={customerInfo.email}
              error={customerInfoError.emailError}
              onChange={(e) => {
                dispatch(
                  setCustomerInfo({ ...customerInfo, email: e.target.value })
                );
              }}
            />
            {customerInfo.userId ? (
              <SubscribeSlider
                type="email"
                userId={customerInfo.userId}
                left="50px"
              />
            ) : null}
          </div>
          <div className="phone">
            <PhoneNumberInput
              disabled={disabled}
              mainPage
              title="PHONE"
              error={customerInfoError.phoneNumberError}
              initialValue={customerInfo.phoneNumber}
              onChangeNumber={setPhoneNumber}
              setPhoneNumberValidity={setPhoneNumberValidity}
              onBlur={() => {
                if (!phoneNumberValidity && phoneNumber.length > 0) {
                  setCustomerInfoError({
                    ...customerInfoError,
                    phoneNumberError: true,
                  });
                }
              }}
            />
            {customerInfo.userId ? (
              <SubscribeSlider
                type="phone"
                userId={customerInfo.userId}
                left="56px"
              />
            ) : null}
          </div>
        </InputRow>
        <InputRow hidden={mini}>
          <Input
            disabled={disabled}
            mainPage
            title="TRAVEL DOCUMENT #"
            value={customerInfo.travelDocumentNumber}
            onChange={(e) => {
              dispatch(
                setCustomerInfo({
                  ...customerInfo,
                  travelDocumentNumber: e.target.value,
                })
              );
            }}
            additionalstyle={`
              width: 50%;

              @media only screen and (max-width: 767px) {
                width: 100%;
              }
            `}
          />
          <Dropdown
            disabled={disabled}
            mainPage
            options={["Passport", "Driver's License"]}
            title="TRAVEL DOCUMENT TYPE"
            value={customerInfo.travelDocumentType}
            onChange={(e) => {
              dispatch(
                setCustomerInfo({ ...customerInfo, travelDocumentType: e })
              );
            }}
            additionalstyle={`
              width: 50%;

              @media only screen and (max-width: 767px) {
                width: 100%;
              }
            `}
          />
        </InputRow>
        <InputRow hidden={mini}>
          <Input
            disabled={disabled}
            mainPage
            type="text"
            title="DATE OF BIRTH (DD/MM/YYYY)"
            value={customerInfo.dateOfBirth}
            error={customerInfoError.dateOfBirthError}
            errorMessage="Invalid date"
            onChange={(e) => {
              const input = e.target.value;

              // only allow numbers and '/'
              if (!/^\d*\/?\d*\/?\d*$/.test(input)) {
                return;
              }

              // length limit
              if (input.length > 10) {
                return;
              }

              // delete
              if (input.length < customerInfo.dateOfBirth?.length) {
                dispatch(
                  setCustomerInfo({
                    ...customerInfo,
                    dateOfBirth: input,
                  })
                );
                setCustomerInfoError({
                  ...customerInfoError,
                  dateOfBirthError: false,
                });
                return;
              }

              // add '/'
              let formattedDate = input;
              if (input.length === 2 || input.length === 5) {
                formattedDate += "/";
              }

              // validation
              const parts = formattedDate.split("/");
              const day = parts[0] ? parseInt(parts[0], 10) : null;
              const month = parts[1] ? parseInt(parts[1], 10) : null;
              const year = parts[2] ? parseInt(parts[2], 10) : null;

              if (parts[0] && parts[0].length === 2 && (day < 1 || day > 31)) {
                setCustomerInfoError({
                  ...customerInfoError,
                  dateOfBirthError: true,
                });
                return;
              }
              if (
                parts[1] &&
                parts[1].length === 2 &&
                (month < 1 || month > 12)
              ) {
                setCustomerInfoError({
                  ...customerInfoError,
                  dateOfBirthError: true,
                });
                return;
              }
              if (
                parts[2] &&
                parts[2].length === 4 &&
                (year < 1900 || year > currentYear)
              ) {
                setCustomerInfoError({
                  ...customerInfoError,
                  dateOfBirthError: true,
                });
                return;
              }

              dispatch(
                setCustomerInfo({
                  ...customerInfo,
                  dateOfBirth: formattedDate,
                })
              );
              setCustomerInfoError({
                ...customerInfoError,
                dateOfBirthError: false,
              });
            }}
            additionalstyle={`
            width: calc(50% - 8px);

            @media only screen and (max-width: 767px) {
              width: 100%;
            }
          `}
          />
        </InputRow>
      </FormContainer>
      <div className="buttonContainer">{btnInfo}</div>
    </Card>
  );
};

export default GuestInformation;
