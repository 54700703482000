import React from 'react';
import SubHeader from './components/SubHeader';
import 'react-calendar-timeline/lib/Timeline.css';
import useTimeline from 'customHook/useTimeline';
import TimelineTable from 'components/TimelineTable';
import ItemRenderer from './components/ItemRenderer';
import generalFakeData from './components/general-fake-data';
import { SideBarItemContainer } from './styles';

const BookingsReport = () => {
  const { timelineState } = useTimeline();
  const { groups, items } = generalFakeData();

  const SideBarItem = groups.map((groupItem) =>
    Object.assign({}, groupItem, {
      title: (<SideBarItemContainer>{groupItem.title}</SideBarItemContainer>),
    }),
  );

  const handleItemClick = (itemId, e, time) => {

  }

  return (
    <>
      <SubHeader timelineState={timelineState} />
      <TimelineTable
        timelineState={timelineState}
        SideBarItem={SideBarItem}
        items={items}
        itemRenderer={ItemRenderer}
        handleItemClick={handleItemClick}
        SideBarTitle="Channel"
        sidebarWidth={120}
      />
    </>
  );
};

export default BookingsReport;
