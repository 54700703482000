import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Header from 'components/Header';
import FooterBar from 'components/FooterBar';
import Card from 'components/Card';
import CircularProgress from '@mui/material/CircularProgress';
import ModalAddNewStaff from 'screens/ModalAddNewStaff';
import { useQuery } from '@apollo/client';
import { GET_ALL_STAFFS } from 'graphql/service';
import useWindowDimensions from 'customHook/useWindowDimensions';
import { EditIcon, RemoveIcon } from 'assets/icons';
import moment from 'moment';
import {
  StaffsContainer,
  Container, 
  StyledInput,
  StyledTable,
  StyledItem,
  Navigator,
  NavigatorContainer
} from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';
import ModalRemoveStaff from 'screens/ModalRemoveStaff';

const Staffs = () => {

  const history = useHistory()
  const { width, height } = useWindowDimensions();
  const [ keyword, setKeyword ] = useState('')
  const [ staffs, setStaffs ] = useState([])

  const { data: staffsData, loading, refetch } = useQuery(
    GET_ALL_STAFFS, 
    {
      onCompleted: (staffsData) => {
        if(staffsData) {
          setStaffs(staffsData.staffs)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  let pageSize
  if(width>1200) {
    pageSize = Math.floor((height - 260)/36)
  } else if(width<=1200 && width>=768) {
    pageSize = Math.floor((height - 280)/48)
  } else {
    pageSize = Math.floor((height - 265)/48)
  }

  const inputHandler = (keyword) => {

    setKeyword(keyword)
    const filteredStaffs = staffsData.staffs.filter((staff)=>
      (
        staff.user.fullName.includes(keyword) ||
        staff.user.phoneNumber.includes(keyword) ||
        staff.user.email.includes(keyword) ||
        staff.user.userRoles?.[0]?.name.includes(keyword)
      )
    )
    setStaffs(filteredStaffs);
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter') {
      if(e.target.value > 0 && e.target.value <= Math.ceil(staffs.length/pageSize)) {
        setPageNumber(e.target.value)
      } 
    }
  }

  const [pageNumber, setPageNumber] = useState(1)
  const [inputNumber, setInputNumber] = useState(pageNumber)

  const paginate = (array, page_size, page_number) => {
    const newArray = [...array]
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return newArray.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const previousPage = () => {
    setPageNumber(pageNumber-1)
    setInputNumber(pageNumber-1)
  }

  const nextPage = () => {
    setPageNumber(pageNumber+1)
    setInputNumber(pageNumber+1)
  }

  const resultArray = paginate(staffs, pageSize, pageNumber).map((staff, index)=> {

    return (
      <StyledItem key={index}>
        <td className="name">{staff.user.fullName}</td>
        <td className="role">{staff.user.userRoles?.[0]?.name}</td>
        <td className="phone">{staff.user.phoneNumber}</td>
        <td className="email">{staff.user.email}</td>
        <td className="status">{staff.user.status}</td>
        <td className="created">
          {moment(staff.user.created).format("MM/DD/yy")}
        </td>
        <td className="lastLogin">
          {moment(staff.user.lastLogin).format("MM/DD/yy")}
        </td>
        <td className="action">
          <div className="iconContainer">
            <div
              className="icon"
              onClick={() => {
                history.push(`/staff-details/${staff.staffId}`);
              }}
            >
              <EditIcon />
            </div>
            <ModalRemoveStaff
              buttonIcon={<RemoveIcon />}
              name={staff.user.fullName}
              staffId={staff.staffId}
            />
          </div>
        </td>
      </StyledItem>
    );
  })

  return (
    <>
      <Helmet>
        <title>Staffs - Rook Hotel PMS</title>
      </Helmet>
      <Header
        subHeader={
          <>
            <div className="title">Staffs</div>
            <ModalAddNewStaff
              buttonLabel="Create a New Staff"
              buttonStyle={`
                width: 148px;
              `}
              refetch={refetch}
            />
          </>
        }
      />
      <StaffsContainer>
        <Container>
          {loading ? (
            <CircularProgress
              style={{ position: "absolute", top: "33px", left: "240px" }}
              color="success"
              thickness={10}
              size={20}
            />
          ) : (
            <Card>
              <StyledInput
                placeholder="Search..."
                value={keyword}
                onChange={(e) => inputHandler(e.target.value)}
              />
              <StyledTable>
                <thead>
                  <tr>
                    <th className="name">NAME</th>
                    <th className="role">ROLE</th>
                    <th className="phone">PHONE</th>
                    <th className="email">EMAIL</th>
                    <th className="status">STATUS</th>
                    <th className="created">CREATED</th>
                    <th className="lastLogin">LAST LOGIN</th>
                    <th className="action">ACTION</th>
                  </tr>
                </thead>
                <tbody>{resultArray}</tbody>
              </StyledTable>
            </Card>
          )}
          {staffs.length > pageSize ? (
            <NavigatorContainer>
              <div className="totalResults">{staffs.length} RESULTS</div>
              <Navigator
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                {"<"} Back
              </Navigator>
              <div className="pageControl">
                <input
                  type="number"
                  value={inputNumber}
                  onChange={(e) => {
                    setInputNumber(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
                <div className="totalPages">
                  {" "}
                  of {Math.ceil(staffs.length / pageSize)}
                </div>
              </div>
              <Navigator
                disabled={pageNumber >= staffs.length / pageSize}
                onClick={nextPage}
              >
                Next {">"}
              </Navigator>
            </NavigatorContainer>
          ) : null}
        </Container>
      </StaffsContainer>
      <FooterBar />
    </>
  );
}

export default Staffs;
