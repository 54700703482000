import React from 'react';
import Checkbox from 'components/Checkbox';
import { ReactComponent as SvgImagePlayButton } from 'assets/svg/Play.svg';
import { Container, ChatItem, PlayContainer } from './styles';
import colors from 'constants/colors';

const CommunicationHistroy = () => {

  return (
    <Container>
      <div className='title'>Tenant Communication History</div>
      <table>
        <thead>
          <tr>
            <th className="sign"></th>
            <th className="dateTime">DATE / TIME</th>
            <th className="type">TYPE</th>
            <th className="agent">AGENT</th>
            <th className="details">DETAILS</th>
            <th className="playback">PLAYBACK</th>
          </tr>
        </thead>
        <tbody>
          <tr className="highlight">
            <td>
              <Checkbox
                backgroundColor={colors.grey2}
                checkColor={colors.green1}
                checked={false}
                onChange={() => { }}
              />
            </td>
            <td>10/5/2020 5:24 PM</td>
            <td>Email</td>
            <td>John Smith</td>
            <td>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
              culpa qui officia deserunt mollit anim id est laborum.”{' '}
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <Checkbox
                backgroundColor={colors.grey2}
                checkColor={colors.green1}
                checked={true}
                onChange={() => { }}
              />
            </td>
            <td>10/5/2020 5:24 PM</td>
            <td>Web</td>
            <td>John Smith</td>
            <td>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod.”
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <Checkbox
                backgroundColor={colors.grey2}
                checkColor={colors.green1}
                checked={true}
                onChange={() => { }}
              />
            </td>
            <td>10/5/2020 5:24 PM</td>
            <td>Chat</td>
            <td colSpan="2">
              <ChatItem color={`${colors.blue2}`}>
                <span className="chatItem">
                <span className="chatAgent">
                  Staff
                </span>
                <span className="chatDetails">
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.”{' '}
                </span>
                </span>
              </ChatItem>
              <ChatItem color={`${colors.green2}`}>
              <span className="chatItem">
                <span className="chatAgent">
                  Joe B.
                </span>
                <span className="chatDetails">
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod.”
                </span>
                </span>
              </ChatItem>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <Checkbox
                backgroundColor={colors.grey2}
                checkColor={colors.green1}
                checked={true}
                onChange={() => { }}
              />  
            </td>
            <td>10/5/2020 5:24 PM</td>
            <td>SMS</td>
            <td>Joe B.</td>
            <td>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod.”
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <Checkbox
                backgroundColor={colors.grey2}
                checkColor={colors.green1}
                checked={true}
                onChange={() => { }}
              />
            </td>
            <td>10/5/2020 5:24 PM</td>
            <td>Phone</td>
            <td>Joe B.</td>
            <td>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod.”
            </td>
            <td>
              <PlayContainer>
                <SvgImagePlayButton />
                <span>4:34</span>
              </PlayContainer>
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  )
}

export default CommunicationHistroy;
