import React from 'react';
import { StatusBarContainer } from './styles';

const StatusBar = ({ 
  status,
  fontSize,
  fontWeight,
  width,
  height,
  margin,
  color 
}) => {

  const statusObj = {
    CHECKED_IN: 'Checked-in',
    CHECKED_OUT: 'Checked-out',
    CANCELLED: 'Cancelled',
    PENDING: 'Pending',
    EXPIRED: 'Expired',
  }

  return (
    <StatusBarContainer
      width={width}
      height={height}
      margin={margin}
      status={status}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
    >
      {statusObj[status]}
    </StatusBarContainer>
  );
}

export default StatusBar;
