import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import { FailIcon } from 'assets/icons';
import { Container } from './styles';

const ModalTransactionFailed = ({
  isOpen,
  setIsOpen,
  amount,
  cardNumber,
  loading,
  errorMessage,
  retryFunction,
  type
}) => {

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-danger"
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            height: 100%;
            padding: 24px;
          }
        }
        top: 179px;
      `}
    >
      <Container>
        <FailIcon />
        <div className="text">
          {type === "void"
            ? `Unable to void the $${Number(amount).toFixed(
                2
              )} transaction of the card ending in ${cardNumber?.slice(-4)}.`
            : type === "settlement"
            ? `There was an issue triggering the payment process. Please try again later.`
            : type === "createStaff"
            ? `Unable to create staff.`
            : `Unable to process a ${type} of $${Number(amount).toFixed(
                2
              )} to the card ending in ${cardNumber?.slice(-4)}.`}
        </div>
        {errorMessage && <div className="error">{errorMessage}</div>}
        <div className="buttonContainer">
          <Button
            danger
            onClick={() => setIsOpen(false)}
            additionalstyle={`
              width: 79px;
              margin-right: 16px;
            `}
          >
            Cancel
          </Button>
          <Button
            success
            loading={loading}
            onClick={retryFunction}
            additionalstyle={`
              width: 72px;
            `}
          >
            {loading ? null : "Retry"}
          </Button>
        </div>
      </Container>
    </MainModal>
  );
}

export default ModalTransactionFailed;
