import React from 'react';
import { Helmet } from 'react-helmet';
import Header from 'components/Header';
import { Switch, Route, Redirect } from 'react-router-dom'
import BookingsReport from './BookingsReport';
import OccupancyReport from './OccupancyReport';
import DailyReconciliation from './DailyReconciliation';
import RevenueReport from './RevenueReport';
import RoomNightReport from './RoomNightReport';

const Reports = () => {

  return (
    <>
    <Helmet>
      <title>Reports - Rook Hotel PMS</title>
    </Helmet>
    <Header />
    <Switch>
      <Route
        path={'/reports/bookings-report'}
        render={() => <BookingsReport />}
      />
      <Route
        path={'/reports/occupancy-report'}
        render={() => <OccupancyReport />}
      />
      <Route
        path={'/reports/daily-reconciliation'}
        render={() => <DailyReconciliation />}
      />
      <Route
        path={'/reports/revenue-report'}
        render={() => <RevenueReport />}
      />
      <Route
        path={'/reports/room-night-report'}
        render={() => <RoomNightReport />}
      />
      <Redirect to={'/reports'} />
    </Switch>
    </>
  );
};

export default Reports;