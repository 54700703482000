import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 10px;
    width: 370px;
    min-height: 120px;
    padding: 16px;
    border: 1px solid ${colors.grey9};
    background-color: ${colors.grey2};

    .itemTitle {
      color: ${colors.white1};
      font-size: 12px;
      font-weight: 400;
    }

    .createdAt, .amount, .cardNumber, .description, .voidedAt, .voidDescription {
      color: ${colors.white1};
      font-size: 14px;
      font-weight: 600;
    }

    .originator, .voidOriginator {
      color: ${colors.green1};
      font-size: 14px;
      font-weight: 400;
    }

    .highlight {
      color: ${colors.red1};
    }
`;
