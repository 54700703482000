import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { cleanGuest, setCustomerInfo, setStaffInfo } from 'redux/actions'
import Header from 'components/Header';
import Button from 'components/Button';
import Message from './components/Message';
import Audio from './components/Audio';
import QueueAnim from 'rc-queue-anim'
import { useLazyQuery } from '@apollo/client';
import { GET_TENANT_INFO_BY_TENANT_ID, GET_STAFF_INFO_BY_STAFF_ID } from 'graphql/service';
import {
  Content,
  ContentContainer,
  CommunicationContainer,
  TitleContainer,
} from './styles';
import ChatIcon from 'assets/icons/chat.svg'
import SendButton from 'assets/svg/sendButton.svg'
import CallButton from 'assets/svg/callButton.svg'
import colors from 'constants/colors';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const Communications = ({ mobile, open }) => {

  const history = useHistory()
  const dispatch = useDispatch()

  const [activeClass, setActiveClass] = useState('Emails')
  const [content, setContent] = useState('')

  const staffInfo = useSelector(state => state.staffInfoReducer.staffInfo)
  const staffId= useParams().staffId

  const customerInfo = useSelector(state => state.customerInfoReducer.customerInfo)
  const tenantId = useParams().tenantId 
  const type = useSelector(state => state.selectReducer.selectedGuest?.type)

  useEffect(() => {
    if(type) {
      setActiveClass(type)
    }
  },[type])

  const [getTenantInfoByTenantId] = useLazyQuery(GET_TENANT_INFO_BY_TENANT_ID, {
    variables: { tenantId: tenantId },
    onCompleted: (tenantInfoData) => {
      if(tenantInfoData) {
        const newCustomer = {...customerInfo}
        const {
          user: {
            userId,
            fullName,
            email,
            phoneNumber,
            address
          }
        } = tenantInfoData.tenantByID
        newCustomer.userId = userId
        newCustomer.tenantId = tenantId
        newCustomer.fullName = fullName
        newCustomer.email = email
        newCustomer.phoneNumber = phoneNumber
        newCustomer.billingAddress = address
        //FIXME: document value
        newCustomer.travelDocumentNumber = ''
        newCustomer.travelDocumentType = ''
        dispatch(setCustomerInfo(newCustomer))
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const [getStaffInfoByStaffId] = useLazyQuery(GET_STAFF_INFO_BY_STAFF_ID, {
    variables: { staffId: staffId },
    onCompleted: (staffInfoData) => {
      if(staffInfoData) {
        const newStaff = {...staffInfo}
        const {
          user: {
            userId,
            fullName,
            email,
            phoneNumber,
            address
          }
        } = staffInfoData.staffByID
        newStaff.userId = userId
        newStaff.staffId = staffId
        newStaff.fullName = fullName
        newStaff.email = email
        newStaff.phoneNumber = phoneNumber
        newStaff.address = address
        // newStaff.roles = roles
        // newStaff.status = status
        dispatch(setStaffInfo(newStaff))
      }

    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  useEffect(()=>{
    const messageList = []
    window.scrollTo(0, document.body.scrollHeight)
  }, [])

  useEffect(()=>{
    if(tenantId) {
      getTenantInfoByTenantId()
    } else if(staffId) {
      getStaffInfoByStaffId()
    }
  }, [getStaffInfoByStaffId, getTenantInfoByTenantId, staffId, tenantId])

  return (
    <CommunicationContainer mobile={mobile} open={open}>
      <Helmet>
        <title>Communications - Rook Hotel PMS</title>
      </Helmet>
      <Header />
      <Content>
        <TitleContainer>
          <Button
            additionalstyle={`
              position: absolute;
              left: 30px;
              font-size: 16px;
              font-weight: bold;
              color: ${colors.blue1};
              background-color: transparent;

              @media only screen and (max-width: 767px) {
                font-size: 13px;
              }
            `}
            onClick={()=>{
              if(!mobile) {
                history.go(-1)
              } else {
                dispatch(cleanGuest())
              }
            }}
          >
            {'<'} Back
          </Button>
          <div>
            <img src={ChatIcon} alt={'chat'}/>
            {`Communications with ${tenantId? customerInfo.fullName: staffInfo.fullName}`}
          </div>
        </TitleContainer>
        <ContentContainer>
          <div className='navigators'>
            <div
              className={activeClass==='Emails'? 'active': null}
              onClick={()=> {
                setActiveClass('Emails')
              }}
            >
              Emails
            </div>
            <div
              className={activeClass==='Chats'? 'active': null}
              onClick={()=> {
                setActiveClass('Chats')
              }}
            >
              Chats
            </div>
            <div
              className={activeClass==='Text'? 'active': null}
              onClick={()=> {
                setActiveClass('Text')
              }}
            >
              Text Message
            </div>
            <div
              className={activeClass==='Phone Calls'? 'active': null}
              onClick={()=> {
                setActiveClass('Phone Calls')
              }}
            >
              Phone Calls
            </div>
          </div>
          {
            activeClass==='Phone Calls' ? (
              <>
                <div className='audios'>
                  <QueueAnim type='alpha' delay={100}>
                    <Audio
                      createAt={Date.now()}
                      path={"https://file-examples.com/storage/fe88505b6162b2538a045ce/2017/11/file_example_MP3_1MG.mp3"}
                    />
                    <Audio
                      staff
                      createAt={Date.now()+1000}
                      path={"https://file-examples.com/storage/feffcabf3562ab2f72eb1aa/2017/11/file_example_MP3_1MG.mp3"}
                    />
                    <Audio
                      createAt={Date.now()+2000}
                      path={"https://file-examples.com/storage/feffcabf3562ab2f72eb1aa/2017/11/file_example_MP3_1MG.mp3"}
                    />
                    <div style={{clear: 'both'}}></div>
                  </QueueAnim>
                </div>
                <div className='callBar'>
                  <div></div>
                  <img src={CallButton} alt={'callButton'} />
                </div>
              </>
            ): (
              <>
                <div className='messages'>
                  <QueueAnim type='alpha' delay={100}>
                    <Message
                      createAt={Date.now()}
                      content={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
                    />
                    <Message
                      staff
                      createAt={Date.now()}
                      content={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
                    />
                    <Message
                      createAt={Date.now()}
                      content={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
                    />
                    <div style={{clear: 'both'}}></div>
                  </QueueAnim>
                </div>
                <div className='typeBar'>
                  <input
                    value={content}
                    onChange={(e)=>{
                      setContent(e.target.value)
                    }}
                  />
                  <img src={SendButton} alt={'sendButton'} />
                </div>
              </>
            )
          }
        </ContentContainer>
      </Content>
    </CommunicationContainer>
  )
}

export default Communications;
