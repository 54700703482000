import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerInfo } from 'redux/actions'
import Header from 'components/Header';
import ModalResetPassword from 'screens/ModalResetPassword';
import ModalRemoveTenant from 'screens/ModalRemoveTenant';
import FooterBar from 'components/FooterBar';
import Button from 'components/Button';
import GuestInformation from 'components/BookingMain/GuestInformation';
import GuestInformationImages from 'components/BookingMain/GuestInformationImages';
import SendCustomerMessage from './components/SendCustomerMessage';
import CommunicationHistory from './components/CommunicationHistory';
import MetaInformation from './components/MetaInformation';
import BookingHistory from './components/BookingHistory';
import GeneralGuestNotes from './components/GeneralGuestNotes';
import { useQuery } from '@apollo/client';
import { GET_TENANT_INFO_BY_TENANT_ID } from 'graphql/service';
import { useCustomerForm } from 'customHook/useCustomerForm';
import { scrollDown } from 'utils/scrollDown';
import {
  Content,
  ContentColumn,
  Container,
  DesktopCommunicationContainer,
  MobileCommunicationContainer
} from 'components/BookingMain/styles';
import ChatIcon from 'assets/icons/chat.svg'
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';


const TenantDetails = () => {

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const customerInfo = useSelector(state => state.customerInfoReducer.customerInfo)
  const tenantId = useParams().tenantId
  const screenBottomRef = useRef()
  const [groups, setGroups] = useState([])

  //const scrollToBottom = () => screenBottomRef.current.scrollIntoView({ behavior: 'smooth' });
  const scrollToBottom = () => scrollDown(screenBottomRef.current?.offsetTop, 1500)  

  //GeneralGuestNotes
  const [tenantNotes, setTenantNotes] = useState([])

  const { refetch } = useQuery(GET_TENANT_INFO_BY_TENANT_ID, {
    variables: { tenantId: tenantId },
    onCompleted: (tenantInfoData) => {
      if(tenantInfoData) {
        const newCustomer = {...customerInfo}
        const {
          user: {
            userId,
            fullName,
            email,
            phoneNumber,
            address
          },
          groups,
          tenantNotes
        } = tenantInfoData.tenantByID
        setTenantNotes(tenantNotes)
        newCustomer.userId = userId
        newCustomer.tenantId = tenantId
        newCustomer.fullName = fullName
        newCustomer.email = email
        newCustomer.phoneNumber = phoneNumber
        newCustomer.billingAddress = address
        //FIXME: document value
        newCustomer.travelDocumentNumber = ''
        newCustomer.travelDocumentType = ''
        dispatch(setCustomerInfo(newCustomer))
        setGroups(groups)
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  useEffect(() => {
    if(location.state?.communication) {
      scrollToBottom()
    }
  },[location.state?.communication])

  //Customer Information
  const [customerInfoError, setCustomerInfoError] = useState({})
  const { updateCustomerInfo } = useCustomerForm(setCustomerInfoError)

  const handleSaveChanges = () => {
    updateCustomerInfo()
  }

  return (
    <>
      <Helmet>
        <title>Tenant Details - Rook Hotel PMS</title>
      </Helmet>
      <Header
        subHeader={
          <>
            <div className='title'>
              Guest Profile
            </div>
            <div className='buttonContainer'>
              <div>
                <ModalResetPassword
                  buttonLabel="Reset Password"
                  buttonStyle={`
                    width: 122px;
                    margin-left: 14px;
                  `}
                  email={customerInfo.email}
                  phoneNumber={customerInfo.phoneNumber}
                />
                <ModalRemoveTenant
                  buttonLabel="Remove"
                  buttonStyle={`
                    width: 115px;
                    margin-left: 14px;
                  `}
                  name={customerInfo.fullName}
                  tenantId={customerInfo.tenantId}
                />
              </div>
            </div>
          </>
        }
      />
      <Content>
        <MobileCommunicationContainer>
          <Button
            mainPage
            onClick={()=>{
              history.push(`/guest-communications/${tenantId}`)
            }}
            additionalstyle={`

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 308px;
                height: 55px;
                font-size: 15px;
              }

              @media only screen and (max-width: 767px) {
                width: 100%;
                height: 55px;
                font-size: 15px;
              }
            `}
          >
            <img src={ChatIcon} alt={'chat'} width={29} height={29}/>
            {`Communications with ${customerInfo.fullName}`}
          </Button>
        </MobileCommunicationContainer>
        <ContentColumn>
          <Container>
            <GuestInformation
              customerInfoError={customerInfoError}
              setCustomerInfoError={setCustomerInfoError}
              btnInfo={
                <Button
                  mainPage
                  success
                  onClick={handleSaveChanges}
                  additionalstyle={`
                    margin-left: 14px;
                    width: 113px;
                  `}
                >
                  Save Changes
                </Button>
              }
            />
          </Container>
          <Container>
            <GuestInformationImages />
          </Container>
        </ContentColumn>
        <ContentColumn>
          <Container>
            <BookingHistory groups={groups} />
          </Container>
          <Container>
            <GeneralGuestNotes
              tenantNotes={tenantNotes}
              refetch={refetch}
              tenantId={tenantId}
            />
          </Container>
          <Container>
            <MetaInformation
              created={Date.now()}
              origin={"Booking.com"}
            />
          </Container>
        </ContentColumn>
        <DesktopCommunicationContainer ref={screenBottomRef}>
          <SendCustomerMessage />
          <CommunicationHistory />
        </DesktopCommunicationContainer>
      </Content>
      <FooterBar />
    </>
  )
}

export default TenantDetails;
