import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderSearch from 'components/HeaderSearch';
import AccountMenu from 'components/AccountMenu';
import BurgerMenu from 'components/BurgerMenu';
import StyledBadge from 'components/Badge'
import MessageBox from 'components/MessageBox'
import AlertBox from 'components/AlertBox'
import { HeaderContainer, Title, SubHeader, Messages, Alerts } from './styles';
import Logo from 'assets/Logo.png'
import BellIcon from 'assets/svg/Bell.svg';
import MessageIcon from 'assets/svg/Message.svg';

const Header = ({ subHeader, additionalstyle, desktop, mobile, open, mobileSubHeaderHidden }) => {

  const [ messagesActive, setMessagesActive ] = useState(false);
  const [ alertsActive, setAlertsActive ] = useState(false);
  const history = useHistory()
  const location = useLocation() 

  return (
    <>
      <HeaderContainer additionalstyle={additionalstyle} desktop={desktop} mobile={mobile} open={open}>
        <Title
          onClick={() => {
            history.push('/')
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            width={41}
            height={31}
          />
          <span className='highlight'>ROOK</span>
          <span>HOTEL</span>
        </Title>
        <HeaderSearch
          staff={location.pathname==='/staffs'}
        />
        <AccountMenu />
        <Messages
          active={messagesActive}
          onClick={() => {
            setMessagesActive(!messagesActive)
            setAlertsActive(false)
          }}
        >
          <StyledBadge
            className="badge"
            color="error"
            badgeContent=" "
            variant="dot"
            invisible={false}
            right={'4px'}
            top={'0px'}
          >
            <img src={MessageIcon} alt={'messageIcon'}/>
          </StyledBadge>   
        </Messages>
        <Alerts
          active={alertsActive}
          onClick={() => {
            setAlertsActive(!alertsActive)
            setMessagesActive(false)
          }}
        >
          <StyledBadge
            className="badge"
            color="error"
            badgeContent=" "
            variant="dot"
            invisible={false}
            right={'4px'}
            top={'0px'}
          >
            <img src={BellIcon} alt={'alertIcon'} />
          </StyledBadge>   
        </Alerts> 
        <BurgerMenu />
        <div
          className="outerContainer"
          style={{
            position: 'absolute',
            top: '50px',
            right: 0,
            width: '100vw',
            height: 'calc(100vh - 100px)',
            display: messagesActive ? 'block': alertsActive? 'block':'none'
          }}
          onClick={() => {
            setMessagesActive(false)
            setAlertsActive(false)
          }}
        >
          <MessageBox
            active={messagesActive}
          />
          <AlertBox
            active={alertsActive}
          />
        </div>
      </HeaderContainer>
      {subHeader ? <SubHeader mobileSubHeaderHidden={mobileSubHeaderHidden}>{subHeader}</SubHeader> : null}
    </>
  )
}

export default Header;
