import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import CircularProgress from '@mui/material/CircularProgress';
import { Container, StyledInput, StyledList, StyledItem } from './styles'

const sleep = (delay = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const HeaderSearch = ({staff}) => {

  const history = useHistory()
  
  const [ loading, setLoading ] = useState(false)
  const [ isActive, setIsActive ] = useState(false)
  const [ keyword, setKeyword ] = useState('')
  const [ options, setOptions ] = useState([])

  const inputHandler = async (keyword) => {

    setLoading(true)
    setIsActive(true)
    setKeyword(keyword)
    //convert input text to lower case
    const lowerCase = keyword.toLowerCase();
    let result
    if(staff) {
      result = await sleep(500);
    } else {
      result = await sleep(500);
    }

    if (!result) {

      const optionArr = results.map((result, index) => {
        const newResult = {...result}
        const matches = match(result.info, lowerCase, {insideWords: true, findAllOccurrences: true, requireMatchAll: true});
        const parts = parse(result.info, matches);
        newResult.info = parts
        return newResult
      })

      setOptions([...optionArr]);
    }
    setLoading(false)
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter') {
      history.push({pathname: '/search-results', state: {keyword: keyword}})
    }
  }
  
  return (
    <Container>
      <StyledInput
        placeholder='Search...'
        onBlur={()=>{
          setKeyword('')
          setIsActive(false)
        }}
        onKeyDown={handleKeyDown}
        value={keyword}
        onChange={(e) => inputHandler(e.target.value)}  
      />
      {
        loading ? 
          <CircularProgress
            style={{position: 'absolute',top: '11px', right: '140px'}}
            color="success"
            thickness={10}
            size={20}
          /> :
          isActive ?
          <StyledList>
            {
              options.map((item, index)=> (
                <StyledItem
                  key={index}
                  onClick={()=>{
                    if(staff) {
                      //history.push(`/staff-details/${item.staffId}`)
                    } else {
                      //dispatch(setRoom(item.roomNumber));
                      //history.push(`/room/${item.roomNumber}`)
                    }
                    setKeyword('')
                    setIsActive(false)
                  }}
                >
                  <span className="type">{item.type}: </span>
                  {item.info.map((str, index) => (
                    <span
                      key={index}
                      className={str.highlight? 'highlight': null}
                    >
                      {str.text}
                    </span>
                  ))}
                </StyledItem>
              ))
            }
          </StyledList>
          :null
      }
    </Container>
  )
}

export default HeaderSearch;
// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const results = [
  { type: 'The Shawshank Redemption', info: '1994' },
  { type: 'The Godfather', info: '1972' },
  { type: 'The Godfather: Part II', info: '1974' },
  { type: 'The Dark Knight', info: '2008' },
  { type: '12 Angry Men', info: '1957' },
  { type: "Schindler's List", info: '1993' },
  { type: 'Pulp Fiction', info: '1994' }
];