import React from 'react';
import { LegendItem } from './LegendItem';
import { LegendContainer } from './style';
import colors from 'constants/colors';
import { KingIcon, QueenIcon, KnightIcon, RookIcon, PawnIcon } from 'assets/icons';

const Legend = () => {
  return (
    <LegendContainer>
      <div className='legendRow'>
        <LegendItem
          backgroundColor={colors.green6}
          legendTitle="Inspected"
        />
        <LegendItem
          backgroundColor={colors.blue7}
          legendTitle="Room Clean"
        />
        <LegendItem
          backgroundColor={colors.brown2}
          legendTitle="Room Dirty"
        />
        <LegendItem
          backgroundColor={colors.black6}
          legendTitle="Not Available"
        />
        <LegendItem
          legendTitle="Current Booking"
        />
      </div>
      <div className='legendRow'>
        <LegendItem
          iconComponent={<KingIcon className="iconLayout" />}
          legendTitle="King"
        />
        <LegendItem
          iconComponent={<QueenIcon className="iconLayout" />}
          legendTitle="Queen"
        />
        <LegendItem
          iconComponent={<KnightIcon className="iconLayout" />}
          legendTitle="Knight"
        />
        <LegendItem
          iconComponent={<RookIcon className="iconLayout" />}
          legendTitle="Rook"
        />
        <LegendItem
          iconComponent={<PawnIcon className="iconLayout" />}
          legendTitle="Pawn"
        />
      </div>
    </LegendContainer>
  );
};

export default Legend;
