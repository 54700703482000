import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import { CREATE_BOOKING_CHECK_OUT } from 'graphql/service';
import { useMutation } from '@apollo/client';
import colors from 'constants/colors';
import { Container } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalCheckOutConfirm = ({
  isOpen,
  setIsOpen,
  checkOutBookings,
  buttonLabel,
  buttonStyle,
  refetch
}) => {

  const [createBookingCheckOut] = useMutation(CREATE_BOOKING_CHECK_OUT,
    {
      onCompleted: (data) => {
        setIsOpen(false)
        refetch()
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  const firstRoom=`room ${checkOutBookings[0]?.roomBookings?.[0]?.room?.roomNumber}`

  const rooms = checkOutBookings.length === 0?
    ''
    : checkOutBookings.length === 1?
    firstRoom
    : checkOutBookings.slice(1).reduce((acc, booking, index, array) => {
      const roomString = `room ${booking.roomBookings?.[0]?.room?.roomNumber}`
        if (index === array.length - 1) {
            return `${acc} and ${roomString}`
        } else {
            return `${acc}, ${roomString}`
        }
      }, firstRoom)


  const handleCheckOut = () => {
    const checkOutBookingIdArray = checkOutBookings?.map(booking => booking.bookingId)
    createBookingCheckOut({
      variables: {
        input: {
          ids: checkOutBookingIdArray
        }
      }
    })
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            height: 100%;
          }
        }
        top: 179px;
      `}
    >
      <Container>
        <div className='text'>Are you sure you want to proceed with the checkout for {rooms}?</div>
        <Button
          success
          onClick={handleCheckOut}
          additionalstyle={`
            width: 105px;
            margin-top: 24px;
          `} 
        >
          Check Out
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalCheckOutConfirm;
