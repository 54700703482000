import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  justify-content: center;
  text-align: center;
  padding: 24px;

  .text {
    color: ${colors.white1};
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 16px 0 0 0; 
  }

  .error {
    color: ${colors.red1};
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin: 10px 0 0 0; 
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
`;
