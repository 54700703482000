import React from 'react'
import { Helmet } from 'react-helmet'
import Header from 'components/Header'
import FooterBar from 'components/FooterBar'
import ManageRooms from './ManageRooms'
import { RoomsContainer } from './styles'

const RoomChange = () => {

  return (
    <>
      <Helmet>
        <title>Room Change - Rook Hotel PMS</title>
      </Helmet>
      <Header />
      <RoomsContainer>
        <ManageRooms />
      </RoomsContainer>
      <FooterBar />
    </>
  );
};

export default RoomChange;
