import React from 'react';
import { LegendItemContainer } from './style';

export const LegendItem = ({
  backgroundColor,
  iconComponent,
  legendTitle,
}) => {
  return (
    <LegendItemContainer backgroundColor={backgroundColor}>
      <div className='iconContainer'>{iconComponent}</div>
      <div className='legendTitle'>{legendTitle ? legendTitle : 'error'}</div>
    </LegendItemContainer>
  );
};
