import React, { useState } from 'react'
import FloorCard from '../../Home/components/FloorCard'
import EighthFloor from '../../Home/components/FloorPlan/floors/EighthFloor'
import NinthFloor from '../../Home/components/FloorPlan/floors/NinthFloor'
import TenthFloor from '../../Home/components/FloorPlan/floors/TenthFloor'
import Legend from '../Legend'
import { useQuery } from '@apollo/client';
import { GET_ALL_ROOMS } from 'graphql/service';
import { Container, MapRow, FloorCardContainer } from './style';
import colors from 'constants/colors'

const ManageRooms = ({}) => {

  const { data: roomsData, loading, error } = useQuery(GET_ALL_ROOMS, {
    pollInterval: 1200000,
  });
    
  if (error) {
    return <div>Error! {error.message}</div>;
  }
  if (loading) {
    return <div>Loading......</div>;
  }

  const eighthFloorRooms = {}
  const ninthFloorRooms = {}
  const tenthFloorRooms = {}
  roomsData.validRooms.forEach(room => {
    switch (room.floor) {
      case '8':
        eighthFloorRooms[room.roomNumber] = room
        break
      case '9':
        ninthFloorRooms[room.roomNumber] = room
        break
      case '10':
        tenthFloorRooms[room.roomNumber] = room
        break
      default:
        break
    }
  })

  return (
    <Container>
      <MapRow>
        <EighthFloor scale={1.25} backgroundColor={colors.black5} rooms={eighthFloorRooms}/>
        <NinthFloor scale={1.25} backgroundColor={colors.black5} rooms={ninthFloorRooms}/>
        <TenthFloor scale={1.25} backgroundColor={colors.black5} rooms={tenthFloorRooms}/>
      </MapRow>
      <FloorCardContainer>
        <FloorCard eighthFloorRooms={eighthFloorRooms} ninthFloorRooms={ninthFloorRooms} tenthFloorRooms={tenthFloorRooms}/>
      </FloorCardContainer>
      <Legend />
    </Container>
  );
};

export default ManageRooms;
