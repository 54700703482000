import React, { useState, useEffect, useMemo } from "react";
import MainModal from "components/ModalMain";
import Input from "components/Input";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import DatePicker from "components/DatePicker";
import TimePicker from "components/TimePicker";
import CheckBox from "components/Checkbox";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import {
  ADD_BOOKING_TO_GROUP,
  GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE,
  GET_ALL_BOOKING_ADD_ONS,
} from "graphql/service";
import {
  Head,
  Container,
  Row,
  TravelInputRow,
  SummaryContainer,
  ItemContainer,
  Footer,
} from "./styles";
import { message } from "antd";
import { getMessageTimeOut, dateDiff } from "utils/util";
import moment from "moment";
import colors from "constants/colors";

const ModalAddRoom = ({ hidden, buttonStyle, groupId, refetch }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [barrierFree, setBarrierFree] = useState(false);

  const [availableRoomTypes, setAvailableRoomTypes] = useState([]);
  const [roomTypeOptions, setRoomTypeOptions] = useState([]);
  const [roomTypeId, setRoomTypeId] = useState("");

  const [breakfastQuantity, setBreakfastQuantity] = useState(0);
  const [parkingQuantity, setParkingQuantity] = useState(0);
  const [earlyCheckIn, setEarlyCheckIn] = useState(false);
  const [lateCheckOut, setLateCheckOut] = useState(false);

  const [addonPrices, setAddonPrices] = useState({});

  const [billArray, setBillArray] = useState([]);

  useQuery(GET_ALL_BOOKING_ADD_ONS, {
    onCompleted: (data) => {
      const prices = {};
      data.validBookingAddons.forEach((item) => {
        prices[item.title] = item.pricePerNight;
      });
      setAddonPrices(prices);
    },
    onError: (error) => {
      let content = error.message;
      if (error.message.includes("Validation")) {
        content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception);
      }
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy();
        },
      });
    },
  });

  const addOns = useMemo(
    () => [
      {
        addonId: "63f6e8f8-8095-4c79-8d92-ce5812db4f15",
        quantity: breakfastQuantity,
      },
      {
        addonId: "fd67a4cb-667a-4ba9-bec4-379cb430682a",
        quantity: parkingQuantity,
      },
      {
        addonId: "4c773a2c-0d0a-44ba-8680-d34244fea4d5",
        quantity: earlyCheckIn ? 1 : 0,
      },
      {
        addonId: "c5032166-bd7f-4a8a-bc4b-81d5c7fc5ff5",
        quantity: lateCheckOut ? 1 : 0,
      },
    ],
    [breakfastQuantity, parkingQuantity, earlyCheckIn, lateCheckOut]
  );

  const [checkInDate, setCheckInDate] = useState(
    moment().set({ hour: 15, minute: 0, second: 0 })
  );
  const [checkOutDate, setCheckOutDate] = useState(
    moment().add(1, "days").set({ hour: 11, minute: 0, second: 0 })
  );

  const [getAvailableRoomTypesByDateRange] = useLazyQuery(
    GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE,
    {
      onCompleted: (data) => {
        setAvailableRoomTypes(data.availableRoomTypesByDateRange);
        setRoomTypeOptions(
          data.availableRoomTypesByDateRange?.map((roomType) => ({
            value: roomType.roomTypeId,
            label: roomType.name,
          }))
        );
        setRoomTypeId("");
      },
      onError: (error) => {
        let content = error.message;
        if (error.message.includes("Validation")) {
          content = JSON.stringify(
            error.graphQLErrors[0]?.extensions?.exception
          );
        }
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy();
          },
        });
      },
    }
  );

  useEffect(() => {
    getAvailableRoomTypesByDateRange({
      variables: {
        input: {
          checkInDate: checkInDate.toDate(),
          checkOutDate: checkOutDate.toDate(),
          isWheelchairAccessible: barrierFree,
          roomQuantity: 1,
          guestQuantity: 1,
        },
      },
    });
  }, [
    barrierFree,
    checkInDate,
    checkOutDate,
    getAvailableRoomTypesByDateRange,
  ]);

  useEffect(() => {
    if (roomTypeId) {
      const nights = dateDiff(checkInDate, checkOutDate);
      const selectedRoomType = availableRoomTypes.find(
        (roomType) => roomType.roomTypeId === roomTypeId
      );
      if (!selectedRoomType) return;
      const billArray = [
        <ItemContainer key={roomTypeId}>
          <span className="description">{`${selectedRoomType?.name} - ${nights + (nights>1? ' nights,': ' night,')} ${
            selectedRoomType?.bed
          } w/ ${selectedRoomType.hasWindows ? "window" : "skylight"}`}</span>
          <span className="value">
            {"$" + (selectedRoomType.pricePerNight * nights).toFixed(2)}
          </span>
        </ItemContainer>,
      ];

      addOns.forEach((item) => {
        if (item.quantity > 0) {
          switch (item.addonId) {
            case "63f6e8f8-8095-4c79-8d92-ce5812db4f15": // Breakfast
              billArray.push(
                <ItemContainer key={item.addonId}>
                  <span className="description">{`Breakfast (${
                    item.quantity > 1
                      ? item.quantity + " vouchers"
                      : item.quantity + " voucher"
                  } @ $${addonPrices["Breakfast"]}/meal)`}</span>
                  <span className="value">{"$" + item.quantity * nights}</span>
                </ItemContainer>
              );
              break;
            case "fd67a4cb-667a-4ba9-bec4-379cb430682a": // Parking
              billArray.push(
                <ItemContainer key={item.addonId}>
                  <span className="description">{`Parking (${
                    item.quantity + (item.quantity > 1 ? " cars, " : " car, ")
                  } ${nights + (nights > 1 ? "nights" : "night")} @ $${
                    addonPrices["Parking"]
                  }/car/night)`}</span>
                  <span className="value">
                    {"$" + item.quantity * nights * addonPrices["Parking"]}
                  </span>
                </ItemContainer>
              );
              break;
            case "4c773a2c-0d0a-44ba-8680-d34244fea4d5": // Early Check-in
              billArray.push(
                <ItemContainer key={item.addonId}>
                  <span className="description">{`Early Checkin (${item.quantity} room @ $${addonPrices["Early Checkin"]}/room)`}</span>
                  <span className="value">
                    {"$" + item.quantity * addonPrices["Early Checkin"]}
                  </span>
                </ItemContainer>
              );
              break;
            case "c5032166-bd7f-4a8a-bc4b-81d5c7fc5ff5": // Late Check-out
              billArray.push(
                <ItemContainer key={item.addonId}>
                  <span className="description">{`Late Checkout (${item.quantity} room @ $${addonPrices["Late Checkout"]}/room)`}</span>
                  <span className="value">
                    {"$" + item.quantity * addonPrices["Late Checkout"]}
                  </span>
                </ItemContainer>
              );
              break;
            default:
              return;
          }
        } else {
          return;
        }
      });
      setBillArray(billArray);
    } else {
      setBillArray([]);
    }
  }, [
    roomTypeId,
    availableRoomTypes,
    breakfastQuantity,
    parkingQuantity,
    earlyCheckIn,
    lateCheckOut,
    addonPrices,
    checkInDate,
    checkOutDate,
    addOns,
    setBillArray,
  ]);

  const [addBookingToGroup] = useMutation(ADD_BOOKING_TO_GROUP, {
    onCompleted: (data) => {
      refetch();
      setIsOpen(false);
    },
    onError: (error) => {
      let content = error.message;
      if (error.message.includes("Validation")) {
        content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception);
      }

      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy();
        },
      });
    },
  });

  const handleAddRoom = () => {
    addBookingToGroup({
      variables: {
        groupId,
        input: {
          roomTypeId: roomTypeId,
          checkInDate: checkInDate.toDate(),
          checkOutDate: checkOutDate.toDate(),
          isWheelchairAccessible: barrierFree,
          addons: addOns,
        },
      },
    });
  };

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel="Add Room"
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalstyle={`
        max-width: 640px;
        .modal-content{        
          .close {
            z-index: 200;
          }
          .modal-header {
            position: relative;
            top: 10px;
          }              
          .modal-body {
            height: 100%;
            padding: 24px;
          }
        }
        top: 69px;
      `}
    >
      <Head>Add another room to this booking?</Head>
      <Container>
        <CheckBox
          checked={barrierFree}
          onChange={() => setBarrierFree(!barrierFree)}
          title="Barrier Free"
          additionalstyle={`
            margin: 24px 0 0 2px;
          `}
        />
                <TravelInputRow>
          <div className="checkIn">
            <div className="datePickerContainer">
              <span>ARRIVAL</span>
              <DatePicker
                error={moment(checkInDate) >= moment(checkOutDate)}
                minDate={moment(new Date())}
                dateValue={moment(checkInDate)}
                dateOnChange={(e) => {
                  setCheckInDate(e);
                  if (moment(e) >= moment(checkOutDate)) {
                    setCheckOutDate(
                      moment(e)
                        .add(1, "days")
                        .set({ hour: 11, minute: 0, second: 0 })
                    );
                  }
                }}
                additionalstyle={`
                  width: 100%;
                  min-width: 130px;
                  border: 1px solid ${colors.grey1};

                  input{
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
            <div className="timePickerContainer">
              <span>ARRIVAL TIME</span>
              <TimePicker
                TimeValue={moment(checkInDate)}
                setTime={setCheckInDate}
                additionalstyle={`
                  width: 100%;
                  min-width: 110px;
                  border: 1px solid ${colors.grey1};

                  input{
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
          </div>
          <div className="checkOut">
            <div className="datePickerContainer">
              <span>DEPARTURE</span>
              <DatePicker
                error={moment(checkInDate) >= moment(checkOutDate)}
                minDate={moment(new Date())}
                dateValue={moment(checkOutDate)}
                dateOnChange={(e) => {
                  setCheckOutDate(e);
                  if (moment(e) <= moment(checkInDate)) {
                    if (
                      moment(e)
                        .subtract(1, "days")
                        .set({ hour: 15, minute: 0, second: 0 }) >=
                      moment(new Date())
                    ) {
                      setCheckInDate(
                        moment(e)
                          .subtract(1, "days")
                          .set({ hour: 15, minute: 0, second: 0 })
                      );
                    } else {
                      setCheckInDate(moment(new Date()));
                    }
                  }
                }}
                additionalstyle={`
                  width: 100%;
                  min-width: 130px;
                  border: 1px solid ${colors.grey1};

                  input{
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
            <div className="timePickerContainer">
              <span>DEPARTURE TIME</span>
              <TimePicker
                TimeValue={moment(checkOutDate)}
                setTime={setCheckOutDate}
                additionalstyle={`
                  width: 100%;
                  min-width: 110px;
                  border: 1px solid ${colors.grey1};

                  input{
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
          </div>
        </TravelInputRow>
        <Row>
          <Dropdown
            mainPage
            options={roomTypeOptions}
            value={
              roomTypeOptions.find((option) => option.value === roomTypeId)
                ?.label || "-Select-"
            }
            title="ROOM TYPE"
            onChange={(e) => {
              setRoomTypeId(e);
            }}
            additionalstyle={`
              flex-basis: 25%;
              margin-right: 16px;
            `}
          />
          <Input
            mainPage
            title="BREAKFAST"
            value={breakfastQuantity}
            onChange={(e) => setBreakfastQuantity(e.target.value)}
            additionalstyle={`
              flex-basis: 15%;
              margin-right: 16px;
            `}
          />
          <Input
            mainPage
            title="PARKING"
            value={parkingQuantity}
            onChange={(e) => setParkingQuantity(e.target.value)}
            additionalstyle={`
              flex-basis: 15%;
            `}
          />
        </Row>
        <CheckBox
          checked={earlyCheckIn}
          onChange={() => setEarlyCheckIn(!earlyCheckIn)}
          title="Early Check-in"
          additionalstyle={`
            margin: 24px 0 0 2px;
          `}
        />
        <CheckBox
          checked={lateCheckOut}
          onChange={() => setLateCheckOut(!lateCheckOut)}
          title="Late Check-out"
          additionalstyle={`
            margin: 16px 0 0 2px;
          `}
        />
        {billArray.length ? (
          <SummaryContainer>{billArray}</SummaryContainer>
        ) : null}
        <Footer>
          <Button
            danger
            additionalstyle={`
              width: 79px;
            `}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            success
            additionalstyle={`
              width: 106px;
              margin-left: 16px;
            `}
            onClick={handleAddRoom}
          >
            Add Room
          </Button>
        </Footer>
      </Container>
    </MainModal>
  );
};

export default ModalAddRoom;
