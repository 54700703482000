import React from 'react';
import Room from 'screens/Home/components/Room';
import {
  Container,
  CenterBoxContainer,
  FloorImageCenterContent,
} from './styles';

const NinthFloor = ({ scale, desktop, backgroundColor, rooms }) => {

  return (
    <Container scale={scale} desktop={desktop} backgroundColor={backgroundColor}>
      <div className='top'>
        <Room className="room9_31" width="46px" height="48px" roomData={rooms['931']} roomNumber='931' floorBackgroundColor={backgroundColor} />
        <Room className="room9_32" width="23px" height="43px" roomData={rooms['932']} roomNumber='932' floorBackgroundColor={backgroundColor} />
        <Room className="room9_34" width="26px" height="43px" roomData={rooms['934']} roomNumber='934' floorBackgroundColor={backgroundColor} />
        <Room width="84px" height="43px" disabled />
        <Room className="room9_3" width="25px" height="43px" roomData={rooms['903']} roomNumber='903' floorBackgroundColor={backgroundColor} />
        <Room className="room9_5" width="25px" height="43px" roomData={rooms['905']} roomNumber='905' floorBackgroundColor={backgroundColor} />
        <Room className="room9_6" width="46px" height="48px" roomData={rooms['906']} roomNumber='906' floorBackgroundColor={backgroundColor} />
      </div>
      <div className='middle'>
        <div className='left'>
          <Room className="room9_30" width="42px" height="24px" roomData={rooms['930']} roomNumber='930' floorBackgroundColor={backgroundColor} />
          <Room className="room9_29" width="42px" height="23px" roomData={rooms['929']} roomNumber='929' floorBackgroundColor={backgroundColor} />
          <Room className="room9_28" width="42px" height="40px" roomData={rooms['928']} roomNumber='928' floorBackgroundColor={backgroundColor} />
          <Room className="room9_27" width="42px" height="30px" roomData={rooms['927']} roomNumber='927' floorBackgroundColor={backgroundColor} />
          <Room className="room9_26" width="42px" height="23px" roomData={rooms['926']} roomNumber='926' floorBackgroundColor={backgroundColor} />
          <Room className="room9_24" width="42px" height="24px" roomData={rooms['924']} roomNumber='924' floorBackgroundColor={backgroundColor} />
        </div>
        <FloorImageCenterContent>
          <div>
            <Room className="room9_33" width="40px" height="41px" roomData={rooms['933']} roomNumber='933' floorBackgroundColor={backgroundColor} />
            <Room className="room9_35" width="40px" height="38px" roomData={rooms['935']} roomNumber='935' floorBackgroundColor={backgroundColor} />
            <Room className="room9_36" width="40px" height="38px" roomData={rooms['936']} roomNumber='936' floorBackgroundColor={backgroundColor} />
            <Room className="room9_25" width="40px" height="38px" roomData={rooms['925']} roomNumber='925' floorBackgroundColor={backgroundColor} />
          </div>
          <div>
            <div>
              <CenterBoxContainer>
                <div className="row1">
                  <div></div>
                  <div></div>
                </div>
                <div className="row2"></div>
                <div className="row3">
                  <Room className="room9_54" roomData={rooms['954']} roomNumber='954' roomName="leftElevator" disabled />
                  <Room className="room9_55" roomData={rooms['955']} roomNumber='955' roomName="rightElevator" disabled />
                </div>
                <div className="row4">
                  <Room className="room9_19" width="33px" height="40px" roomData={rooms['919']} roomNumber='919' floorBackgroundColor={backgroundColor} />
                  <Room className="room9_17" width="33px" height="40px" roomData={rooms['917']} roomNumber='917' floorBackgroundColor={backgroundColor} />
                </div>
              </CenterBoxContainer>
            </div>
          </div>
          <div>
            <Room className="room9_4" width="40px" height="41px" roomData={rooms['904']} roomNumber='904' floorBackgroundColor={backgroundColor} />
            <Room className="room9_2" width="40px" height="38px" roomData={rooms['902']} roomNumber='902' floorBackgroundColor={backgroundColor} />
            <Room className="room9_1" width="40px" height="38px" roomData={rooms['901']} roomNumber='901' floorBackgroundColor={backgroundColor} />
            <Room className="room9_12" width="40px" height="38px" roomData={rooms['912']} roomNumber='912' floorBackgroundColor={backgroundColor} />
          </div>
        </FloorImageCenterContent>
        <div className='right'>
          <Room className="room9_7" width="42px" height="24px" roomData={rooms['907']} roomNumber='907' floorBackgroundColor={backgroundColor} />
          <Room className="room9_8" width="42px" height="23px" roomData={rooms['908']} roomNumber='908' floorBackgroundColor={backgroundColor} />
          <Room className="room9_9" width="42px" height="40px" roomData={rooms['909']} roomNumber='909' floorBackgroundColor={backgroundColor} />
          <Room className="room9_10" width="42px" height="30px" roomData={rooms['910']} roomNumber='910' floorBackgroundColor={backgroundColor} />
          <Room className="room9_11" width="42px" height="23px" roomData={rooms['911']} roomNumber='911' floorBackgroundColor={backgroundColor} />
          <Room className="room9_13" width="42px" height="24px" roomData={rooms['913']} roomNumber='913' floorBackgroundColor={backgroundColor} />
        </div>
      </div>
      <div className='bottom'>
        <Room className="room9_23" width="46px" height="48px" roomData={rooms['923']} roomNumber='923' floorBackgroundColor={backgroundColor} />
        <Room className="room9_22" width="23px" height="43px" roomData={rooms['922']} roomNumber='922' floorBackgroundColor={backgroundColor} />
        <Room className="room9_21" width="37px" height="43px" roomData={rooms['921']} roomNumber='921' floorBackgroundColor={backgroundColor} />
        <Room className="room9_20" width="26px" height="43px" roomData={rooms['920']} roomNumber='920' floorBackgroundColor={backgroundColor} />
        <Room className="room9_18" width="35px" height="43px" roomData={rooms['918']} roomNumber='918' floorBackgroundColor={backgroundColor} />
        <Room className="room9_16" width="35px" height="43px" roomData={rooms['916']} roomNumber='916' floorBackgroundColor={backgroundColor} />
        <Room className="room9_15" width="23px" height="43px" roomData={rooms['915']} roomNumber='915' floorBackgroundColor={backgroundColor} />
        <Room className="room9_14" width="46px" height="48px" roomData={rooms['914']} roomNumber='914' floorBackgroundColor={backgroundColor} />
      </div>
    </Container>
  )
}

export default NinthFloor