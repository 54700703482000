import styled from 'styled-components';
import colors from 'constants/colors';

export const DefaultIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .icon {
    width: ${(props) => (props.width ? props.width : ' 15px')};
    height: ${(props) => (props.height ? props.height : ' 15px')};
  }
`;

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width ? props.width: '100%'};
  height: ${(props) => props.height ? props.height: '100%'};
  line-height: ${(props) => props.height};
  display: flex;
  margin: 2px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  ${({ selected }) => (selected? `
    border: 2px solid ${colors.white1};
    ` : null)};
  
  & > img {
    margin: auto;
    height: 30px;
  }

  ${({roomNumber, selected, backgroundColor, floorBackgroundColor}) => {
    switch (roomNumber) {
      case '802':
      case '905':
      case '1005':
      case '818':
      case '924':
      case '1025':
        return ` 
          ::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            right: -3px;
            bottom: -3px;
            border: 6px solid ${floorBackgroundColor};
            border-top-color: transparent;
            border-left-color: transparent;
          }
        `
      case '825':
      case '932':
      case '1033':
      case '809':
      case '913':
      case '1013':
        return ` 
          ::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            left: -3px;
            bottom: -3px;
            border: 6px solid ${floorBackgroundColor};
            border-top-color: transparent;
            border-right-color: transparent;
          }
        `
      case '823':
      case '930':
      case '1031':
      case '811':
      case '915':
      case '1015':
        return ` 
          ::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            right: -3px;
            top: -3px;
            border: 6px solid ${floorBackgroundColor};
            border-bottom-color: transparent;
            border-left-color: transparent;
          }
        `
      case '804':
      case '907':
      case '1007':
      case '816':
      case '922':
      case '1023':
        return ` 
          ::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            left: -3px;
            top: -3px;
            border: 6px solid ${floorBackgroundColor};
            border-bottom-color: transparent;
            border-right-color: transparent;
          }
        `
      case '824':
      case '931':
      case '1032':
        return ` 
          ::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            right: -3px;
            bottom: -3px;
            border: 8px solid ${floorBackgroundColor};
            border-top-color: transparent;
            border-left-color: transparent;
          }
        `
      case '803':
      case '906':
      case '1006':
        return ` 
          ::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            left: -3px;
            bottom: -3px;
            border: 8px solid ${floorBackgroundColor};
            border-top-color: transparent;
            border-right-color: transparent;
          }
        `
      case '817':
      case '923':
      case '1024':
        return ` 
          ::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            right: -3px;
            top: -3px;
            border: 8px solid ${floorBackgroundColor};
            border-bottom-color: transparent;
            border-left-color: transparent;
          }
        `
      case '810':
      case '914':
      case '1014':
        return ` 
          ::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            left: -3px;
            top: -3px;
            border: 8px solid ${floorBackgroundColor};
            border-bottom-color: transparent;
            border-right-color: transparent;
          }
        `
      case '821':
      case '928':
        return ` 
          ::before {
            content: '';
            width: 20px;
            height: 12px;
            position: absolute;
            left: -3px;
            bottom: -3px;
            background-color: ${floorBackgroundColor};
          }
        `
      case '813':
      case '918':
        return `
          ::before {
            content: '';
            width: 12px;
            height: 20px;
            position: absolute;
            left: -3px;
            bottom: -3px;
            background-color: ${floorBackgroundColor};
          }
        `
      case '806':
      case '909': 
        return `
          ::before {
            content: '';
            width: 20px;
            height: 12px;
            position: absolute;
            right: -3px;
            bottom: -3px;
            background-color: ${floorBackgroundColor};
          }
        `
      case '830':
        return `
          top: 2px;
          right: -2px;
          width: 57px;
          height: 50px;
          margin: 2px;
          ::before {
            content: '';
            position: absolute;
            right: ${selected? '-2px': '0'};
            bottom: ${selected? '-22px': '-20px'};
            width: 31px;
            height: ${selected? '22px': '20px'};
            background-color: ${backgroundColor};
            border-style: solid;
            border-width: ${selected? '0 2px 2px 2px': '0 0 0 0'};
            border-color: ${colors.white1};
          }
          ::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: -1px;
            width: ${selected? '27px': '31px'};
            height: 2px;
            background-color: ${backgroundColor};
          }
        `
      case '838':
        return `
          top: 22px;
          right: -2px;
          width: 57px;
          height: 62px;
          margin: 2px;
          ::before {
            content: '';
            position: absolute;
            left: ${selected? '-2px': '0'};
            top: ${selected? '-22px': '-20px'};
            width: 22px;
            height: ${selected? '22px': '20px'};
            background-color: ${backgroundColor};
            border-style: solid;
            border-width: ${selected? '2px 2px 0 2px': '0 0 0 0'};
            border-color: ${colors.white1};
          }
          ::after {
            content: '';
            position: absolute;
            left: 0;
            top: -1px;
            width: ${selected? '18px': '22px'};
            height: 2px;
            background-color: ${backgroundColor};
          }
        `
      default:
        return null
    }
  }}; 
`;

export const Border = styled.div`
  position: absolute;
  z-index: 200;

  ${({roomNumber}) => {
    switch (roomNumber) {
      case '802':
      case '905':
      case '1005':
        return `
          height: 10px;
          width: 10px;
          right: 52px;
          top: 35px;
          background: linear-gradient(-45deg, transparent 42%, ${colors.white1} 42%, ${colors.white1} 58%, transparent 58%);
        `
      case '818': 
      case '924':
      case '1025':
        return `
          height: 10px;
          width: 10px;
          left: 34px;
          bottom: 54px;
          background: linear-gradient(-45deg, transparent 42%, ${colors.white1} 42%, ${colors.white1} 58%, transparent 58%);
        `
      case '825':
      case '932':
      case '1033':
        return `
          height: 10px;
          width: 10px;
          left: 52px;
          top: 35px;
          background: linear-gradient(45deg, transparent 42%, ${colors.white1} 42%, ${colors.white1} 58%, transparent 58%);
        `
      case '809': 
      case '913':
      case '1013':
        return `
          height: 10px;
          width: 10px;
          right: 34px;
          bottom: 54px;
          background: linear-gradient(45deg, transparent 42%, ${colors.white1} 42%, ${colors.white1} 58%, transparent 58%);
        `
      case '823':
      case '930':
      case '1031':
        return `
          height: 10px;
          width: 10px;
          left: 34px;
          top: 54px;
          background: linear-gradient(45deg, transparent 42%, ${colors.white1} 42%, ${colors.white1} 58%, transparent 58%);
        `
      case '811': 
      case '915':
      case '1015':
        return `
          height: 10px;
          width: 10px;
          right: 52px;
          bottom: 35px;
          background: linear-gradient(45deg, transparent 42%, ${colors.white1} 42%, ${colors.white1} 58%, transparent 58%);
        `
      case '804':
      case '907':
      case '1007':
        return `
          height: 10px;
          width: 10px;
          right: 34px;
          top: 54px;
          background: linear-gradient(-45deg, transparent 42%, ${colors.white1} 42%, ${colors.white1} 58%, transparent 58%);
        `
      case '816': 
      case '922':
      case '1023':
        return `
          height: 10px;
          width: 10px;
          left: 52px;
          bottom: 35px;
          background: linear-gradient(-45deg, transparent 42%, ${colors.white1} 42%, ${colors.white1} 58%, transparent 58%);
        `
      case '824':
      case '931': 
      case '1032': 
        return `
          height: 15px;
          width: 15px;
          left: 33px;
          top: 35px;
          background: linear-gradient(-45deg, transparent 45%, ${colors.white1} 45%, ${colors.white1} 55%, transparent 55%);
        `
      case '803':
      case '906': 
      case '1006':
        return `
          height: 15px;
          width: 15px;
          right: 33px;
          top: 35px;
          background: linear-gradient(45deg, transparent 45%, ${colors.white1} 45%, ${colors.white1} 55%, transparent 55%);
        `
      case '817':
      case '923': 
      case '1024':
        return `
          height: 15px;
          width: 15px;
          left: 33px;
          bottom: 35px;
          background: linear-gradient(45deg, transparent 45%, ${colors.white1} 45%, ${colors.white1} 55%, transparent 55%);
        `
      case '810':
      case '914': 
      case '1014':
        return `
          height: 15px;
          width: 15px;
          right: 33px;
          bottom: 35px;
          background: linear-gradient(-45deg, transparent 45%, ${colors.white1} 45%, ${colors.white1} 55%, transparent 55%);
        `
      case '821':
      case '928': 
        return `
          height: 12px;
          width: 20px;
          left: 2px;
          bottom: 143px;
          border: solid ${colors.white1};
          border-width: 2px 2px 0px 0px;
        `
      case '813':
      case '918': 
        return `
          height: 20px;
          width: 12px;
          left: 150px;
          bottom: 2px;
          border: solid ${colors.white1};
          border-width: 2px 2px 0px 0px;
        `
      case '806':
      case '909': 
        return `
          height: 12px;
          width: 20px;
          right: 2px;
          bottom: 143px;
          border: solid ${colors.white1};
          border-width: 2px 0px 0px 2px;
        `
      default:
        return null
    }
  }}; 
`;