import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${colors.grey2};
  color: ${colors.white1};
  overflow: ${(props) => (props.overflow ? props.overflow : null)};
  ${(props) => (props.width ? `min-width: ${props.width}; max-width: ${props.width};` : null)};

  .buttonContainer {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;

    .subTitle {
      font-size: 10px;
      font-weight: 600;
      color: ${colors.grey5};
      margin-right: 10px;
    }

    @media only screen and (min-width: 767px) {
      .subTitle {
        white-space: nowrap;
      }
    }

    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  ${({ additionalstyle }) => additionalstyle};
`;
