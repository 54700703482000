import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.span`
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: ${colors.red2};
`;

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .groupTitle {
    font-size: 18px;
    font-weight: 600;
    color: ${colors.white1};
    margin-top: 16px;

    .warning {
      color: ${colors.red2};
    }
  }

  .select {
    background-position: 98% 50%;
    background-size: 15px 15px;
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 16px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 16px;
`;