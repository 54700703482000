import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setStaffInfo } from 'redux/actions'
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import PhoneNumberInput from 'components/PhoneNumberInput';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import { usePlacesWidget } from 'react-google-autocomplete'

const StaffInformation = ({
  staffInfoError,
  setStaffInfoError,
  btnInfo
}) => {

  const dispatch = useDispatch()
  const staffInfo = useSelector(state => state.staffInfoReducer.staffInfo)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false)

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_APIKEY,
    onPlaceSelected: (place) => {
      dispatch(setStaffInfo((staffInfo) => ({...staffInfo, address: place.formatted_address})))
    },
    options: {
      types: ['address'],
    },
  }) 

  useEffect(() => {
    if(phoneNumberValidity) {
      setStaffInfoError({...staffInfoError, phoneNumberError: false})
      dispatch(setStaffInfo((staffInfo) => ({...staffInfo, phoneNumber: phoneNumber})))
    }
  }, [phoneNumberValidity])

  return (
    <Card>
      <CardHeader
        additionalstyle={`
          @media only screen and (max-width: 767px) {
            
            .type {
              width: 100%;
              margin-bottom: 10px; 
            }

            .buttonContainer {
              width: 100%;
              justify-content: space-between;
            }
            
            flex-direction: column;
            justify-content: center;
          }
        `}
      >
        <div className='type'>STAFF INFORMATION</div>
      </CardHeader>
      <FormContainer>
        <Input
          mainPage
          title="NAME"
          value={staffInfo.fullName}
          error={staffInfoError.fullNameError}
          onChange={(e) => {
            dispatch(setStaffInfo({...staffInfo, fullName: e.target.value}))
          }}
          additionalstyle={`
            flex-basis: 100%;
          `}
        />
        <Input
          mainPage
          title="ADDRESS"
          inputFieldRef={ref}
          value={staffInfo.address}
          error={staffInfoError.addressError}
          onChange={(e) => {
            dispatch(setStaffInfo({...staffInfo, address: e.target.value}))
          }}
          additionalstyle={`
            flex-basis: 100%;
          `}
        />
        <Input
          mainPage
          title="EMAIL"
          value={staffInfo.email}
          error={staffInfoError.emailError}
          onChange={(e) => {
            dispatch(setStaffInfo({...staffInfo, email: e.target.value}))
          }}
          additionalstyle={`
            flex-basis: 63%;
            margin-right:2%;
            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-right: 0;
            }
          `}
        />
        <PhoneNumberInput
          mainPage
          title="PHONE"
          error={staffInfoError.phoneNumberError}
          initialValue={staffInfo.phoneNumber}
          onChangeNumber={setPhoneNumber}
          setPhoneNumberValidity={setPhoneNumberValidity}
          onBlur={() => {
            if(!phoneNumberValidity&&phoneNumber.length>0) {
              setStaffInfoError({...staffInfoError, phoneNumberError: true})
            }
          }}
          additionalstyle={`
            flex-basis: 35%;
            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
            }
          `}
        />
        <Dropdown
          mainPage
          options={['staff', 'housekeeping']}
          value={staffInfo.roles || ''}
          error={staffInfoError.rolesError}
          title="ROLES"
          onChange={(e) => {
            dispatch(setStaffInfo({...staffInfo, roles: e}))
          }}
          additionalstyle={`
            flex-basis: 50%;
            margin-right:5%;
          `}
        />
        <Dropdown
          mainPage
          options={['active']}
          value={staffInfo.status || ''}
          error={staffInfoError.statusError}
          title="STATUS"
          onChange={(e) => {
            dispatch(setStaffInfo({...staffInfo, status: e}))
          }}
          additionalstyle={`
            flex-basis: 45%;
          `}
        />
      </FormContainer>
      <div className='buttonContainer'>{btnInfo}</div>
    </Card>
  );
};

export default StaffInformation;
