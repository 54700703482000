import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { Container } from './styles';
import { TooltipIcon } from 'assets/icons';
import moment from 'moment';

const BillTooltip = ({
  createdAt,
  createdBy,
  type,
  reason,
  amount,
  transaction
}) => {

  return (
    <Tooltip
      placement="right"
      trigger={['hover']}
      overlay={
        <Container>
          <div>
            <span className='createdAt'>
              {moment(createdAt).format('M/D/YYYY h:mm A') + ' by '}
              </span>
            <span className='createdBy'>{createdBy}</span>
          </div>
          <div className="tooltip-title">{transaction? 'Description:': `Reason for ${type}:`}</div>
          <div className="tooltip-text">{reason || 'N/A'}</div>
          <div className="tooltip-title">
            <span>{`${type} Amount:`}</span>
            <span className="tooltip-highlight"> {' $' + Number(amount).toFixed(2)}</span>
          </div>
        </Container>
      }
    >
      <TooltipIcon />
    </Tooltip>
  );
}

export default BillTooltip;
