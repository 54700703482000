import styled from 'styled-components';
import colors from 'constants/colors';

export const CheckboxContainer = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: ${colors.white1};
  display: flex;
  justify-content: flex-start;

  /* Hide the browser's default checkbox */
  .input {
    display: none;
  }

  /* Create a custom checkbox */
  .checkbox {
    position: relative;
    top: 4px;
    left: 0;
    margin-right: 10px;
    height: 15px;
    width: 15px;
    background-color: ${(props) =>(props.backgroundColor ? props.backgroundColor:colors.grey3)};
    border: 1px solid ${colors.grey18};
    transform: scale(1.27);
    cursor: pointer;
    
    /* Create the checkmark/indicator (hidden when not checked) */
    &::after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  /* Style the checkmark/indicator */
  .checkbox::after {
    left: 4px;
    top: -1px;
    width: 6px;
    height: 12px;
    border-style: solid;
    border-color: ${(props) =>(props.checkColor ? props.checkColor:colors.blue4)};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* When the checkbox is checked, add a grey background */
  input:checked ~ .checkbox {
    background-color: ${(props) =>(props.backgroundColor ? props.backgroundColor:colors.grey3)};
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkbox:after {
    display: block;
  }

  ${({ additionalstyle }) => additionalstyle};
`;