import styled from 'styled-components';
import colors from 'constants/colors';

export const BillItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.grey3};
  row-gap: 8px;
  padding: 16px;

  .title {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .coupon, .ratePlan, .discount {
    color: ${colors.red1};
  }
`;

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  .description {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .value {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;

    .amount {
      width: 100px;
      text-align: right;
    }
  }

  .warning {
    color: ${colors.red1};
  }

  .lineThrough {
    text-decoration: line-through;
  }
`;

export const BillOfSaleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${colors.black5};
  margin-bottom: 16px;
    
  .value {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    letter-spacing: 0.5px;
    float: right;
  }

  .total {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const CouponContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  background-color: ${colors.grey3}; 
`;

export const Warning = styled.div`
  color: ${colors.red1};
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
`;