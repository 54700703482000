import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setRoom } from 'redux/actions'
import { AlertBoxContainer, AlertItem } from './styles';
import { PathIcon } from 'assets/icons';
import colors from 'constants/colors'

const AlertBox = ({ active }) => {

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const alert = {
    roomNumber: '932',
    alertType: "STAYOVER",
    title: "(932) Kenji Lopez-Alt",
    checkInDate: "22/7/2020 3:14PM",
    checkOutDate: "28/07/2020"
  }

  return (
    <AlertBoxContainer active={active}>
      <AlertItem
        onClick={() => {
          if(alert.alertType==='ROOM NOTE') {
            dispatch(setRoom(alert.roomNumber));
            history.push(`/room/${alert.roomNumber}`)
          } else {
            history.push({pathname:'/bookings', state: {roomNumber: alert.roomNumber}})
          }
        }}
        color={alert.type === 'note' ? colors.orange2 : colors.red3}
      >
        <div className='contentBox'>
          <div className='alertType'>{alert.alertType}</div>
          <div className='content'>
            <div className='alertTitle'>{alert.title}</div>
            <div className='dateTime'>
              <span>{alert.checkInDate}</span>
              {alert.checkInDate? ' - ': null}
              <span>{alert.checkOutDate}</span>
            </div>
          </div>
        </div>
        <div className='link'>
          <PathIcon />
        </div>
      </AlertItem>
      <AlertItem
        onClick={() => {
          if(alert.alertType==='ROOM NOTE') {
            if(location.pathname!=='/') {
              dispatch(setRoom(alert.roomNumber));
              history.push('/rooms')
            } else {
              dispatch(setRoom(alert.roomNumber));
            }
          } else {
            history.push({pathname:'/bookings', state: {roomNumber: alert.roomNumber}})
          }
        }}
        color={alert.type === 'note' ? colors.orange2 : colors.red3}
      >
        <div className='contentBox'>
          <div className='alertType'>{alert.alertType}</div>
          <div className='content'>
            <div className='alertTitle'>{alert.title}</div>
            <div className='dateTime'>
              <span>{alert.checkInDate}</span>
              {alert.checkInDate? ' - ': null}
              <span>{alert.checkOutDate}</span>
            </div>
          </div>
        </div>
        <div className='link'>
          <PathIcon />
        </div>
      </AlertItem>
    </AlertBoxContainer>
  )
}

export default AlertBox;