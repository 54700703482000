import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import ModalTransactionSuccessfully from 'screens/ModalTransactionSuccessfully';
import ModalTransactionFailed from 'screens/ModalTransactionFailed';
import { useMutation } from '@apollo/client';
import { CREATE_CHARGE } from 'graphql/service';
import { Head, Container, Footer } from './styles';

const ModalAddCredit = ({
  hidden,
  buttonStyle,
  billId,
  cardNumber,
  max,
  refetch
}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [successfullyIsOpen, setSuccessfullyIsOpen] = useState(false)
  const [failedIsOpen, setFailedIsOpen] = useState(false)
  const [creditDetails, setCreditDetails] = useState('')
  const [creditAmount, setCreditAmount] = useState('')
  const [textAreaError, setTextAreaError] = useState(false)
  const [inputError, setInputError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [createCredit, {loading}] = useMutation(CREATE_CHARGE, {
    onCompleted: () => {
      setSuccessfullyIsOpen(true)
      setIsOpen(false)
    },
    onError: (error) => {
      setFailedIsOpen(true)
      setIsOpen(false)
    }
  })

  const handleAddCredit = () => {
    if(loading) return
    let error = false
    if(creditDetails === '') {
      setTextAreaError(true)
      error = true
    }
    
    if(creditAmount === '') {
      setInputError(true)
      setErrorMessage('Credit amount is required.')
      error = true
    } else if(Number(creditAmount) > max) {
      setInputError(true)
      setErrorMessage('Credit cannot be more than the total amount.')
      error = true
    }

    if(error) return 
    createCredit({
      variables: {
        input: {
          billId: billId,
          type: 'DISCOUNT',
          amount: Number(creditAmount),
          description: creditDetails,
        },
      },
    })
  }

  return (
    <>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="modal-success"
        buttonLabel="Add Credit"
        buttonStyle={buttonStyle}
        hidden={hidden}
        additionalstyle={`
          max-width: 500px;
          .modal-content{
            .close {
              z-index: 200;
            }
            .modal-body {
              height: 100%;
              padding: 24px;
            }
          }
          top: 139px;
        `}
      >
        <Head>Add Credit</Head>
        <Container>
          <TextArea      
            title="CREDIT DETAILS"
            required
            rows={4}
            error={textAreaError}
            value={creditDetails}
            onChange={(e) => {
              setCreditDetails(e.target.value)
              setTextAreaError(false)
            }}
            additionalstyle={`
              flex-basis: 100%;
            `}
          />
        </Container>
        <Footer>
          <Input
            title="AMOUNT"
            required
            value={creditAmount}
            error={inputError}
            errorMessage={errorMessage}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
                setCreditAmount(inputValue);
                setInputError(false)
              }
            }}
            additionalstyle={`
              width: 31%;

              input {
                padding-left: 22px;
              }

              ::before {
                content: '$';
                font-size: 16px;
                font-weight: 600;
                position: absolute;
                left: 12px;
                top: 32px;
              }
              
              .warning {
                white-space: nowrap;
              }
            `}
          />
          <Button
            success
            additionalstyle={`
              width: 84px;
              margin-top: 25px;
            `}
            loading={loading}
            onClick={handleAddCredit}
          >
            {loading? null: 'Submit'}
          </Button>
        </Footer>
      </MainModal>
      <ModalTransactionSuccessfully
        isOpen={successfullyIsOpen}
        setIsOpen={setSuccessfullyIsOpen}
        amount={creditAmount}
        cardNumber={cardNumber}
        setAmount={setCreditAmount}
        setDetails={setCreditDetails}
        refetch={refetch}
        type='credit'
      />
      <ModalTransactionFailed
        isOpen={failedIsOpen}
        setIsOpen={setFailedIsOpen}
        amount={creditAmount}
        cardNumber={cardNumber}
        loading={loading}
        retryFunction={handleAddCredit}
        type='credit'
      />
    </>
  );
}

export default ModalAddCredit;
