import React, { useState, useEffect } from 'react';
import { SwitchOffIcon, SwitchOnIcon } from 'assets/icons';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  GET_EMAIL_SUBSCRIPTIONS_BY_USER_ID,
  GET_PHONE_SUBSCRIPTIONS_BY_USER_ID,
  CREATE_EMAIL_SUBSCRIPTION,
  DELETE_EMAIL_SUBSCRIPTION,
  CREATE_PHONE_SUBSCRIPTION,
  DELETE_PHONE_SUBSCRIPTION,
} from 'graphql/service';
import { Container } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const SubscribeSlider = ({type, userId, left}) => {

  const generalEmailSubscriptionTypeId = "5f705113-b914-4cee-b503-72c476ae27ee"
  const generalPhoneSubscriptionTypeId = "d8761bf0-dc42-4711-9aef-5c8f912bc982"

  const [checked, setChecked] = useState(false);
  const [emailSubscriptionId, setEmailSubscriptionId] = useState("");
  const [phoneSubscriptionId, setPhoneSubscriptionId] = useState("");

  const [getEmailSubscriptionByUserId, {refetch: emailRefetch}] = useLazyQuery(GET_EMAIL_SUBSCRIPTIONS_BY_USER_ID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (emailSubscriptionData) => {
      if (emailSubscriptionData.emailSubscriptionsByUser) {
        const generalEmailSubscription = emailSubscriptionData.emailSubscriptionsByUser.find(
          (subscription) =>
            (subscription.subscriptionType.subscriptionTypeId ===
            generalEmailSubscriptionTypeId) && (subscription.isCancelled === false)
        );
        setChecked(!!generalEmailSubscription);
        setEmailSubscriptionId(generalEmailSubscription?.subscriptionId);
      }
    },
    onError: (error) => {
      return null;
    },
  });

  const [getPhoneSubscriptionByUserId, {refetch: phoneRefetch}] = useLazyQuery(GET_PHONE_SUBSCRIPTIONS_BY_USER_ID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (phoneSubscriptionData) => {
      if (phoneSubscriptionData.phoneSubscriptionsByUser) {
        const generalPhoneSubscription = phoneSubscriptionData.phoneSubscriptionsByUser.find(
          (subscription) =>
            (subscription.subscriptionType.subscriptionTypeId ===
            generalPhoneSubscriptionTypeId) && (subscription.isCancelled === false)
        );
        setChecked(!!generalPhoneSubscription);
        setPhoneSubscriptionId(generalPhoneSubscription?.subscriptionId);
      }
    },
    onError: (error) => {
      return null;
    },
  });

  useEffect(() => {
    if(userId) {
      if(type === 'email') {
        getEmailSubscriptionByUserId({
          variables: { userId }
        });
      } else if(type === 'phone') {
        getPhoneSubscriptionByUserId({
          variables: { userId }
        });
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [userId, type]);

  const [createEmailSubscription] = useMutation(CREATE_EMAIL_SUBSCRIPTION,
    {
      onCompleted: (data) => {
        emailRefetch();
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  );

  const [deleteEmailSubscription] = useMutation(DELETE_EMAIL_SUBSCRIPTION,
    {
      onCompleted: (data) => {
        setChecked(false);
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  );

  const [createPhoneSubscription] = useMutation(CREATE_PHONE_SUBSCRIPTION,
    {
      onCompleted: (data) => {
        phoneRefetch();
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  );

  const [deletePhoneSubscription] = useMutation(DELETE_PHONE_SUBSCRIPTION,
    {
      onCompleted: (data) => {
        setChecked(false);
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  );

  const handleEmailToggle = () => {
    if(checked) {
      deleteEmailSubscription({
        variables: { 
          subscriptionId: emailSubscriptionId
         }
      });
    } else {
      createEmailSubscription({
        variables: { 
          input: {
            userId,
            subscriptionTypeIds: [generalEmailSubscriptionTypeId] 
          }
        }
      });
    }
  }

  const handlePhoneToggle = () => {
    if(checked) {
      deletePhoneSubscription({
        variables: { 
          subscriptionId: phoneSubscriptionId
         }
      });
    } else {
      createPhoneSubscription({
        variables: { 
          input: {
            userId,
            subscriptionTypeIds: [generalPhoneSubscriptionTypeId]
          }
        }
      });
    }
  }

  return (
    <Container
      left={left}
    >
      <div
        className={`switch ${checked? 'active': ''}`}
        onClick={type === 'email'? handleEmailToggle: handlePhoneToggle}
      >
        <div className={`switchSlider ${checked? 'active': ''}`}>
          {checked? <SwitchOnIcon />: <SwitchOffIcon />}
        </div>
      </div>
      <div className={`switchText ${checked? 'active': ''}`}>
        {checked? 'SUBSCRIBED': 'UNSUBSCRIBED'}
      </div>
    </Container>
  );
}

export default SubscribeSlider;
