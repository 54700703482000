import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { UPDATE_STAFF_INFO_BY_STAFF_ID, UPDATE_USER_INFO_BY_USER_ID } from 'graphql/service';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

/**
 * A custom hook for valid form
 * @param {Object} staffInfo - an object has staff information
 * @returns
 * staffInfo,
 * staffInfoError,
 * updateStaffInfo
 */

export const useStaffForm = (setStaffInfoError) => {

  const staffInfo = useSelector(state => state.staffInfoReducer.staffInfo)

  const [updateUserInfoByUserId] = useMutation(
    UPDATE_USER_INFO_BY_USER_ID,
    {
      onCompleted: (updateUserInfoData)=> {
        if(updateUserInfoData?.updateUser?.id) {
          message.success('Staff Info updated successfully!')  
        } else {
          const content = 'Update failed'
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )
  const [updateStaffInfoByStaffId] = useMutation(
    UPDATE_STAFF_INFO_BY_STAFF_ID
  )
  
  //function for valid form, make sure nonempty field in the form
  const isValidForm = () => {

    let result = true
    if (!staffInfo.address) {
      result = false;
      setStaffInfoError((staffInfoError)=>({...staffInfoError, addressError: true}))
    } else {
      setStaffInfoError((staffInfoError)=>({...staffInfoError, addressError: false}))
    }

    if (!staffInfo.fullName) {
      result = false;
      setStaffInfoError((staffInfoError)=>({...staffInfoError, fullNameError: true}))
    } else {
      setStaffInfoError((staffInfoError)=>({...staffInfoError, fullNameError: false}))
    }

    if (!staffInfo.email) {
      result = false;
      setStaffInfoError((staffInfoError)=>({...staffInfoError, emailError: true}))
    } else {
      setStaffInfoError((staffInfoError)=>({...staffInfoError, emailError: false}))
    }

    if (!staffInfo.phoneNumber) {
      result = false;
      setStaffInfoError((staffInfoError)=>({...staffInfoError, phoneNumberError: true}))
    } else {
      setStaffInfoError((staffInfoError)=>({...staffInfoError, phoneNumberError: false}))
    }

    if (!staffInfo.roles) {
      result = false;
      setStaffInfoError((staffInfoError)=>({...staffInfoError, rolesError: true}))
    } else {
      setStaffInfoError((staffInfoError)=>({...staffInfoError, rolesError: false}))
    }

    if (!staffInfo.status) {
      result = false;
      setStaffInfoError((staffInfoError)=>({...staffInfoError, statusError: true}))
    } else {
      setStaffInfoError((staffInfoError)=>({...staffInfoError, statusError: false}))
    }

    return result
  }

  //helper parse function to parse google address component
  // const parseStringAddress = (stringAddress) => {
  //   const parts = stringAddress.split(', ');
  //   const streetAddress = parts[0]
  //   const city = parts[1]
  //   const province = parts[2].split(' ')[0]
  //   const postalCode = parts[2].split(' ')[1] + ' ' + parts[2].split(' ')[2]
  //   const country = parts[3]
  
  //   return {
  //     streetAddress,
  //     city,
  //     province,
  //     postalCode,
  //     country,
  //   };
  // }

  const updateStaffInfo = () => {

    if (isValidForm()) {

      const userId = staffInfo.userId;
      const staffId = staffInfo.staffId;
      const fullName = staffInfo.fullName
      const email = staffInfo.email
      const phoneNumber = staffInfo.phoneNumber
      const address = staffInfo.address
      const roles = staffInfo.roles
      const status = staffInfo.status

      updateUserInfoByUserId({
        variables: {
          userId,
          input: {
            fullName,
            email,
            username: email,
            phoneNumber,
            address: address
          }
        }
      }) 

      // updateStaffInfoByStaffId({
      //   variables: {
      //     staffId,
      //     input: {
      //       roles,
      //       phoneNumber,
      //       roles,
      //       status
      //     }
      //   },
      // })

    } else {
      const content = 'Please fill the form!'
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  };

  return {
    updateStaffInfo
  }
}
