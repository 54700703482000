import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: ${colors.green1};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  margin: 24px 0 0 0;
`;

export const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 16px;
  background-color: ${colors.black8};
  margin: 24px 0 0 0;
`;

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 21px;

  .description {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: ${colors.white1};
    letter-spacing: 0.5px;
  }

  .value {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.white1};
    letter-spacing: 0.5px;
    text-align: right;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 24px 0 0 0;

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back {
      font-size: 18px;
      font-weight: 700;
      margin-right: 30px;
      color: ${colors.blue1};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const TravelInputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 24px 0 0 0;
  column-gap: 10px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    row-gap: 25px;
  }

  .checkIn, .checkOut {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  span {
    display: block;
    margin-bottom: 7px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2.5px;
    color: ${colors.grey5};
    white-space: nowrap;

    @media only screen and (max-width: 1200px) {
      font-size: 11px;
    }
  }

  .datePickerContainer {
    width: 55%;
  }
  
  .timePickerContainer {
    width: 45%;
  }
`;
