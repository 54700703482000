import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: ${colors.white1};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  height: 100%;
`;

export const Content = styled.div`
  width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`;

export const Footer = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
`;
