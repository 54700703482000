import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import ModalStaffInformation from 'screens/ModalStaffInformation';
import { useQuery } from '@apollo/client';
import { GET_ALL_USER_ROLES } from 'graphql/service';
import colors from 'constants/colors';
import { Container, Footer, Head } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalAddNewStaff  = ({
  hidden,
  disabled,
  buttonLabel="",
  buttonStyle,
  refetch
}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [staffInfoIsOpen, setStaffInfoIsOpen] = useState(false)
  
  const [options, setOptions] = useState([])

  const [chosenRole, setChosenRole] = useState("")

  const {data, loading, error} = useQuery(GET_ALL_USER_ROLES,
    {
      onCompleted: (userRolesData) => {
        if(userRolesData.validUserRoles.length > 0) {
          const newOptions = userRolesData.validUserRoles?.map((role) => ({label: role.name, value: role.roleId}))
          setOptions(newOptions)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  const handleContinue = () => {
    if(chosenRole === "") {
      message.error({
        content: "Please select a method",
        duration: getMessageTimeOut("Please select a method"),
        onClick: () => {
          message.destroy()
        }
      })
    } else {
      setIsOpen(false)
      setStaffInfoIsOpen(true)
    }
  }

  return (
    <div>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="modal-success"
        buttonLabel={buttonLabel}
        buttonStyle={buttonStyle}
        hidden={hidden}
        disabled={disabled}
        additionalstyle={`
          max-width: 500px;
          .modal-content{
            min-height: 248px;
            .close {
              z-index: 200;
            }   
            .modal-body{
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              justify-content: space-between;
              padding: 16px;
              height: 100%;
            }
          }
          background: ${colors.grey3};
          top: 179px;
        `}
      >
        <Container>
          <Head>Add New Staff</Head>
          <div className='text'>
            <div>Select the Role you want to add</div>
          </div>
          <Dropdown
            mainPage
            options={options}
            value={options.find((option) => option.value === chosenRole)?.label || "- Select -"}
            onChange={(e) => {
              setChosenRole(e)
            }}
          />
        </Container>
        <Footer>
          <div className='buttonContainer'>
            <Button
              success
              additionalstyle={`
                width: 100px;
              `}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </Footer>
      </MainModal>
      <ModalStaffInformation
        isOpen={staffInfoIsOpen}
        setIsOpen={setStaffInfoIsOpen}
        role={options.find((option) => option.value === chosenRole)?.label || ""}
        roleId={chosenRole}
        refetch={refetch}
      />
    </div>
  );
}

export default ModalAddNewStaff;
