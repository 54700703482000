import styled from 'styled-components';
import colors from 'constants/colors';
import Resizer from 'assets/svg/Resizer.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.white1};

  .label {
    font-size: ${(props) => (props.mainPage ? '10px' : '11px')};
    font-weight: ${(props) => (props.mainPage ? '600' : '300')};
    color: ${(props) => (props.mainPage ? colors.grey5 : colors.white1)};
    letter-spacing: 2.5px;
    margin-bottom: 5px;
    white-space: nowrap;
  }

  textarea {
    min-height: 144px;
    resize: ${(props) => (props.resize? props.resize: 'none')};
    overflow: auto;
    color: ${colors.white1};
    background-color: ${(props) => (props.mainPage ? 'transparent' : colors.grey17)};
    font-size: 14px;
    font-weight: 600;
    padding: 9px;
    border: 1px solid ${colors.grey1};
    width: 100%;

    ::-webkit-resizer {
      background: url(${Resizer});
    }

    :focus-visible {
      outline: none;
      background-color: ${colors.black5};
    }
  }

  .warning {
    width: 100%;
    height: 20px;
    line-height: 20px;
    color: ${colors.red1};
    font-size: 14px;
    font-weight: 600;
    white-space: normal;
    word-wrap: break-word; 
    word-break: break-all;
    overflow: visible;
    text-align: left;
    margin-top: 5px;
  }

  ${(props) => props.additionalstyle};
`;
