import React from 'react';
import { CheckboxContainer } from './styles';

const Checkbox = ({
  disabled,
  onChange,
  checked,
  title,
  className,
  additionalstyle,
  backgroundColor,
  checkColor
}) => (
  <CheckboxContainer
    className={className}
    backgroundColor={backgroundColor}
    checkColor={checkColor}
    additionalstyle={additionalstyle}
  >
    <input
      className="input"
      disabled={disabled}
      type="checkbox"
      checked={checked}
      readOnly
    />
    <div className="checkbox"
      onClick={onChange}
      backgroundColor={backgroundColor}
    />
    {title}
  </CheckboxContainer>
);

export default Checkbox;
