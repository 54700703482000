import React from 'react';
import Card from 'components/Card';
import TextArea from 'components/TextArea';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import { MessageFormContainer } from './styles';

const SendStaffMessage = () => {
  return (
    <Card>
      <MessageFormContainer>
        <TextArea
          mainPage
          resize={'vertical'}
          title="SEND A MESSAGE TO THIS STAFF MEMBER"
          onChange={(e) => { }} 
          additionalstyle={`
            flex-basis: 79%;
            
            textarea {
              height: 170px;
            }
          `}
        />
        <Dropdown
          mainPage
          options={['Text Message']}
          value="Text Message"
          title="SEND AS"
          onChange={(e) => { }}
          additionalstyle={`
            flex-basis: 12%;
            margin-left: 2%;
            .select {
              height: 47px;
            }
          `}
        />
        <div>
          <Button
            mainPage
            additionalstyle={`
              width: 65px;
              margin-top: 20px;
            `}
          >
            Send
          </Button>
        </div>
      </MessageFormContainer>
    </Card>
  )
}

export default SendStaffMessage;
