import React, { useState} from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import { useMutation } from '@apollo/client';
import { DELETE_STAFF_BY_STAFF_ID } from 'graphql/service';
import { Container } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalRemoveStaff = ({
  buttonIcon,
  buttonLabel,
  buttonStyle,
  name,
  staffId
}) => {

  const [isOpen, setIsOpen] = useState(false);

  const [deleteStaff] = useMutation(DELETE_STAFF_BY_STAFF_ID,
    {
      onCompleted: (data) => {
        if(data.deleteStaff.id) {
          setIsOpen(false)
        } else {
          const content = 'Remove staff failed!'
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  const handleRemove = () => {
    deleteStaff({
      variables: {
        staffId
      }
    })
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      buttonIcon={buttonIcon}
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      danger={true}
      className="modal-danger"
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            height: 100%;
            padding: 24px;
          }
        }
        top: 179px;
      `}
    >
      <Container>
        <div className='text'>{`Are you sure you want to remove ${name} from the staff list?`}</div>
        <Button
          danger
          onClick={() => setIsOpen(false)}
          additionalstyle={`
            width: 79px;
            margin-top: 24px;
            margin-right: 16px;
          `} 
        >
          Cancel
        </Button>
        <Button
          success
          onClick={handleRemove}
          additionalstyle={`
            width: 56px;
            margin-top: 24px;
          `}
        >
          Yes
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalRemoveStaff;
