import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ProgressBar from 'components/ProgressBar';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_AVAILABLE_KIOSKS, PASSPORT_SCAN } from 'graphql/service';
import { Head, Container, Content, Footer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ConfirmIdentity = () => {

  const history = useHistory()
  const groupId = useParams().groupId

  const [options, setOptions] = useState([
    {label: "Manual Check", value: "Manual Check"},
  ])

  const [method, setMethod] = useState("")

  const { data, loading, error } = useQuery(GET_ALL_AVAILABLE_KIOSKS,
    {
      onCompleted: (availableKiosksData) => {
        if(availableKiosksData.availableKiosks.length > 0) {
          const newOptions = availableKiosksData.availableKiosks?.map((kiosk) => ({label: kiosk.username, value: kiosk.kioskId}))
          newOptions.push({label: "Manual Check", value: "Manual Check"})
          setOptions(newOptions)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  const [passportScan] = useMutation(PASSPORT_SCAN,
    {
      onCompleted: (data) => {
        history.push(`/group-check-in/customer-identification/${groupId}`);
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )
    
  const handleSend = () => {
    if(method === "") {
      message.error({
        content: "Please select a method",
        duration: getMessageTimeOut("Please select a method"),
        onClick: () => {
          message.destroy()
        }
      })
    } else if(method === "Manual Check") {
      history.push(`/group-check-in/customer-identification/${groupId}`)
    } else {
      passportScan({
        variables: {
          input: {
            groupId: groupId,
            kioskId: method
          }
        }
      })
    }
  }

  return (
    <Container>
      <ProgressBar step={2} />
      <Content>
        <Head>Confirm Identity</Head>
        <div className='text'>
          <div>Which identity scanner device would you like to use?</div>
        </div>
        <Dropdown
          mainPage
          options={options}
          value={options.find(option => option.value === method)?.label || "- Select -"}
          title="PREFERRED METHOD"
          onChange={(e) => {
            setMethod(e)
          }}
        />
      </Content>
      <Footer>
        <div className='buttonContainer'>
          <Button
            success
            additionalstyle={`
              width: 72px;
            `}
            onClick={handleSend}
          >
            Send
          </Button>
        </div>
      </Footer>
    </Container>
  );
}

export default ConfirmIdentity;