import React from 'react';
import SubHeader from './components/SubHeader';
import RevenueReportTable from './components/RevenueReportTable';

const RevenueReport = () => {
  return (
    <>
      <SubHeader />
      <RevenueReportTable />
    </>
  );
};

export default RevenueReport;
