import styled from 'styled-components';
import colors from 'constants/colors';

export const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 29px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20%;
  margin: 48px 0 64px 0;

  .divider {
    width: 100%;
    height: 2px;
    margin: 0 2.5vw;  
    background-color: ${colors.grey9};
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;
  color: ${({highlight}) => highlight? colors.green1: colors.white1};
  column-gap: 16px;

  svg {
    filter: ${({highlight}) => highlight? `invert(71%) sepia(57%) saturate(4977%) hue-rotate(86deg) brightness(97%) contrast(101%)`: null};
  }
`;