import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import ModalResetPasswordSent from 'screens/ModalResetPasswordSent';
import { Container, Footer, Head } from './styles';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from 'graphql/service';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalResetPassword  = ({
  hidden,
  disabled,
  buttonLabel="",
  buttonStyle,
  email,
  phoneNumber,
  userType
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [sentIsOpen, setSentIsOpen] = useState(false)
  const [dropdownError, setDropdownError] = useState(false)
  
  const options =[
    {label: "Email", value: "email"},
    {label: "Phone", value: "phone"}
  ]

  const [method, setMethod] = useState("")

  const [resetPassword] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted: (data) => {
        if(data.forgotPassword.userId) {
          setIsOpen(false)
          setSentIsOpen(true)
        } else {
          const content = 'Password reset failed!'
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )
    
  const handleReset = () => {
    if(method === "") {
      setDropdownError(true)
    } else {
      resetPassword({
        variables: {
          input: {
            login: method === "email" ? email : phoneNumber
          }
        }
      })
    }
  }

  return (
    <>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="modal-success"
        buttonLabel={buttonLabel}
        buttonStyle={buttonStyle}
        hidden={hidden}
        disabled={disabled}
        additionalstyle={`
          max-width: 500px;
          .modal-content{
            .close {
              z-index: 200;
            }
            .modal-body {
              height: 100%;
              padding: 24px;
            }
          }
          top: 179px;
        `}
      >
        <Container>
          <Head>Reset Password</Head>
          <div className='text'>
            <div>{`How would you like to send this ${userType==='staff'? 'staff':'customer'} a password reset link, by phone or email?`}</div>
          </div>
          <Dropdown
            mainPage
            options={options}
            error={dropdownError}
            value={options.find(option => option.value === method)?.label || "- Select -"}
            title="PREFERRED METHOD"
            onChange={(e) => {
              setMethod(e)
              setDropdownError(false)
            }}
          />
        </Container>
        <Footer>
          <div className='buttonContainer'>
            <Button
              success
              additionalstyle={`
                width: 148px;
              `}
              onClick={handleReset}
            >
              Reset Password
            </Button>
          </div>
        </Footer>
      </MainModal>
      <ModalResetPasswordSent
        isOpen={sentIsOpen}
        setIsOpen={setSentIsOpen}
        type={method}
      />
    </>
  );
}

export default ModalResetPassword;
