import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Button from 'components/Button';
import { HistoryTable, HistoryContainer, NavigatorContainer, Navigator } from './styles';
import moment from 'moment';

const BookingHistory = ({ groups }) => {

  const history = useHistory();

  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 5
  const pageCount = Math.ceil(groups?.length/pageSize)
  const paginate = (array, page_size, page_number) => {
    const newArray = [...array]
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return newArray.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const previousPage = () => {
    if(pageNumber > 1) {
      setPageNumber((pageNumber) => pageNumber-1)
    }
  }

  const nextPage = () => {
    if(pageNumber < pageCount) {
      setPageNumber((pageNumber) => pageNumber+1)
    }
  }

  return (
    <HistoryContainer>
      <Card>
        <CardHeader>
          <div className="type">BOOKING HISTORY</div>
        </CardHeader>
      <HistoryTable>
        <thead>
          <tr>
            <th className="arrival">ARRIVAL</th>
            <th className="departure">DEPARTURE</th>
            <th className="roomType">ROOM TYPE</th>
            <th className="details"></th>
          </tr>
        </thead>
        <tbody>
          {paginate(groups, pageSize, pageNumber)?.map((group) => {
            const checkInDate = group.group?.bookings?.[0]?.checkInDate;
            const checkOutDate = group.group?.bookings?.[0]?.checkOutDate;
            return (
              <tr key={group.group.groupId}>
                <td>{moment(checkInDate).format("M/D/YYYY h:mmA")}</td>
                <td>{moment(checkOutDate).format("M/D/YYYY h:mmA")}</td>
                <td>
                  {
                    group.group.bookings?.[0]?.roomBookings?.[0]?.room.roomType.name
                  }
                </td>
                <td>
                  <Button
                    mainPage
                    onClick={() =>
                      history.push(`/group-details/${group.group.groupId}}`)
                    }
                    additionalstyle={`
                        width: 106px;
                      `}
                  >
                    View booking
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </HistoryTable>
      {groups?.length > pageSize ? (
        <NavigatorContainer>
          <Navigator
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            {"<"} Back
          </Navigator>
          <Navigator
            disabled={pageNumber >= groups.length / pageSize}
            onClick={nextPage}
          >
            Next {">"}
          </Navigator>
        </NavigatorContainer>
      ) : null}
      </Card>
    </HistoryContainer>
  );
};

export default BookingHistory;
