import styled from 'styled-components';
import Search from 'assets/svg/Search.svg'
import colors from 'constants/colors';

export const SearchResultsContainer = styled.div`
  position: fixed;
  top: 50px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.black5};
  height: 100vh;

  @media only screen and (min-width: 1201px){
    display: ${({ mobile }) => (mobile? 'none': 'flex')};
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    height: calc(100vh - 110px);
    display: ${(props) => (props.open? 'flex': 'none')};
    z-index: 1000;
  }

  @media only screen and (max-width: 767px) {
    height: calc(100vh - 95px);
    display: ${(props) => (props.open? 'flex': 'none')};
    z-index: 1000;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px 10%;

  .result {
    width: 100%;
    color: ${colors.white1};
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    margin: 40px 0 15px 0;
  }

  .totalResults {
    width: 100%;
    color: ${colors.white1};
    font-size: 11px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    text-align: left;
    background-color: ${colors.grey2};
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 25px 30px 10px 30px;
  }

  @media only screen and (max-width: 767px) {
    padding: 10px 15px 10px 15px;
  }
`;

export const StyledInput = styled.input`
  width: 35%;
  height: 40px;
  color: ${colors.white1};
  font-size: 18px;
  font-weight: 600;
  display: block;
  background-image: url(${Search});
  background-color: ${colors.grey28};
  background-repeat: no-repeat;
  background-position: 2% 50%;
  padding: 0 25px 0 40px;
  border: none;
  outline: none;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    width: 60%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledList = styled.div`
  width: 100%;
`;

export const StyledItem = styled.div`
  width: 100%;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  font-weight: 600;
  padding-left: 20px;
  color: ${colors.grey18};
  background-color: ${colors.black5};
  overflow: hidden;
  cursor: pointer;

  .type {
    font-style: normal;
    font-style: italic;
  }

  .highlight {
    font-weight: bold;
    color: ${colors.blue1};
  }

  :hover {
    border-left: 5px solid ${colors.blue1};
    background-color: ${colors.black9};
    padding-left: 15px;
  }
`;

export const NavigatorContainer = styled.div`
  width: 100%;
  display: flex;
  height: 47px;
  justify-content: space-between;
  align-items: center;
  padding: 0 40%;
  background-color: ${colors.grey2};

  .pageControl {
    display: flex;
  }

  .totalPages {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.blue1};
  }

  input {
    width: 25px;
    color: ${colors.white1}; 
    background-color: transparent;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    margin-right: 5px;
    border: 1px solid ${(props) => props.error? colors.red1: colors.grey1};

    :focus-visible {
      outline: none;
      background-color: ${colors.black5};
    }

    ::-webkit-inner-spin-button, 
    ::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 0 30%;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 20%;
  }
`;

export const Navigator = styled.div`
  color: ${colors.blue1};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  ${(props) => props.disabled ? `
    pointer-events:none;
    color: ${colors.grey8}
  `: null }
`;
