import React from 'react';
import Room from 'screens/Home/components/Room';
import {
  Container,
  FloorImageCenterContent,
  EightfloorInnerLeftBox,
  EightfloorInnerCenterBox,
  EightfloorInnerRightBox,
} from './styles';

const EighthFloor = ({ scale, desktop, backgroundColor, rooms }) => {
  
  return (
    <Container scale={scale} desktop={desktop} backgroundColor={backgroundColor}>
      <div className='top'>
        <Room className="room8_24" width="46px" height="48px" roomData={rooms['824']} roomNumber='824' floorBackgroundColor={backgroundColor} />
        <Room className="room8_25" width="23px" height="43px" roomData={rooms['825']} roomNumber='825' floorBackgroundColor={backgroundColor} />
        <Room className="room8_26" width="26px" height="43px" roomData={rooms['826']} roomNumber='826' floorBackgroundColor={backgroundColor} />
        <Room width="84px" height="43px" disabled />
        <Room className="room8_1" width="25px" height="43px" roomData={rooms['801']} roomNumber='801' floorBackgroundColor={backgroundColor} />
        <Room className="room8_2" width="25px" height="43px" roomData={rooms['802']} roomNumber='802' floorBackgroundColor={backgroundColor} />
        <Room className="room8_3" width="46px" height="48px" roomData={rooms['803']} roomNumber='803' floorBackgroundColor={backgroundColor} />
      </div>
      <div className='middle'>
        <div className='left'>
          <Room className="room8_23" width="42px" height="24px" roomData={rooms['823']} roomNumber='823' floorBackgroundColor={backgroundColor} />
          <Room className="room8_22" width="42px" height="23px" roomData={rooms['822']} roomNumber='822' floorBackgroundColor={backgroundColor} />
          <Room className="room8_21" width="42px" height="40px" roomData={rooms['821']} roomNumber='821' floorBackgroundColor={backgroundColor} />
          <Room className="room8_20" width="42px" height="30px" roomData={rooms['820']} roomNumber='820' floorBackgroundColor={backgroundColor} />
          <Room className="room8_19" width="42px" height="23px" roomData={rooms['819']} roomNumber='819' floorBackgroundColor={backgroundColor} />
          <Room className="room8_18" width="42px" height="24px" roomData={rooms['818']} roomNumber='818' floorBackgroundColor={backgroundColor} />
        </div>
          <FloorImageCenterContent>
            <EightfloorInnerLeftBox>
              <div className="saunaRoom">
                <Room className="room8_45" roomData={rooms['845']} roomNumber='845' roomName="saunaRoom" floorBackgroundColor={backgroundColor} />
                <Room className="room8_44" roomData={rooms['844']} roomNumber='844' roomName="saunaRoom" floorBackgroundColor={backgroundColor} />
              </div>
              <div>
                <Room className="room8_30" roomData={rooms['830']} roomNumber='830' roomName="maleLockerRoom" floorBackgroundColor={backgroundColor} />
              </div>
              <div>
                <Room className="room8_38" roomData={rooms['838']} roomNumber='838' roomName="gym" floorBackgroundColor={backgroundColor} />   
              </div>
            </EightfloorInnerLeftBox>
            <div>
              <div>
                <EightfloorInnerCenterBox>
                  <div className="row1">
                    <div></div>
                    <div></div>
                  </div>
                  <div className="row2"></div>
                  <div className="row3">
                    <Room className="room8_54" roomData={rooms['854']} roomNumber='854' roomName="leftElevator" disabled />
                    <Room className="room8_55" roomData={rooms['855']} roomNumber='855' roomName="rightElevator" disabled />
                  </div>
                </EightfloorInnerCenterBox>
              </div>
            </div>
            <EightfloorInnerRightBox>
              <div className="saunaRoom">
                <Room className="room8_42" roomData={rooms['842']} roomNumber='842' roomName="saunaRoom" floorBackgroundColor={backgroundColor} />
                <Room className="room8_43" roomData={rooms['843']} roomNumber='843' roomName="saunaRoom" floorBackgroundColor={backgroundColor} />
              </div>
              <div className="femaleLockerRoom">
                <Room className="room8_29" roomData={rooms['829']} roomNumber='829' roomName="femaleLockerRoom" floorBackgroundColor={backgroundColor} />
              </div>
            </EightfloorInnerRightBox>
          </FloorImageCenterContent>
        <div className='right'>
          <Room className="room8_4" width="42px" height="24px" roomData={rooms['804']} roomNumber='804' floorBackgroundColor={backgroundColor} />
          <Room className="room8_5" width="42px" height="23px" roomData={rooms['805']} roomNumber='805' floorBackgroundColor={backgroundColor} />
          <Room className="room8_6" width="42px" height="40px" roomData={rooms['806']} roomNumber='806' floorBackgroundColor={backgroundColor} />
          <Room className="room8_7" width="42px" height="30px" roomData={rooms['807']} roomNumber='807' floorBackgroundColor={backgroundColor} />
          <Room className="room8_8" width="42px" height="23px" roomData={rooms['808']} roomNumber='808' floorBackgroundColor={backgroundColor} />
          <Room className="room8_9" width="42px" height="24px" roomData={rooms['809']} roomNumber='809' floorBackgroundColor={backgroundColor} />
        </div>
      </div>
      <div className='bottom'>
        <Room className="room8_17" width="46px" height="48px" roomData={rooms['817']} roomNumber='817' floorBackgroundColor={backgroundColor} />
        <Room className="room8_16" width="23px" height="43px" roomData={rooms['816']} roomNumber='816' floorBackgroundColor={backgroundColor} />
        <Room className="room8_15" width="37px" height="43px" roomData={rooms['815']} roomNumber='815' floorBackgroundColor={backgroundColor} />
        <Room className="room8_14" width="26px" height="43px" roomData={rooms['814']} roomNumber='814' floorBackgroundColor={backgroundColor} />
        <Room className="room8_13" width="35px" height="43px" roomData={rooms['813']} roomNumber='813' floorBackgroundColor={backgroundColor} />
        <Room className="room8_12" width="35px" height="43px" roomData={rooms['812']} roomNumber='812' floorBackgroundColor={backgroundColor} />
        <Room className="room8_11" width="23px" height="43px" roomData={rooms['811']} roomNumber='811' floorBackgroundColor={backgroundColor} />
        <Room className="room8_10" width="46px" height="48px" roomData={rooms['810']} roomNumber='810' floorBackgroundColor={backgroundColor} />
      </div>
    </Container>
  )
}

export default EighthFloor
