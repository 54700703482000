import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Head, Container, Content, Footer } from './styles';

const CustomerIdentification = () => {

  const history = useHistory()
  const groupId = useParams().groupId

  return (
    <Container>

    </Container>
  );
}

export default CustomerIdentification;