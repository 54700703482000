import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import ModalTransactionSuccessfully from 'screens/ModalTransactionSuccessfully';
import ModalTransactionFailed from 'screens/ModalTransactionFailed';
import { useMutation } from '@apollo/client';
import { SETTLE_PAYMENT } from 'graphql/service';
import colors from 'constants/colors';
import { Container } from './styles';

const ModalSettlePayment = ({
  buttonStyle,
  groupId,
  refetch,
  balance,
  primaryCard,
}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [successfullyIsOpen, setSuccessfullyIsOpen] = useState(false)
    const [failedIsOpen, setFailedIsOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const cardNumber = primaryCard?.transaction?.creditCardNumber?.slice(-4)
    
    const [settlePayment, {loading}] = useMutation(SETTLE_PAYMENT, {
      onCompleted: () => {
        setSuccessfullyIsOpen(true)
        setIsOpen(false)
      },
      onError: (error) => {
        setErrorMessage(error.message)
        setFailedIsOpen(true)
        setIsOpen(false)
      }
    })

  const handleSettle = () => {
    if(loading) return
    settlePayment({
      variables: {
        groupId
      },
    });
  }

  return (
    <>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="modal-danger"
        buttonStyle={buttonStyle}
        buttonLabel={`Process Checkout Payment`}
        additionalstyle={`
          max-width: 500px;
          .modal-content{
            height: 100%;
            .close {
              z-index: 200;
            }   
            .modal-body{
              height: 100%;
              padding: 24px;
            }
          }
          background: ${colors.grey3};
          top: 179px;
        `}
      >
        <Container>
          <div className='text'>{`This guest has a pending hold for $${balance} on their card. Processing their payment now will release that hold back to their card. Please be sure you have completed a room inspection for damages before you release this hold.`}</div>
          <div className='buttonContainer'>
            <Button
              danger
              onClick={() => setIsOpen(false)}
              additionalstyle={`
                width: 79px;
                margin-right: 16px;
              `} 
            >
              Cancel
            </Button>
            <Button
              success
              loading={loading}
              onClick={handleSettle}
              additionalstyle={`
                width: 68px;
              `}
            >
              {loading? null: 'Process'}
            </Button>
          </div>
        </Container>
      </MainModal>
      <ModalTransactionSuccessfully
        isOpen={successfullyIsOpen}
        setIsOpen={setSuccessfullyIsOpen}
        amount={balance}
        cardNumber={cardNumber}
        refetch={refetch}
        type='settlement'
      />
      <ModalTransactionFailed
        isOpen={failedIsOpen}
        setIsOpen={setFailedIsOpen}
        amount={balance}
        cardNumber={cardNumber}
        loading={loading}
        retryFunction={handleSettle}
        errorMessage={errorMessage}
        type='settlement'
      />
    </>
  );
}

export default ModalSettlePayment;
