import styled from 'styled-components';
import colors from 'constants/colors';

export const BookingTimelineContainer = styled.div`
  width: 100%;
  background-color: ${colors.black5};
  padding-bottom: 10px;
  margin-top: ${props => props.fixedHeader? '100px': null};

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    padding-bottom: 70px;
  }

  @media only screen and (max-width: 767px) {
    padding-bottom: 55px;
  }


  .timelineHeaders {
    border-top: 1px solid ${colors.grey8};
    & > :nth-child(1) {
      border-right: 1px solid ${colors.white1};
    }
    ${props => props.fixedHeader? `
      position: fixed;
      top: 100px;
      z-index: 1000;
    `: null};
  }

  ${props => props.fixedHeader? `
    .timelineHeaders {
      position: fixed;
      top: 100px;
      z-index: 500;
    }

    .rct-outer {
      margin-top: 151.6px;
    }
  `: null};

  .timelineHeader {
    border-top: none;
    border-bottom: none;
    div {
      border-top: none !important;
      border-bottom: none !important;
      border-left: none !important;
    }
  }

  .react-calendar-timeline .rct-calendar-header {
    border: none;
  }

  .react-calendar-timeline .rct-header-root {
    background: none;
  }

  .react-calendar-timeline .rct-sidebar {
    position: static;
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even, 
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border-bottom: 1px solid ${colors.grey23};
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid ${colors.grey23};
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
    background-color: ${colors.black7};
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background-color: ${colors.grey3};
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: none;
  }

  .rct-item {
    .rct-item-content {
      width: 100%;
      height: auto;
      padding: 0 !important;
    }
  }
`;

export const TimelineHeaderContainer = styled.div`
  .today {
    color: ${colors.black5} !important;
    background-color: ${colors.green1} !important;
    border-right:1px solid ${colors.green1} !important;
  }
`;
