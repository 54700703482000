import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`

  position: relative;
  display: flex;
  flex-direction: column;
  color: ${colors.white1};

  .label {
    font-size: ${(props) => (props.mainPage ? '12px' : '11px')};
    font-weight: ${(props) => (props.mainPage ? '600' : '300')};
    color: ${(props) => (props.mainPage ? colors.grey5 : colors.white1)};
    letter-spacing: 2.5px;
    margin-bottom: 5px;
    white-space: nowrap;
  }

  input {
    color: ${(props) => (props.disabled? colors.grey30: colors.white1)};
    background-color: ${(props) => (props.disabled? colors.grey31: props.mainPage ? 'transparent' : colors.grey17)};
    font-size: ${(props) => (props.mainPage ? '14px' : '18px')};
    font-weight: 600;
    padding: 9px;
    border: 1px solid ${(props) => props.error? colors.red1: colors.grey1};
    width: 100%;
    height: 40px;
    letter-spacing: 0.5px;

    ${({disabled})=>disabled? `
      border: none;
      padding: 0;
    `:null};

    :focus-visible {
      outline: none;
      background-color: ${colors.black5};
    }

    :disabled {
      padding: 9px;
    }
  }

  .warning {
    width: 100%;
    height: 20px;
    line-height: 20px;
    color: ${colors.red1};
    font-size: 14px;
    font-weight: 600;
    white-space: normal;
    word-wrap: break-word; 
    word-break: break-all;
    overflow: visible;
    text-align: left;
    margin-top: 5px;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    span {
      font-size: 11px;
    }

    input {
      font-size: ${(props) => (props.mainPage ? '15px' : '18px')};
    }
  }

  @media only screen and (max-width: 767px) {
    span {
      font-size: 11px;
    }

    input {
      font-size: ${(props) => (props.mainPage ? '13px' : '18px')};
    }
  }

  ${(props) => props.additionalstyle};
`;
