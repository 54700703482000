import styled from 'styled-components';

export const WaveformContainer = styled.div`
	height: 60px;
	width: 100%;
	display: flex;
	align-items: center;

  .canvas {
    display: block;
    cursor: pointer;
  }
`;