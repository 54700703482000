import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import { SuccessIcon } from 'assets/icons';
import { Container } from './styles';

const ModalTransactionSuccessfully = ({
  isOpen,
  setIsOpen,
  amount,
  cardNumber,
  role,
  fullName,
  setAmount,
  setDetails,
  refetch,
  type
}) => {

  const closeModal = () => {
    refetch()
    if(setAmount) {
      setAmount('')
    }
    if(setDetails) {
      setDetails('')
    }
    setIsOpen(false)
  }


  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={closeModal}
      className="modal-success"
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            height: 100%;
            padding: 24px;
          }
        }
        top: 179px;
      `}
    >
      <Container>
        <SuccessIcon />
        <div className="text">
          {type === "void"
            ? `You have successfully voided the $${Number(amount).toFixed(
                2
              )} transaction of the card ending in ${cardNumber?.slice(-4)}.`
            : type === "settlement"
            ? `The payment has been processed successfully. Any remaining hold on this customer’s card has been released.`
            :type === "createStaff"
            ? `You have successfully added ${fullName} as a ${role}.`
            : `The ${type} of $${Number(amount).toFixed(
                2
              )} to the card ending in ${cardNumber?.slice(
                -4
              )} has been successfully processed.`}
        </div>
        <Button
          success
          onClick={closeModal}
          additionalstyle={`
            width: 70px;
            margin-top: 24px;
          `}
        >
          Close
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalTransactionSuccessfully;
