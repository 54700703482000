import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 24px;

  .text {
    color: ${colors.white1};
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
`;
