import React from 'react'
import { useSelector } from 'react-redux'
import { TimelineHeaderContainer } from './styles';
import { setPickedDate } from 'redux/actions'
import { useDispatch } from 'react-redux'
import colors from 'constants/colors'
import moment from 'moment'

const TimelineHeader = ({
  intervals,
  getRootProps,
  getIntervalProps,
}) => {

  const pickedDate = useSelector(state => state.pickedDateReducer.pickedDate)
  const dispatch = useDispatch()

  return (
    <TimelineHeaderContainer {...getRootProps()}>
      {
        intervals.map((interval) => {
          const intervalStyle = {
            height: '50px',
            textAlign: 'left',
            borderTop: `2px solid ${colors.grey8}`,
            borderBottom: `2px solid ${colors.grey8}`,
            borderRight: `1px solid ${colors.grey23}`,
            cursor: 'pointer',
            backgroundColor: colors.black5,
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '0 10px',
            letterSpacing: '1px',
            color: colors.grey5,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          };

          const date = interval.startTime.format('M/DD/YYYY');
          const week = interval.startTime.format('dddd');

          return (
            <div
              className={moment(pickedDate).format('M/DD/YYYY') === date ? 'today': null}
              key={interval.startTime}
              onClick={() => {
                dispatch(setPickedDate(moment(date).valueOf()))
              }} 
              {...getIntervalProps({
                interval,
                style: {
                  ...intervalStyle,
                },
              })}
            >
              <div>
                <div>{week}</div>
                <div>{date}</div>
              </div>
            </div>
          )
        })
      }
    </TimelineHeaderContainer>
  );
};

export default TimelineHeader;
