import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: ${colors.green1};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  height: 100%;
`;

export const Content = styled.div`
  width: 500px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .text {
    color: ${colors.white1};
    margin: 25px 0 15px 0;
  }

  .list {
    margin-bottom: 20px;
  }
`;

export const Footer = styled.div`
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
