import styled from 'styled-components';

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 16px;

  .email, .phone {
    width: 100%;
    position: relative;
  }
  
  @media only screen and (max-width: 767px) {
    
  }
`;