import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import Header from 'components/Header';
import StatusBar from 'components/StatusBar';
import StatusTooltip from 'components/StatusTooltip';
import CheckInRoutes from './Components/CheckInRoutes';
import FooterBar from 'components/FooterBar';
import { useQuery } from '@apollo/client';
import { GET_GROUP_DETAILS_BY_GROUP_ID } from 'graphql/service';
import moment from 'moment';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const GroupCheckIn = (props) => {

  const history = useHistory()
  const location = useLocation()
  const pathSegments = location.pathname.split('/');
  const groupId = pathSegments[pathSegments.length - 1];
  const [groupStatus, setGroupStatus] = useState('')
  const [groupName, setGroupName] = useState('')
  const [bookings, setBookings] = useState([])

  const { data: groupData, loading, error, refetch } = useQuery(GET_GROUP_DETAILS_BY_GROUP_ID, {
    variables: { groupId: groupId },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (groupData) => {
      if(groupData) {
        const { name, primaryBookingStatus, bookings } = groupData.groupByID
        setGroupStatus(primaryBookingStatus)
        setGroupName(name)
        const earlyCheckInBookings = bookings.filter(booking => (moment() < moment(booking.checkInDate).set('hour', 15)) && (moment(booking.checkInDate)).set('hour', 3) <= moment())
        setBookings(earlyCheckInBookings)
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>Group Details - Rook Hotel PMS</title>
      </Helmet>
      <Header
        subHeader={
          <div className="title">
            <span
              className="highlight"
              onClick={() => {
                history.push("/bookings");
              }}
            >
              Bookings
            </span>
            {` > ${groupName}`}
            <StatusBar status={groupStatus} />
            {groupStatus === "CANCELLED" ? (
              <StatusTooltip
                createdAt={groupData.groupByID.cancelledAt}
                createdBy={groupData.groupByID.cancelledBy?.fullName}
                type={"Cancellation"}
                reason={groupData.groupByID.cancellationReason}
                amount={groupData.groupByID.cancellationFee}
              />
            ) : null}
          </div>
        }
      />
      <CheckInRoutes bookings={bookings}/>
      <FooterBar />
    </>
  );
}

export default GroupCheckIn;