import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import { Container } from './styles';
import { SuccessIcon } from 'assets/icons';

const ModalMakeCardPrimarySuccessfully = ({
  isOpen,
  setIsOpen,
}) => {

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            height: 100%;
            padding: 24px;
          }
        }
        top: 179px;
      `}
    >
      <Container>
        <SuccessIcon />
        <div className='title'>Primary card updated successfully.</div>
        <div className='text'>We have successfully placed a hold on the new card, and released the hold on the old card.</div>
        <Button
          success
          onClick={() => setIsOpen(false)}
          additionalstyle={`
            width: 116px;
            margin-top: 24px;
          `} 
        >
          OK
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalMakeCardPrimarySuccessfully;
