import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import colors from 'constants/colors';
import { useMutation } from '@apollo/client';
import { DOWNLOAD_RECEIPT } from 'graphql/service';
import { Container } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalDownloadReceipt = ({
  buttonLabel,
  buttonStyle,
  groupId
}) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [downloadReceipt] = useMutation(DOWNLOAD_RECEIPT,
    {
      onCompleted: (data) => {
        const url = window.URL.createObjectURL(new Blob([data.downloadReceipt], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'receipt.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsOpen(false);
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    },
  );

  const handleDownloadReceipt = () => {
    downloadReceipt({
      variables: {
        input: {
          groupId
        }
      }
    });
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      success
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            position: relative;
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
    >
      <Container>
        <div className='text'>{`Are you sure you want to download the receipt of this group?`}</div>
        <Button
          danger
          onClick={() => setIsOpen(false)}
          additionalstyle={`
            width: 79px;
            margin-top: 24px;
            margin-right: 16px;
          `} 
        >
          Cancel
        </Button>
        <Button
          success
          onClick={handleDownloadReceipt}
          additionalstyle={`
            width: 158px;
            margin-top: 24px;
          `}
        >
          Download as PDF
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalDownloadReceipt;
