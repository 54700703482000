export const getMessageTimeOut = (content) => {
  return Math.max(content.length * 50, 7000)
}

export const dateDiff = (startDay, endDay) => {
  if (!startDay || !endDay) return 0
  if (startDay >= endDay) return 0
  const _MS_PER_DAY = 1000 * 60 * 60 * 24

  const start = new Date(startDay)
  const end = new Date(endDay)

  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}