import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPickedDate } from 'redux/actions'
import moment from 'moment';
import { TimelineSubHeaderContainer } from '../../styles';
import {
  DoubleLeftDateIcon,
  DoubleRightDateIcon,
} from 'assets/icons';
import DatePicker from 'components/DatePicker';
import colors from 'constants/colors';

const SubHeader = ({ timelineState }) => {
  
  const pickedDate = useSelector(state => state.pickedDateReducer.pickedDate)
  const dispatch = useDispatch() 
  const { state, setState } = timelineState;
  const onPreWeekClick = () => {
    const zoom = (state.defaultTimeEnd - state.defaultTimeStart) * 7;
    dispatch(setPickedDate(moment(pickedDate).subtract(7, 'day').valueOf()))
    setState((state) => {
      return {
        ...state,
        visibleTimeStart: state.visibleTimeStart - zoom,
        visibleTimeEnd: state.visibleTimeEnd - zoom,
        today: moment(state.today).subtract(7, 'day').valueOf(),
        endToday: moment(state.endToday).subtract(7, 'day').valueOf(),
      };
    });
  };

  const onNextWeekClick = () => {
    const zoom = (state.defaultTimeEnd - state.defaultTimeStart) * 7;
    dispatch(setPickedDate(moment(pickedDate).add(7, 'day').valueOf()))
    setState((state) => {
      return {
        ...state,
        visibleTimeStart: state.visibleTimeStart + zoom,
        visibleTimeEnd: state.visibleTimeEnd + zoom,
        today: moment(state.today).add(7, 'day').valueOf(),
        endToday: moment(state.endToday).add(7, 'day').valueOf(),
      };
    });
  };

  return (
    <TimelineSubHeaderContainer>
      <div className="contentContainer">
        <DoubleLeftDateIcon
          onClick={onPreWeekClick}
          style={{ cursor: 'pointer' }}
        />
        <div className="dateSection">
          <DatePicker
            dateValue={moment(pickedDate)}
            setDate={(date)=>{
              dispatch(setPickedDate(date))
            }}
            timelineState={timelineState}
            variant="standard"
            additionalstyle={`
              width: 137px;

              input{
                padding-left: 40px;
                padding-right: 0px;
                border-bottom: 2px solid ${colors.grey1};
              }

              .MuiInput-root {
                margin-top: 0;
              }
            `}
          />
        </div>
        <DoubleRightDateIcon
          onClick={onNextWeekClick}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </TimelineSubHeaderContainer>
  );
};

export default SubHeader;
