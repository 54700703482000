import React from 'react';
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  CustomHeader,
  TimelineMarkers,
  CustomMarker,
} from 'react-calendar-timeline';
import { BookingTimelineContainer } from './styles';
import SideBarTitleContainer from './SideBarTitleContainer';
import TimelineHeader from './TimelineHeader';
import colors from 'constants/colors';;

const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end',
  groupLabelKey: 'title',
};

const TimelineTable = ({
  timelineState,
  items,
  SideBarTitle,
  SideBarItem,
  itemRenderer,
  ClientModal,
  refetch,
  handleItemClick,
  handleItemMove,
  clientModalOpen,
  setClientModalOpen,
  bookingDetailsData,
  sidebarWidth=100,
  fixedHeader,
}) => {
  const {
    defaultTimeStart,
    defaultTimeEnd,
    visibleTimeStart,
    visibleTimeEnd,
    today,
    endToday,
  } = timelineState.state;

  return (
    <BookingTimelineContainer open={clientModalOpen} fixedHeader={fixedHeader}>
      <Timeline
        groups={SideBarItem}
        items={items}
        keys={keys}
        fullUpdate
        minZoom={60 * 60 * 1000 * 24 * 10}
        maxZoom={60 * 60 * 1000 * 24 * 10}
        itemTouchSendsClick={false}
        stackItems
        sidebarWidth={sidebarWidth}
        itemHeightRatio={1}
        onItemClick={(itemId, e, time) => {
          if(handleItemClick) {
            handleItemClick(itemId, e, time);
          }
        }}
        onItemSelect={(itemId, e, time) => {
          if(handleItemClick) {
            handleItemClick(itemId, e, time);
          }
        }}
        onItemMove={(itemId, dragTime, newGroupOrder) => {
          if(handleItemMove) {
            handleItemMove(itemId, dragTime, newGroupOrder)
          }
        }}
        defaultTimeStart={defaultTimeStart}
        defaultTimeEnd={defaultTimeEnd}
        visibleTimeStart={visibleTimeStart}
        visibleTimeEnd={visibleTimeEnd}
        itemRenderer={itemRenderer}
      >
        <TimelineHeaders
          className="timelineHeaders"
          calendarHeaderClassName="timelineHeader"
        >
          <SidebarHeader>
            {({ getRootProps }) => (
              <SideBarTitleContainer getRootProps={getRootProps} title={SideBarTitle} />
            )}
          </SidebarHeader>
          <CustomHeader height={50} unit="day">
            {({
              headerContext: { intervals },
              getRootProps,
              getIntervalProps,
            }) =>
              TimelineHeader({
                intervals,
                getRootProps,
                getIntervalProps,
              })
            }
          </CustomHeader>
        </TimelineHeaders>
        <TimelineMarkers>
          <CustomMarker date={today}>
            {({ styles }) => {
              const customStyles = {
                ...styles,
                backgroundColor: colors.green1,
                width: '1px',
                zIndex: '200'
              };
              return <div style={customStyles} />;
            }}
          </CustomMarker>
          <CustomMarker date={endToday}>
            {({ styles }) => {
              const customStyles = {
                ...styles,
                backgroundColor: colors.green1,
                width: '1px',
                zIndex: '200'
              };
              return <div style={customStyles} />;
            }}
          </CustomMarker>
        </TimelineMarkers>
      </Timeline>
      {ClientModal ? (
        <ClientModal
          clientModalOpen={clientModalOpen}
          setClientModalOpen={setClientModalOpen}
          bookingDetailsData={bookingDetailsData}
          refetch={refetch}
        />
      ) : null}
    </BookingTimelineContainer>
  );
};

export default TimelineTable;
