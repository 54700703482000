import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ProgressBar from 'components/ProgressBar';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { useMutation } from '@apollo/client';
import {
  SUBSCRIBE_BOOKING_ADD_ONS
} from 'graphql/service';
import { Head, Container, Content, Footer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const EarlyCheckIn = ({bookings}) => {

  const history = useHistory()
  const groupId = useParams().groupId
  const [earlyCheckInFee, setEarlyCheckInFee] = useState(true)
  const [addOns, setAddOns] = useState([])

  const [subscribeBookingAddOns] = useMutation(SUBSCRIBE_BOOKING_ADD_ONS,
    {
      onCompleted: (data) => {
        history.push(`/group-check-in/confirm-identity/${groupId}`);
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )
  
  useEffect(() => {
    const originalAddOns = bookings?.map((booking) => {
      return {
        bookingId: booking.bookingId,
        id: "4c773a2c-0d0a-44ba-8680-d34244fea4d5", //early check in addon id
        quantity: booking.addons?.find(addon => addon.bookingAddOn.addonId === "4c773a2c-0d0a-44ba-8680-d34244fea4d5")?.quantity || 0
      }
    })
    setAddOns(originalAddOns)
  }, [bookings])

  const checkBoxArray = bookings?.map((booking, index) => {
    return (
      <Checkbox
        key={booking.bookingId}
        checked={addOns?.[index]?.quantity > 0}
        onChange={() => {
          const newAddOns = [...addOns]
          newAddOns[index].quantity = newAddOns[index].quantity > 0 ? 0 : 1
          setAddOns(newAddOns)
        }}
        title={`Room: ${index+1} ${booking.fullName}`}
      />
    )
  })
    
  const handleContinue = () => {
    subscribeBookingAddOns({
      variables: {
        input: {
          addOns: addOns
        }
      }
    })
  }

  return (
    <Container>
      <ProgressBar step={1}/>
      <Content>
        <Head>Early Checkin</Head>
        <div className='text'>
          <div>This customer does not check in until 3:00PM.</div>
          <div>Which rooms would you like to check {bookings?.length>1? 'them': bookings?.[0]?.fullName} in early?</div>
        </div>
        {bookings?.length > 1? (
          <div className='list'>
            {checkBoxArray}
          </div>
        ): null}
      </Content>
      <Footer>
        <Checkbox
          checked={!earlyCheckInFee}
          onChange={(e) => setEarlyCheckInFee(!earlyCheckInFee)}
          title="Do not apply early check in fee"
        />
        <Button
          success
          additionalstyle={`
            width: 104px;
          `}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Footer>
    </Container>
  );
}

export default EarlyCheckIn;