import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.span`
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: ${colors.white1};
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    margin-top: 15px;
    margin-bottom: 10px;
    color: ${colors.white1};

    .select {
      background-position: 96% 50%;
      background-size: 15px 15px;
    }

    span {
      font-weight: 300;
    }
  }
`;

export const Footer = styled.div`
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 13px;

  .select {
      background-position: 90% 50%;
      background-size: 15px 15px;
  }

  span {
      font-weight: 300;
  }
`;
