import React from 'react';
import { Container } from './styles';

const ShowInformation = ({
  title,
  description,
  width,
  warning,
  crossLine,
  additionalstyle
}) => (
  <Container
    width={width}
    additionalstyle={additionalstyle}
    warning={warning}
    crossLine={crossLine}
  >
    <div className='title'>{title}</div>
    <div className='info'>{description}</div>
  </Container>
);

export default ShowInformation;