import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerInfo } from 'redux/actions'
import Header from 'components/Header';
import FooterBar from 'components/FooterBar';
import Button from 'components/Button';
import ModalCheckOutConfirm from 'screens/ModalCheckOutConfirm';
import ModalBookingCancel from 'screens/ModalBookingCancel';
import ModalCancelConfirm from 'screens/ModalCancelConfirm';
import TravelArrangements from 'components/BookingMain/TravelArrangements';
import GroupBookingInfo from 'components/BookingMain/GroupBookingInfo';
import Room from 'components/BookingMain/Room';
import GuestInformation from 'components/BookingMain/GuestInformation';
import AddOns from 'components/BookingMain/AddOns';
import GeneralBookingNotes from 'components/BookingMain/GeneralBookingNotes';
import MetaInformation from 'components/BookingMain/MetaInformation';
import ActivityHistory from 'components/BookingMain/ActivityHistory';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  GET_BOOKING_DETAILS_BY_BOOKING_ID,
  GET_GROUP_DETAILS_BY_GROUP_ID,
  UPDATE_GROUP_BY_GROUP_ID,
} from 'graphql/service';
import { useCustomerForm } from 'customHook/useCustomerForm';
import { message } from 'antd';
import {
  Content,
  ContentColumn,
  Container,
} from 'components/BookingMain/styles';
import moment from 'moment';
import { getMessageTimeOut } from 'utils/util';
import StatusBar from 'components/StatusBar';

const BookingDetails = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history= useHistory()
  const bookingId = useParams().bookingId
  const dispatch = useDispatch()
  const customerInfo = useSelector(state => state.customerInfoReducer.customerInfo)
  const [groupId, setGroupId] = useState('')
  const [checkOutOpen, setCheckOutOpen] = useState(false)
  const [updateGroupByGroupId] = useMutation(UPDATE_GROUP_BY_GROUP_ID)

  //Header
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const [cancelRoomParams, setCancelRoomParams] = useState({})

  //GroupBookingInfo
  const [groupName, setGroupName] = useState('')
  const [tenantList, setTenantList] = useState({})
  const [getGroupDetailsByGroupId, { data: groupData, loading: groupLoading, error: groupError }] = useLazyQuery(
    GET_GROUP_DETAILS_BY_GROUP_ID,
    {
      onCompleted: (groupData) => {
        if(groupData) {
          const newList = []
          if(groupData.groupByID.bookings) {
            groupData.groupByID.bookings.forEach((booking) => {
              newList.push({label: booking?.tenantGroup?.tenant?.user?.fullName, value: booking.bookingId})
            })
          }
          setTenantList(newList)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  //StatusBar
  const [status, setStatus] = useState('')
  
  //Room 
  const [room, setRoom] = useState({})

  //TravelArrangements
  const [checkInDate, setCheckInDate] = useState(moment().hour(15).minute(0).second(0))
  const [checkOutDate, setCheckOutDate] = useState(moment().add(1,'days').hour(11).minute(0).second(0))

  //AddOns
  const [addons, setAddons] = useState([])

  //GeneralBookingNotes
  const [bookingNotes, setBookingNotes] = useState([])

  const { data: bookingDetailsData, loading, error, refetch } = useQuery(GET_BOOKING_DETAILS_BY_BOOKING_ID, {
    variables: { bookingId: bookingId },
    onCompleted: (bookingDetailsData) => {
      if(bookingDetailsData.bookingByID) {
        const newCustomer = {...customerInfo}
        const {
          email,
          phoneNumber,
          fullName,
          tenantGroup,
          group,
          checkInDate,
          checkOutDate,
          roomBookings,
          addons,
          bookingNotes,
          status
        } = bookingDetailsData.bookingByID

        setStatus(status)
        setCheckInDate(checkInDate)
        setCheckOutDate(checkOutDate)
        setAddons(addons)
        setBookingNotes(bookingNotes)
        setRoom(roomBookings[0]?.room)
        const { groupId, name: groupName } = group
        setGroupName(groupName) 
        setGroupId(groupId) 
        newCustomer.userId = tenantGroup?.tenant.user.userId || ''
        newCustomer.tenantId = tenantGroup?.tenant.tenantId || ''
        newCustomer.fullName = fullName
        newCustomer.email = email
        newCustomer.phoneNumber = phoneNumber
        newCustomer.billingAddress = tenantGroup?.tenant.user.address || ''
        //FIXME: document value
        newCustomer.travelDocumentNumber = ''
        newCustomer.travelDocumentType = '' 
        dispatch(setCustomerInfo(newCustomer))
        getGroupDetailsByGroupId({
          variables: { groupId: groupId}
        })
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const [customerInfoError, setCustomerInfoError] = useState({})
  const { updateCustomerInfo } = useCustomerForm(setCustomerInfoError)

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const handleSaveChanges = () => {
    updateCustomerInfo()
  }

  return (
    <>
      <Helmet>
        <title>Booking Details - Rook Hotel PMS</title>
      </Helmet>
      <Header
        subHeader={
          <>
            <div className='title'>
              <span
                className='highlight'
                onClick={() => {
                  history.push('/bookings')
                }}
              >
                Bookings
              </span>
              {` > ${bookingId} ${customerInfo.fullName || ''}`} 
              <StatusBar status={status}/>
            </div>
            <div className='buttonContainer'>
              <div>
                <Button
                  mainPage
                  additionalstyle={`
                    width: 86px;
                  `}
                  onClick={() => {
                    //TODO: add function to save changes
                  }}
                >
                  Check In
                </Button>
                <ModalCheckOutConfirm
                  buttonLabel={"Check Out"}
                  buttonStyle={`
                    width: 86px;
                    margin-left: 8px;
                  `}
                  isOpen={checkOutOpen}
                  setIsOpen={setCheckOutOpen}
                  checkOutBookings={[bookingDetailsData.bookingByID]}
                />
                <ModalBookingCancel
                  mainPage
                  danger
                  checkedIn={status === 'CHECKED_IN'}
                  buttonLabel={'Cancel This Room'}
                  buttonStyle={`
                    width: 134px;
                    margin-left: 8px;
                  `}
                  setCancelRoomParams={setCancelRoomParams}
                  setConfirmIsOpen={setConfirmIsOpen}
                />
              </div>
            </div>
          </>
        }
      />
      <ModalCancelConfirm
        isOpen={confirmIsOpen}
        setIsOpen={setConfirmIsOpen}
        cancelRoomParams={cancelRoomParams}
        setCancelRoomParams={setCancelRoomParams}
        roomNumber={room?.roomNumber}
        bookingId={bookingId}
        refetch={refetch}
      />
      <Content>
        <ContentColumn>
          <Container mobile>
            <GroupBookingInfo
              groupId={groupId}
              tenantList={tenantList}
              groupName={groupName}
              setGroupName={setGroupName}
            />
          </Container>
          <Container>
            <GuestInformation
              mini
              customerInfoError={customerInfoError}
              setCustomerInfoError={setCustomerInfoError}
              btnInfo={
                <>
                  <Button
                    mainPage
                    onClick={() => {
                      history.push(`/tenant-details/${customerInfo.tenantId}`)
                    }}
                    additionalstyle={`
                      width: 132px;
                      margin-right: 16px;
                    `}
                    hidden={!customerInfo.tenantId}
                  >
                    Guest Profile
                  </Button>
                  <Button
                    mainPage
                    success
                    onClick={handleSaveChanges}
                    additionalstyle={`
                      width: 113px;
                    `}
                  >
                    Save Changes
                  </Button>
                </>
              }
            />
          </Container>
          <Container>
            <TravelArrangements
              bookingId={bookingId}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              setCheckInDate={setCheckInDate}
              setCheckOutDate={setCheckOutDate}
            />
          </Container>
          <Container>
            <Room
              room={room}
              bookingId={bookingId}
              refetch={refetch}
            />
          </Container>
          <Container desktop>
            <GroupBookingInfo
              groupId={groupId}
              tenantName={customerInfo.fullName}
              tenantList={tenantList}
              groupName={groupName}
              setGroupName={setGroupName}
            />
          </Container>
        </ContentColumn>
        <ContentColumn>
          <Container>
            <AddOns
              bookingId={bookingId}
              addons={addons}
            />
          </Container>
          <Container>
            <GeneralBookingNotes
              notes={bookingNotes}
              bookingId={bookingId}
              headerString={'GENERAL BOOKING NOTES'}
              refetch={()=>{
                refetch().then((bookingDetailsData) => {
                  setBookingNotes(bookingDetailsData.data.bookingByID?.bookingNotes)
                })
              }}
            />
          </Container>
          <Container>
            <MetaInformation
              created={Date.now()}
              origin={"Booking.com"}
              confirmationNumber={"#########"}
              channel={"Lorem Ipsum"}
              campaignTag={"Christmas Promo 2020"}
            />
          </Container>
          <Container>
            <ActivityHistory bookingId={bookingId}/>
          </Container>
        </ContentColumn>
      </Content>
      <FooterBar />
    </>
  );
};

export default BookingDetails;
