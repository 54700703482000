import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  position: absolute;
  padding: 2px;
  top: 0;
  left: ${({ left }) => left? left: '55px'};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .switch {
    width: 26px;
    height: 14px;
    border: 2px solid ${colors.grey27};
    background-color: ${colors.grey18};
    border-radius: 7px;
    cursor: pointer;

    .switchSlider {
      width: 6px;
      height: 6px;
      position: relative;
      top: calc(50% - 10px);
      left: 2px;
      transition: transform 0.3s ease;
    }

    .switchSlider.active {
      transform: translateX(13px);
    }
  }

  .switch.active {
    border: 2px solid ${colors.green1};
    background-color: ${colors.green9};
  }

  .switchText {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2.5px;
    line-height: 14px;
    color: ${colors.grey5};
    margin-left: 5px;
    transition: color 0.3s ease;
  }

  .switchText.active {
    color: ${colors.green1};
  }
`;
