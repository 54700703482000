import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import ModalVoidConfirm from 'screens/ModalVoidConfirm';
import { useMutation } from '@apollo/client';
import { CREATE_CHARGE } from 'graphql/service';
import { Head, Container, Footer } from './styles';

const ModalVoid = ({
  hidden,
  buttonStyle,
  transactionId,
  cardNumber,
  amount,
  refetch
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [voidDetails, setVoidDetails] = useState('')
  const [textAreaError, setTextAreaError] = useState(false)

  const handleAddCharge = () => {
    if(voidDetails === '') {
      setTextAreaError(true)
      return
    }
    setIsOpen(false)
    setConfirmIsOpen(true)
  }
  
  return (
    <>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="modal-success"
        buttonLabel="Void"
        buttonStyle={buttonStyle}
        hidden={hidden}
        additionalstyle={`
          max-width: 500px;
          .modal-content{
            .close {
              z-index: 200;
            }          
            .modal-body {
              height: 100%;
              padding: 24px;
            }
          }
          top: 139px;
        `}
      >
        <Head>Void</Head>
        <Container>
          <TextArea      
            title="VOID DETAILS"
            required
            rows={4}
            error={textAreaError}
            value={voidDetails}
            onChange={(e) => {
              setVoidDetails(e.target.value)
              setTextAreaError(false)
            }}
            additionalstyle={`
              flex-basis: 100%;
            `}
          />
        </Container>
        <Footer>
          <Button
            success
            additionalstyle={`
              width: 84px;
              margin-top: 25px;
            `}
            onClick={handleAddCharge}
          >
            Continue
          </Button>
        </Footer>
      </MainModal>
      <ModalVoidConfirm
        isOpen={confirmIsOpen}
        setIsOpen={setConfirmIsOpen}
        transactionId={transactionId}
        cardNumber={cardNumber}
        amount={amount}
        voidDetails={voidDetails}
        setVoidDetails={setVoidDetails}
        refetch={refetch}
      />
    </>
  )
}

export default ModalVoid;
