import React from 'react';
import { ModalBody } from 'reactstrap'
import { StyledModal, Container } from './styles'
import Button from 'components/Button'

const ModalMain = ({
  isOpen,
  setIsOpen,
  setClose,
  className,
  children,
  additionalstyle,
  danger,
  success,
  buttonIcon,
  buttonLabel,
  buttonStyle,
  hidden,
  cleanFunction
}) => {

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    if(cleanFunction) {
      cleanFunction()
    }
    if(setClose) {
      setClose()
    } else {
      setIsOpen(false)
    }
  }

  return (
    <Container hidden={hidden}>
      {buttonLabel? (
        <Button
          mainPage
          danger={danger}
          success={success}
          hidden={!buttonLabel}
          onClick={openModal}
          additionalstyle={buttonStyle}
        >
          {buttonLabel}
        </Button>
      ) : buttonIcon? (
        <div
          className="icon"
          onClick={openModal}
        >  
          {buttonIcon}
        </div>
      ): null}
      <StyledModal
        isOpen={isOpen}
        className={className}
        additionalstyle={additionalstyle}
      >
        <div className="close" onClick={closeModal} />
        <ModalBody>{children}</ModalBody>
      </StyledModal>
    </Container>
  );
};

export default ModalMain;
