import styled from 'styled-components';
import { Modal } from 'reactstrap';
import cross from 'assets/svg/cross.svg';

export const Container = styled.div`
  display: ${(props) => (props.hidden ? 'none' : null)};
`;

export const StyledModal = styled(Modal)`

  .modal-content {
    position: relative;

    .close {
      width: 14px;
      height: 14px;
      position: absolute;
      right: 16px;
      top: 16px;
      background-image: url(${cross});
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  ${(props) => props.additionalstyle};
`;
