import React from 'react';
import { ItemRendererContainer } from '../styles';
import IncreaseArrow from 'assets/icons/increaseArrow.svg'
import DecreaseArrow from 'assets/icons/decreaseArrow.svg'
import moment from 'moment';

const ItemRenderer = ({ itemContext, getItemProps }) => {
  const [ a, b, c, d ] = itemContext.title.split(' ')
  
  return (
    <div
      {...getItemProps({
        style: {
          background: 'transparent !important',
          border: 'transparent !important',
        },
      })}
    >
      <div className="rct-item-content" style={{ height: '70px'}}>
        <ItemRendererContainer today={moment().format('M/DD/YYYY')===itemContext.dimensions.collisionLeft.format('M/DD/YYYY')}>
          <div className="row">
            <div className='text'>{a}</div>
            <div className="textHighlight">{b}</div>
          </div>
          <div className="row">
            <div className='text'>{c}</div>
            {
              d ?
              <div className="increase">
                <img src={IncreaseArrow} alt={'increaseArrow'}/> {d}
              </div>: 
              <div className="decrease">
                <img src={DecreaseArrow} alt={'decreaseArrow'} /> {d}
              </div>
            }
          </div>
        </ItemRendererContainer>
      </div>
    </div>
  );
};

export default ItemRenderer;
