import React, { useState } from "react";
import Card from "components/Card";
import CardHeader from "components/CardHeader";
import ModalAddCredit from "screens/ModalAddCredit";
import ModalAddCharge from "screens/ModalAddCharge";
import ModalDownloadReceipt from "screens/ModalDownloadReceipt";
import ModalVoid from "screens/ModalVoid";
import ModalRefund from "screens/ModalRefund";
import BillTooltip from "components/BillTooltip";
import TransactionTooltip from "components/TransactionTooltip";
import Input from "components/Input";
import Button from "components/Button";
import { useMutation } from "@apollo/client";
import { APPLY_COUPON_TO_GROUP } from "graphql/service";
import {
  BillOfSaleContainer,
  BillItemContainer,
  ItemContainer,
  Warning,
  CouponContainer,
} from "./styles";
import moment from "moment";
import { message } from "antd";
import { getMessageTimeOut } from "utils/util";

const BillOfSale = ({ bill, primaryCard, refetch, groupId }) => {
  const billArray = [];
  const transactionArray = [];

  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState(false);

  const [applyCouponToGroup] = useMutation(APPLY_COUPON_TO_GROUP, {
    onCompleted: (data) => {
      if (data.applyCoupon) {
        message.success("Coupon applied successfully");
        refetch();
      } else {
        message.error("Coupon could not be applied");
      }
    },
    onError: (error) => {
      let content = error.message;
      if (error.message.includes("Validation")) {
        content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception);
      }

      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy();
        },
      });
    },
  });

  const handleApplyCoupon = () => {
    if (couponCode) {
      applyCouponToGroup({
        variables: {
          input: {
            groupId,
            couponCode,
          },
        },
      });
    } else {
      setCouponError(true);
    }
  };

  bill.rooms?.forEach((room) => {
    const nights = room.nights;
    const roomType = room.roomType;
    billArray.push(
      <ItemContainer key={room.roomId}>
        <div className="description">
          {`1 ${room.roomType?.name} -
          ${nights + (nights > 1 ? "nights," : "night, ")}
            ${roomType?.bed || "Queen bed"} w/ ${
            roomType?.hasWindows ? "window" : "skylight"
          }`}
        </div>
        <div className="value">{"$" + room.totalPrice.toFixed(2)}</div>
      </ItemContainer>
    );
  });

  const ratePlan = bill.discounts?.find((discount) => discount.ratePlan);
  if (ratePlan) {
    billArray.push(
      <ItemContainer key={"ratePlan"} className="ratePlan">
        <div className="description">{"Advanced Purchase (10% off)"}</div>
        <div className="value">{"-$" + ratePlan.amount.toFixed(2)}</div>
      </ItemContainer>
    );
  }

  const coupon = bill.discounts?.find((discount) => discount.coupon);
  if (coupon) {
    billArray.push(
      <ItemContainer key={"coupon"} className="coupon">
        <div className="description">
          {`Coupon Code (${coupon.coupon.couponCode})`}
        </div>
        <div className="value">{"-$" + coupon.amount.toFixed(2)}</div>
      </ItemContainer>
    );
  }

  billArray.push(
    <ItemContainer key={"MAT"}>
      <div className="description">{"Municipal Accommodation Tax (6%)"}</div>
      <div className="value">{"$" + bill.MAT?.toFixed(2)}</div>
    </ItemContainer>
  );

  const units = {
    Breakfast: "voucher",
    Parking: "night",
    "Early Checkin": "room",
    "Late Checkout": "room",
  };

  bill.addons?.forEach((addon) => {
    billArray.push(
      <ItemContainer key={addon.addOn?.addonId}>
        <div className="description">
          {addon.addon.title +
            ` (${addon.quantity} ${units[addon.addon.title]}${
              addon.quantity > 1 ? "s" : ""
            } @ $${addon.unitPrice.toFixed(2)}/${units[addon.addon.title]})`}
        </div>
        <div className="value">{"$" + addon.totalPrice.toFixed(2)}</div>
      </ItemContainer>
    );
  });

  const titles = {
    DISCOUNT: "Credit",
    CHARGE: "Charge",
  };

  bill.extraCharges?.forEach((extraCharge) => {
    billArray.push(
      <ItemContainer
        key={extraCharge.chargeId}
        className={extraCharge.type?.toLowerCase()}
      >
        <div className="description">
          {titles[extraCharge.type]}
          <BillTooltip
            createdAt={extraCharge.createdAt}
            createdBy={extraCharge.originator}
            type={titles[extraCharge.type]}
            reason={extraCharge.description}
            amount={extraCharge.amount}
          />
        </div>
        <div className="value">
          {(extraCharge.type === "CHARGE" ? "$" : "-$") +
            extraCharge.amount.toFixed(2)}
        </div>
      </ItemContainer>
    );
  });

  billArray.push(
    <ItemContainer key={"HST"}>
      <div className="description">{"HST (13%)"}</div>
      <div className="value">{"$" + bill.HST?.toFixed(2)}</div>
    </ItemContainer>
  );

  const formatString = (input) => {
    return input
      .split("_") // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      .join(" "); // Join the words with spaces
  };

  bill.transactions?.forEach((transaction) => {
    transactionArray.push(
      <ItemContainer
        key={transaction.transactionId}
        className={transaction.type?.toLowerCase()}
      >
        <div
          className={
            "description" +
            (transaction.type === "REFUND" ||
            transaction.type === "CREDIT" ||
            transaction.type === "AUTH_REVERSAL"
              ? " warning"
              : "")
          }
        >
          {formatString(transaction.purpose)}
          <TransactionTooltip
            createdAt={transaction.createdAt}
            amount={"$" + Number(transaction.amount).toFixed(2)}
            warning={transaction.type === "REFUND" ||
              transaction.type === "CREDIT" ||
              transaction.type === "AUTH_REVERSAL"}
            originator={transaction.originator}
            cardNumber={transaction.creditCardNumber?.slice(-4)}
            description={transaction.description}
            isVoided={transaction.isVoided}
            voidedAt={transaction.updatedAt}
            voidOriginator={transaction.voidOriginator}
            voidDescription={transaction.voidDescription}
          />
        </div>
        <div
          className={
            "value" +
            (transaction.type === "REFUND" ||
            transaction.type === "CREDIT" ||
            transaction.type === "AUTH_REVERSAL"
              ? " warning"
              : "")
          }
        >
          {transaction.type === "SALE" &&
          !transaction.isVoided &&
          moment(transaction.createdAt).add(7, "days") > moment() ? (
            <>
              <ModalVoid
                buttonStyle={`
                width: 60px;
              `}
                amount={transaction.amount}
                transactionId={transaction.transactionId}
                cardNumber={transaction.creditCardNumber}
                refetch={refetch}
              />
              <ModalRefund
                buttonStyle={`
                width: 78px;
                margin-left: 8px;
              `}
                transactionId={transaction.transactionId}
                cardNumber={transaction.creditCardNumber}
                max={transaction.amount}
                refetch={refetch}
              />
            </>
          ) : null}
          {transaction.type === "REFUND" &&
          !transaction.isVoided &&
          moment(transaction.createdAt).add(7, "days") > moment() ? (
            <ModalVoid
              buttonStyle={`
                width: 60px;
              `}
              amount={transaction.amount}
              transactionId={transaction.transactionId}
              cardNumber={transaction.creditCardNumber}
              refetch={refetch}
            />
          ) : null}
          <span
            className={"amount" + (transaction.isVoided ? " lineThrough" : "")}
          >
            {(transaction.type === "REFUND" ||
            transaction.type === "CREDIT" ||
            transaction.type === "AUTH_REVERSAL"
              ? "$"
              : "-$") + Number(transaction.amount).toFixed(2)}
          </span>
        </div>
      </ItemContainer>
    );
  });

  return (
    <Card
      additionalstyle={`

        .buttonContainer {
          margin-top: 0;
        }
          
        @media only screen and (max-width: 767px) {

          .type {
            width: 100%;
            margin-bottom: 10px; 
          }

          .buttonContainer {
            width: 100%;
            justify-content: space-between;
          }
          
          flex-direction: column;
          justify-content: center;
        }          
      `}
    >
      <CardHeader>
        <div className="type">BILL OF SALE</div>
        <div className="buttonContainer">
          <ModalDownloadReceipt
            buttonStyle={`
              width: 148px;
            `}
            buttonLabel="Download Receipt"
            groupId={groupId}
          />
          <ModalAddCredit
            buttonStyle={`
              width: 113px;
              margin-left: 8px;
            `}
            billId={bill.billId}
            cardNumber={primaryCard.transaction?.creditCardNumber}
            max={bill.total}
            e
            refetch={refetch}
          />
          <ModalAddCharge
            buttonStyle={`
              width: 113px;
              margin-left: 8px;
            `}
            billId={bill.billId}
            cardNumber={primaryCard.transaction?.creditCardNumber}
            refetch={refetch}
          />
        </div>
      </CardHeader>
      <BillItemContainer>{billArray}</BillItemContainer>
      <BillOfSaleContainer>
        <div className="total">TOTAL</div>
        <div className="value">{"$" + bill.total?.toFixed(2) || "$0:00"}</div>
      </BillOfSaleContainer>
      <BillItemContainer>
        <div className="title">TRANSACTIONS</div>
        {transactionArray.length ? (
          transactionArray
        ) : (
          <Warning>No transaction has been added.</Warning>
        )}
      </BillItemContainer>
      <BillOfSaleContainer>
        <div className="total">BALANCE OUTSTANDING</div>
        <div className="value">{"$" + bill.balance?.toFixed(2) || "$0:00"}</div>
      </BillOfSaleContainer>
      <CouponContainer>
        <Input
          mainPage
          placeholder="Enter coupon code"
          title="COUPON CODE"
          error={couponError}
          value={couponCode}
          onChange={(e) => {
            setCouponCode(e.target.value);
            setCouponError(false);
          }}
          additionalstyle={`
            flex-basis: 100%;
          `}
        />
        <Button
          mainPage
          additionalstyle={`
            width: 68px;
            margin-left: 16px;
            margin-top: 23px;
          `}
          onClick={handleApplyCoupon}
        >
          Apply
        </Button>
      </CouponContainer>
    </Card>
  );
};

export default BillOfSale;
