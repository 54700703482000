import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import ModalTransactionSuccessfully from 'screens/ModalTransactionSuccessfully';
import ModalTransactionFailed from 'screens/ModalTransactionFailed';
import { useMutation } from '@apollo/client';
import { VOID_TRANSACTION } from 'graphql/service';
import colors from 'constants/colors';
import { Container } from './styles';

const ModalVoid = ({
  isOpen,
  setIsOpen,
  transactionId,
  cardNumber,
  amount,
  voidDetails,
  setVoidDetails,
  refetch,
}) => {

    const [successfullyIsOpen, setSuccessfullyIsOpen] = useState(false)
    const [failedIsOpen, setFailedIsOpen] = useState(false)
    
    const [voidTransaction, {loading}] = useMutation(VOID_TRANSACTION, {
      onCompleted: () => {
        setSuccessfullyIsOpen(true)
        setIsOpen(false)
      },
      onError: (error) => {
        setFailedIsOpen(true)
        setIsOpen(false)
      }
    })

  const handleVoid = () => {
    if(loading) return
    voidTransaction({
      variables: {
        input: {
          transactionId,
          reason: voidDetails
        },
      },
    });
  }

  return (
    <>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="modal-success"
        additionalstyle={`
          max-width: 500px;
          .modal-content{
            height: 100%;
            .close {
              z-index: 200;
            }   
            .modal-body{
              height: 100%;
              padding: 24px;
            }
          }
          background: ${colors.grey3};
          top: 179px;
        `}
      >
        <Container>
          <div className='text'>{`Are you sure you want to void $${Number(amount).toFixed(2)} to the card ending in ${cardNumber?.slice(-4)}. Note that this action will result in the refund being cancelled, the original purchase will still be applied to the card.`}</div>
          <div className='buttonContainer'>
            <Button
              danger
              onClick={() => setIsOpen(false)}
              additionalstyle={`
                width: 79px;
                margin-right: 16px;
              `} 
            >
              Cancel
            </Button>
            <Button
              success
              loading={loading}
              onClick={handleVoid}
              additionalstyle={`
                width: 68px;
              `}
            >
              {loading? null: 'Void'}
            </Button>
          </div>
        </Container>
      </MainModal>
      <ModalTransactionSuccessfully
        isOpen={successfullyIsOpen}
        setIsOpen={setSuccessfullyIsOpen}
        amount={amount}
        cardNumber={cardNumber}
        setDetails={setVoidDetails}
        refetch={refetch}
        type='void'
      />
      <ModalTransactionFailed
        isOpen={failedIsOpen}
        setIsOpen={setFailedIsOpen}
        amount={amount}
        cardNumber={cardNumber}
        loading={loading}
        retryFunction={handleVoid}
        type='void'
      />
    </>
  );
}

export default ModalVoid;
