import React from 'react';
import { ProgressBarContainer, Item  } from './styles';
import { CheckInIcon, IdentityIcon, CreditCardIcon, KeyCardIcon } from 'assets/icons';

const ProgressBar = ({step}) => {

  return (
    <ProgressBarContainer>
      <Item highlight={step === 1}>
        <CheckInIcon />
        Early Checkin
      </Item>
      <hr className="divider" />
      <Item highlight={step === 2}>
        <IdentityIcon />
        Identification 
      </Item>
      <hr className="divider" />
      <Item highlight={step === 3}>
        <CreditCardIcon />
        Payment
      </Item>
      <hr className="divider" />
      <Item highlight={step === 4}>
        <KeyCardIcon />
        Print Key
      </Item>
    </ProgressBarContainer>
  )
}

export default ProgressBar;