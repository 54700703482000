import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: ${colors.green1};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 16px;
  margin: 24px 0 0 0;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .text {
    color: ${colors.white1};
    margin: 25px 0 15px 0;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 24px;

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;
