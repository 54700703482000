import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'components/Button';
import { Head, Container, Content, Footer } from './styles';

const EarlyCheckIn = () => {

  const history = useHistory()
  const groupId = useParams().groupId

  return (
    <Container>
      <Content>
        <Head>Something went wrong, please try again!</Head>
        <Footer>
          <Button
            additionalstyle={`
              width: 154px;
            `}
            onClick={() => history.push('/home')}
          >
            Back To Home
          </Button>
        </Footer>
      </Content>
    </Container>
  );
}

export default EarlyCheckIn;