import styled from 'styled-components';
import colors from 'constants/colors';

export const StatusBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.grey1};
  border-radius: 11px;
  font-size: ${({fontSize}) => (fontSize ? fontSize : '12px')};
  font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '600')};
  height: ${({height}) => (height ? height : '23px')};
  line-height: ${({height}) => (height ? height : '23px')};
  background:   ${({status}) =>  {
    switch(status) {
      case 'PENDING': return colors.orange5;
      case 'CHECKED_IN': return colors.green9;
      case 'CHECKED_OUT': return colors.grey30;
      case 'CANCELLED': return colors.red2;
      case 'EXPIRED': return colors.red2;
      default: return colors.orange5;
    }}
  };
  color: ${({color}) => (color? color: colors.white1)};
  white-space: nowrap;
  letter-spacing: 0.5px;
  margin: ${({margin}) => (margin ? margin : '0 8px 0 15px')};
  padding: 0 11px;
`;
