import styled from 'styled-components';
import colors from 'constants/colors';

export const LegendContainer = styled.div`
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 130px;
  box-sizing: border-box;

  .legendRow {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }
`;

export const LegendItemContainer = styled.div`
  width: 60px;
  height: 55px;

  .iconContainer {
    width: 30px;
    height: 30px;
    background-color: ${(props) => props.backgroundColor ? props.backgroundColor : colors.white1}; 
    margin: 10px auto;
    padding: 3px 6px;
  }

  .iconLayout {
    width: 18px;
    height: 18px;
  }

  .legendTitle {
    width: 100%;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
    color: ${colors.grey5};
  }
`;
