import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`

  .title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 25px 0 0 50px;
  }

  table {
    border-collapse: collapse;
    color: ${colors.grey5};
    background-color: ${colors.grey2};
    margin: 30px 50px 50px 50px;

    tr {
      border-bottom: 1px solid ${colors.grey8};
    }

    th {
      color: ${colors.grey7};
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 2.5px;
    }

    .sign {
      width: 70px;
    }
    .dateTime {
      width: 160px;
    }
    .type {
      width: 80px;
    }
    .agent {
      width: 120px;
    }
    .details {
      min-width: 700px;
    }
    .playback {
      width: 75px;
    }

    td{
      color: ${colors.grey5};
      font-size: 14px;
      padding-top: 8px;
      padding-bottom: 8px;
      vertical-align: top;
      :nth-child(1) {
        padding-left: 25px;
      }
      :nth-child(4) {
        color: ${colors.white1};
        font-weight: 600;
      }
      :nth-child(5) {
        padding-right: 50px;
      }
    }

    .highlight > td {
      color: ${colors.white1};
      font-weight: bold;
      padding-top: 15px;
      padding-bottom: 15px;
    }     
  }
`;

export const ChatItem = styled.div`
  display: flex;
  
  .chatItem {
    display: flex;
    border-radius: 25px;
    border: 1px solid ${(props) => props.color};
    margin-left: -10px;
    margin-right: 50px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: ${colors.grey2};
  }

  .chatAgent {
    display: inline-block;
    color: ${(props) => props.color};
    min-width: 120px;
    width: 120px;
  }

  .chatDetails {
    display: inline-block;
    font-weight: 400;
  }
`;

export const PlayContainer = styled.div`
  & > svg {
    top: -1px;
    position: relative;
    margin-right: 3px;
  }
`;
