import React, { useState } from "react";
import MainModal from "components/ModalMain";
import Button from "components/Button";
import Input from "components/Input";
import Dropdown from "components/Dropdown";
import PhoneNumberInput from "components/PhoneNumberInput";
import ModalTransactionSuccessfully from "screens/ModalTransactionSuccessfully";
import ModalTransactionFailed from "screens/ModalTransactionFailed";
import { usePlacesWidget } from "react-google-autocomplete";
import { useMutation } from "@apollo/client";
import { CREATE_STAFF } from "graphql/service";
import colors from "constants/colors";
import { Container, Footer, Head, Row } from "./styles";
import { message } from "antd";
import { getMessageTimeOut } from "utils/util";

const ModalStaffInformation = ({
  isOpen,
  setIsOpen,
  roleId,
  role,
  refetch
}) => {

  const [successfullyIsOpen, setSuccessfullyIsOpen] = useState(false);
  const [failedIsOpen, setFailedIsOpen] = useState(false);

  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("- Select -");

  const [staffInfoError, setStaffInfoError] = useState({
    fullNameError: false,
    addressError: false,
    emailError: false,
    phoneNumberError: false,
    statusError: false,
  });

  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_APIKEY,
    onPlaceSelected: (place) => {
      setAddress(place.formatted_address);
    },
    options: {
      types: ["address"],
    },
  });

  const [createStaff] = useMutation(CREATE_STAFF, {
    onCompleted: (data) => {
      if (data.createStaff.id) {
        setIsOpen(false);
        setSuccessfullyIsOpen(true);
      } else {
        setFailedIsOpen(true);
      }
    },
    onError: (error) => {
      message.error(getMessageTimeOut(error.message));
    },
  });

  const handleContinue = () => {
    createStaff({
      variables: {
        input: {
          fullName,
          address,
          email,
          phoneNumber,
          status,
          roleId,
        },
      },
    });
  };

  return (
    <>
      <MainModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hidden={true}
        className="modal-success"
        additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            height: 100%;
            padding: 24px;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
      >
        <Container>
          <Head>Staff Details</Head>
          <Row>
            <Input
              mainPage
              title="NAME"
              value={fullName}
              error={staffInfoError.fullNameError}
              onChange={(e) => {
                setFullName(e.target.value);
                setStaffInfoError({ ...staffInfoError, nameError: false });
              }}
              additionalstyle={`
              width: 48%;
            `}
            />
          </Row>
          <Row>
            <Input
              mainPage
              inputFieldRef={ref}
              title="ADDRESS"
              value={address}
              error={staffInfoError.addressError}
              onChange={(e) => {
                setAddress(e.target.value);
                setStaffInfoError({ ...staffInfoError, addressError: false });
              }}
              additionalstyle={`
              width: 100%;
            `}
            />
          </Row>
          <Row>
            <Input
              mainPage
              title="EMAIL"
              value={email}
              error={staffInfoError.emailError}
              onChange={(e) => {
                setEmail(e.target.value);
                setStaffInfoError({ ...staffInfoError, emailError: false });
              }}
              additionalstyle={`
              width: 50%;
            `}
            />
            <PhoneNumberInput
              mainPage
              title="PHONE"
              initialValue={phoneNumber}
              onChangeNumber={setPhoneNumber}
              setPhoneNumberValidity={setPhoneNumberValidity}
              onBlur={() => {
                if (!phoneNumberValidity && phoneNumber.length > 0) {
                  setStaffInfoError({
                    ...staffInfoError,
                    phoneNumberError: true,
                  });
                }
              }}
              additionalstyle={`
              width: 50%;
              @media only screen and (max-width: 767px) {
                width: 100%;
              }
            `}
            />
          </Row>
          <Row>
            <Input
              mainPage
              title="ROLE"
              value={role}
              readOnly
              additionalstyle={`
              width: 50%;
            `}
            />
            <Dropdown
              mainPage
              title="STATUS"
              options={["ACTIVE", "INACTIVE"]}
              value={status}
              error={staffInfoError.statusError}
              onChange={(e) => {
                setStatus(e.target.value);
                setStaffInfoError({ ...staffInfoError, statusError: false });
              }}
              additionalstyle={`
              width: 50%;
            `}
            />
          </Row>
        </Container>
        <Footer>
          <div className="buttonContainer">
            <Button
              danger
              additionalstyle={`
              width: 79px;
              margin-right: 16px;
            `}
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              success
              additionalstyle={`
              width: 97px;
            `}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </Footer>
      </MainModal>
      <ModalTransactionSuccessfully
        isOpen={successfullyIsOpen}
        setIsOpen={setSuccessfullyIsOpen}
        refetch={refetch}
        role={role}
        fullName={fullName}
        type="createStaff"
      />
      <ModalTransactionFailed
        isOpen={failedIsOpen}
        setIsOpen={setFailedIsOpen}
        retryFunction={handleContinue}
        type="createStaff"
      />
    </>
  );
};

export default ModalStaffInformation;
