import styled from 'styled-components';
import colors from 'constants/colors';

export const Row = styled.div`
  width: 100%;
  display: flex;
`;

export const TravelInputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  column-gap: 10px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    row-gap: 25px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    width: 50%;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  span {
    display: block;
    margin-bottom: 7px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2.5px;
    color: ${colors.grey5};
    white-space: nowrap;
  }

  .datePickerContainer {
    width: 55%;
  }
  
  .timePickerContainer {
    width: 45%;
  }
`;

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0;
  column-gap: 10px;

  .price{
    width: 60%;
    margin: 0 15px;

    .highlight {
      color: ${colors.red1};
      font-style: italic;
    }

    div:nth-child(1) {
      font-size: 10px;
      font-weight: 600;
      color: ${colors.grey5};
    }
    div:nth-child(2) {
      height: 40px;
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 600;
      color: ${colors.grey5};
    }    
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    row-gap: 25px;
    
    .price {
      margin-left: 0;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    width: 40%;

    @media only screen and (max-width: 767px) {
      justify-content: space-between;
      width: 100%;
    }
  }
`;