import React from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import Button from 'components/Button';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOKING_BY_BOOKING_ID } from 'graphql/service';
import { TravelInputRow } from './styles';
import colors from 'constants/colors';
import moment from 'moment';
import { message } from 'antd';

const TravelArrangements = ({ 
  disabled,
  bookingId,
  checkInDate,
  checkOutDate,
  setCheckInDate,
  setCheckOutDate,
}) => {

  const [updateBookingByBooingId] = useMutation(UPDATE_BOOKING_BY_BOOKING_ID, {
    onCompleted: () => {
      message.success('Changes saved successfully')
    },
    onError: (error) => {
      message.error(error.message)
    }
  })

  const handleSaveChanges = () => {
    updateBookingByBooingId({
      variables: {
        bookingId,
        input: {
          checkInDate: checkInDate,
          checkOutDate: checkOutDate,
        }
      }
    })
  }
  
  return (
    <Card>
      <CardHeader>
        <div className='type'>TRAVEL ARRANGEMENTS</div>
      </CardHeader>
      <TravelInputRow>
        <div>
          <div className="datePickerContainer">
            <span>ARRIVAL</span>
            <DatePicker
              disabled={disabled}
              error={checkInDate? moment(checkInDate) >= moment(checkOutDate): false}
              minDate={moment(new Date())}
              dateValue={moment(checkInDate)}
              dateOnChange={setCheckInDate}
              additionalstyle={`
                width: 100%;
                min-width: 130px;
                border: 1px solid ${colors.grey1};

                input{
                  padding-left: 40px;
                  padding-right: 0px;
                }

                ${disabled?`
                  border: none;

                  input {
                    padding-left: 0;
                  }
                  
                  button {
                    display: none;
                  }
                `: null}
              `}
            />
          </div>
          <div className="timePickerContainer">
            <span>ARRIVAL TIME</span>
            <TimePicker
              disabled={disabled}
              TimeValue={moment(checkInDate)}
              setTime={setCheckInDate}
              additionalstyle={`
                width: 100%;
                min-width: 110px;
                border: 1px solid ${colors.grey1};

                input{
                  padding-left: 40px;
                  padding-right: 0px;
                }

                ${disabled?`
                  border: none;

                  input {
                    padding-left: 0;
                  }

                  button {
                    display: none;
                  }
                `: null}
              `}
            />
          </div>
        </div>
        <div>
          <div className="datePickerContainer">
            <span>DEPARTURE</span>
            <DatePicker
              disabled={disabled}
              error={checkInDate? moment(checkInDate) >= moment(checkOutDate): false}
              minDate={moment(new Date())}
              dateValue={moment(checkOutDate)}
              dateOnChange={setCheckOutDate}
              additionalstyle={`
                width: 100%;
                min-width: 130px;
                border: 1px solid ${colors.grey1};

                input{
                  padding-left: 40px;
                  padding-right: 0px;
                }

                ${disabled?`
                  border: none;

                  input {
                    padding-left: 0;
                  }

                  button {
                    display: none;
                  }
                `: null}
              `}
            />
          </div>
          <div className="timePickerContainer">
            <span>DEPARTURE TIME</span>
            <TimePicker
              disabled={disabled}
              TimeValue={moment(checkOutDate)}
              setTime={setCheckOutDate}
              additionalstyle={`
                width: 100%;
                min-width: 110px;
                border: 1px solid ${colors.grey1};

                input{
                  padding-left: 40px;
                  padding-right: 0px;
                }

                ${disabled?`
                  border: none;

                  input {
                    padding-left: 0;
                  }

                  button {
                    display: none;
                  }
                `: null}
              `}
            />
          </div>
        </div>
      </TravelInputRow>
      <div className='buttonContainer'>
        {!disabled?
          <Button
            mainPage
            success
            onClick={handleSaveChanges}
            additionalstyle={`
              margin-left: 14px;
              width: 113px;
            `}
          >
            Save Changes
          </Button>: null
        }
      </div>
    </Card>
  );
};

export default TravelArrangements;
