import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useWindowDimensions from 'customHook/useWindowDimensions';
import moment from 'moment';

const useTimeline = () => {

  const { width } = useWindowDimensions();

  const pickedDate = useSelector(state => state.pickedDateReducer.pickedDate)

  const [state, setState] = useState({
    defaultTimeStart: moment().startOf('day').toDate(),
    defaultTimeEnd: moment().endOf('day').toDate(),
    visibleTimeStart: moment(pickedDate)
      .startOf('week')
      .toDate()
      .valueOf(),
    visibleTimeEnd: moment(pickedDate)      
      .startOf('week')
      .add((width > 1200? 10: 5), 'day')
      .toDate()
      .valueOf(),
    today: moment(pickedDate).startOf('day').valueOf(),
    endToday: moment(pickedDate).endOf('day').valueOf(),
  });

  useEffect(() => {
    const defaultTimeStart = moment().startOf('day').toDate();
    const defaultTimeEnd = moment().endOf('day').toDate();
    const visibleTimeStart = moment(pickedDate)
      .startOf('week')
      .toDate()
      .valueOf();
    const visibleTimeEnd = moment(pickedDate)
      .startOf('week')
      .add((width > 1200? 10: 5), 'day')
      .toDate()
      .valueOf();
    const today = moment(pickedDate).startOf('day').valueOf();
    const endToday = moment(pickedDate).endOf('day').valueOf();
    setState({
      defaultTimeStart,
      defaultTimeEnd,
      visibleTimeStart,
      visibleTimeEnd,
      today,
      endToday,
    });
  }
  ,[pickedDate, width]);

  const generateBookingData = (roomsData, bookingsData) => {
    const items = [];
    let groups = {};

    if (bookingsData?.validBookings) {
      bookingsData.validBookings.forEach((booking, index) => {
        items.push({
          id: booking.bookingId,
          group: booking.roomBookings?.[0]?.room?.roomNumber,
          title: booking.group?.name.length > 0? booking.group.name: booking.fullName,
          start: moment(booking.checkInDate),
          end: moment(booking.checkOutDate),
          canMove: true,
          canResize: false,
          canChangeGroup: true,
        });
      });
    }

    if (roomsData?.validRooms) {
      const sortedRooms = [...roomsData.validRooms].sort((a,b) => Number(a.roomNumber)-Number(b.roomNumber))
      sortedRooms.forEach((room, index) => {
        if(room.roomType.name === 'Amenities') {
          return
        }
        groups[room.roomType.name] = groups[room.roomType.name] || []
        groups[room.roomType.name].push({
          id: room.roomNumber,
          roomId: room.roomId,
          status: room.roomStatusHistories?.[0]?.status,
          height: 32
        })
      })
    }
    return { groups, items };
  };

  useEffect(() => {
    //disable timeline horizontal scroll
    if (document.querySelector('.rct-scroll')) {
      document.querySelector('.rct-scroll').style.overflowX = 'hidden';
    }
  },[]);

  return {
    timelineState: {
      state,
      setState,
    },
    generateBookingData,
  };
};

export default useTimeline;
