import { createSlice } from '@reduxjs/toolkit';

const floorChoiceSlice = createSlice({
  name: 'floorChoice',
  initialState: { 
    floorChoice: 8,
  }, 
  reducers: {
    setFloorChoice: (state, action) => {
      return { ...state, floorChoice: action.payload }
    }
  },
});

export const { setFloorChoice } = floorChoiceSlice.actions;
export default floorChoiceSlice.reducer;