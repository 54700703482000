import React, { useState } from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Note from 'components/Note';
import { GET_EVENT_HISTORY_BY_BOOKING_ID } from 'graphql/service';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ActivityHistory = ({ btnHidden, bookingId }) => {

  const [eventHistory, setEventHistory] = useState([])
  useQuery(GET_EVENT_HISTORY_BY_BOOKING_ID, {
    variables: { bookingId: bookingId },
    onCompleted: (eventHistoryData)=>{
      if(eventHistoryData) {
        setEventHistory(eventHistoryData.eventHistoryByBookingID)
      }      
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  return (
    <Card>
      <CardHeader>
        <div className="type">ACTIVITY HISTORY</div>
      </CardHeader>
      <div>
        {eventHistory.length ? (
          eventHistory.map((event) => {
            return (
              <Note
                key={event.eventHistoryId}
                date={moment(event.createdAt).format("M/D/YYYY h:mm A")}
                description={event.description}
                disabled
              />
            );
          })
        ) : (
          <Note date={""} description={"No activity history found"} disabled />
        )}
      </div>
    </Card>
  );
};

export default ActivityHistory;
