import React from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import { SuccessIcon } from 'assets/icons';
import { Container } from './styles';

const ModalResetPasswordSent = ({
  isOpen,
  setIsOpen,
  type
}) => {

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={closeModal}
      className="modal-success"
      additionalstyle={`
        max-width: 500px;
        .modal-content{
          height: 100%;
          .close {
            z-index: 200;
          }   
          .modal-body{
            height: 100%;
            padding: 24px;
          }
        }
        top: 179px;
      `}
    >
      <Container>
        <SuccessIcon />
        <div className='text'>{`An ${type==='email'? 'email': 'text message'} has been sent.`}</div>
        <Button
          success
          onClick={closeModal}
          additionalstyle={`
            width: 116px;
            margin-top: 24px;
          `} 
        >
          OK
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalResetPasswordSent;
