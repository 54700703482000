import styled from 'styled-components';
import colors from 'constants/colors';

export const ItemRendererContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-size: 14px;
  color: ${colors.grey5};

  .row {
    width: 100%;
    line-height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    .increase {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      font-weight: bold;
      color: ${colors.green2};
    }

    .decrease {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      font-weight: bold;
      color: ${colors.red4};
    }

    .textHighlight {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      font-weight: bold;
      color: ${colors.white1};
    }

    .text {
      width: 50%;
      color: ${(props) => props.today ? colors.white1: null};
    }
  }
`;

export const SideBarItemContainer = styled.div`

  cursor: pointer;
  height: ${(props) => props.rowHeight ? props.rowHeight + 'px' : '70px'};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.grey5};
`;

export const SubHeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: black;
  color: ${colors.white1};
  padding-left: 10px;

  .contentContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;

    .dateSection {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 170px;
    }
  }
`;

