import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'components/Header';
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { SEARCH } from 'graphql/service';
import useWindowDimensions from 'customHook/useWindowDimensions';
import { SearchResultsContainer, Container, StyledInput, StyledList, StyledItem, Navigator, NavigatorContainer } from './styles';

const sleep = (delay = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const SearchResults = ({mobile, open}) => {

  const location = useLocation()
  const { width, height } = useWindowDimensions();
  const [ loading, setLoading ] = useState(false)
  const [ keyword, setKeyword ] = useState(location.state?.keyword)
  const [ options, setOptions ] = useState([])
  const [searchKeyword] = useLazyQuery(SEARCH)

  let pageSize
  if(width>1200) {
    pageSize = Math.floor((height - 380)/46)
  } else if(width<=1200 && width>=768) {
    pageSize = Math.floor((height - 375)/46)
  } else {
    pageSize = Math.floor((height - 345)/46)
  }

  useEffect(()=>{
    inputHandler(location.state?.keyword)
  },[location.state?.keyword])

  const inputHandler = async (keyword) => {

    setKeyword(keyword)
    //convert input text to lower case
    const lowerCase = keyword?.toLowerCase() || '';

    // const result = await searchKeyword({
    //   variables: { input: lowerCase }
    // })
    const result = await sleep(500); 

    if (!result) {

      const optionArr = results.map((result, index) => {
        const newResult = {...result}
        const matches = match(result.info, lowerCase, {insideWords: true, findAllOccurrences: true, requireMatchAll: true});
        const parts = parse(result.info, matches);
        newResult.info = parts
        return newResult
      })

      setOptions([...optionArr]);
    }
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter') {
      if(e.target.value > 0 && e.target.value <= Math.ceil(options.length/pageSize)) {
        setPageNumber(e.target.value)
      } 
    }
  }

  const [pageNumber, setPageNumber] = useState(1)
  const [inputNumber, setInputNumber] = useState(pageNumber)

  const paginate = (array, page_size, page_number) => {
    const newArray = [...array]
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return newArray.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const previousPage = () => {
    setPageNumber(pageNumber-1)
    setInputNumber(pageNumber-1)
  }

  const nextPage = () => {
    setPageNumber(pageNumber+1)
    setInputNumber(pageNumber+1)
    
  }

  const resultArray = paginate(options, pageSize, pageNumber).map((item, index)=> {

    const result = item.info.map((str, index) => {

      return (
        <span
          key={index}
          className={str.highlight? 'highlight': null}
        >
          {str.text}
        </span>
      )
    })
    
    return (
      <StyledItem
        key={index}
        onClick={()=>{
          //TODO: redirect to the page
        }}
      >
        <span className='type'>{item.type}: </span>
        {result}
      </StyledItem>
    )
  })

  return (
    <>
      <Header mobile={mobile} open={open}/>
      <SearchResultsContainer mobile={mobile} open={open}>
        <Container>
          <StyledInput
            placeholder='Search...'
            onBlur={()=>{
            }}
            value={keyword}
            onChange={(e) => inputHandler(e.target.value)}  
          />
          <div className='result'>Search results for '{keyword}'</div>
          <div className='totalResults'>{options.length} RESULTS</div>
          {
            loading? 
              <CircularProgress
                style={{position: 'absolute',top: '81px', right: '440px'}}
                color="success"
                thickness={10}
                size={20}
              /> :
              <StyledList>
                {resultArray}
              </StyledList>
          }
          {
            options.length> pageSize ? 
              (
                <NavigatorContainer>
                  <Navigator
                    disabled={pageNumber<=1} 
                    onClick={previousPage}
                  >
                    {'<'} Back
                  </Navigator>
                  <div className='pageControl'>
                    <input
                      type='number'
                      value={inputNumber}
                      onChange={(e) => {
                        setInputNumber(e.target.value)
                      }}
                      onKeyDown={handleKeyDown} 
                    />
                    <div className='totalPages'> of {Math.ceil(options.length/pageSize)}</div>
                  </div>
                  <Navigator
                    disabled={pageNumber>= options.length/pageSize}
                    onClick={nextPage}
                  >
                    Next {'>'}
                  </Navigator>
                </NavigatorContainer>            
              ): null 
          }
        </Container>
      </SearchResultsContainer>
    </>
  );
}

export default SearchResults;

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const results = [
  { type: 'The Shawshank Redemption', info: '1994' },
  { type: 'The Godfather', info: '1972' },
  { type: 'The Godfather: Part II', info: '1974' },
  { type: 'The Dark Knight', info: '2008' },
  { type: '12 Angry Men', info: '1957' },
  { type: "Schindler's List", info: '1993' },
  { type: 'Pulp Fiction', info: '1994' },
  { type: 'The Shawshank Redemption', info: '1994' },
  { type: 'The Godfather', info: '1972' },
  { type: 'The Godfather: Part II', info: '1974' },
  { type: 'The Dark Knight', info: '2008' },
  { type: '12 Angry Men', info: '1957' },
  { type: "Schindler's List", info: '1993' },
  { type: 'Pulp Fiction', info: '1994' },
  { type: 'Pulp Fiction', info: '1994' },
  { type: 'The Shawshank Redemption', info: '1994' },
  { type: 'The Godfather', info: '1972' },
  { type: 'The Godfather: Part II', info: '1974' },
  { type: 'The Dark Knight', info: '2008' },
  { type: '12 Angry Men', info: '1957' },
  { type: "Schindler's List", info: '1993' },
  { type: 'Pulp Fiction', info: '1994' }
];