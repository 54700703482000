import React, { useState } from 'react';
import Card from 'components/Card';
import MessageItem from 'screens/Home/components/MessageItem';
import { Container, MessageContainer } from './styles';
import { useQuery } from '@apollo/client';
import { GET_ALL_NEW_MESSAGES } from 'graphql/service'
import moment from 'moment';
import { message } from 'antd';
import { getMessageTimeout } from 'utils/util';

const Messages = ({mobile}) => {

  // const [messageList, setMessageList] = useState()

  // const { data: messageData, loading, error } = useQuery(
  //   GET_ALL_NEW_MESSAGES,
  //   {
  //     pollInterval: 1200000,
  //     onCompleted: (messageData)=> {
  //       if(messageData) {
  //         const messageList = messageData.messages.map((message) => {
  //           const { 
  //             messageId,
  //             notes,
  //             createdAt,
  //             type,
  //             room: { 
  //               roomNumber
  //             },
  //             tenant: {
  //               tenantId,
  //               user: {
  //                 fullName
  //               }
  //             }
  //           } = message
  
  //           return (
  //             <MessageItem
  //               key={messageId}
  //               mobile={mobile}
  //               fullName={fullName}
  //               roomNumber={roomNumber}
  //               tenantId={tenantId}
  //               type={type}
  //               date={moment(createdAt).format('M/D/YYYY h:mm A')}
  //               message={notes}
  //             />
  //           )
  //         })
  //         setMessageList(messageList)
  //       }
  //     },
  //     onError: (error) => {
  //       let content = error.message
  //       if(error.message.includes('Validation')) {
  //           content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
  //       }
        
  //       message.error({
  //         content,
  //         duration: getMessageTimeout(content),
  //         onClick: () => {
  //           message.destroy()
  //         }
  //       })
  //     }
  //   }
  // )
  
  return (
    <Container mobile={mobile}>
      <Card>
        MESSAGES
      </Card>
      <MessageContainer mobile={mobile}>
        {
          //messageData? messageList:
            (
              <>
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
                <MessageItem
                  mobile={mobile}
                  fullName="Nancy Hachisu"
                  roomNumber={'825'}
                  tenantId={'981b25f5-8a30-4475-a383-8488e21863d0'}
                  type={'Text'}
                  date={'20/6/2020 7:48PM'}
                  message={'First line of message blah blah yada yada yada truncated…'}
                />
              </>
            )        
        }
        <div>There is nothing else here.</div>
      </MessageContainer>
    </Container>
  );
};

export default Messages;
