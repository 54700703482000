import React, { useState, useEffect } from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import Button from 'components/Button';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import { SUBSCRIBE_BOOKING_ADD_ONS } from 'graphql/service';
import { useMutation } from '@apollo/client';
import { AddOnContainer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const AddOns = ({ bookingId, addons }) => {

  const [addOns, setAddOns] = useState([
    {
      bookingId: bookingId,
      id: '63f6e8f8-8095-4c79-8d92-ce5812db4f15',
      quantity: 0
    },
    {
      bookingId: bookingId,
      id: 'fd67a4cb-667a-4ba9-bec4-379cb430682a',
      quantity: 0
    },
    {
      bookingId: bookingId,
      id: '4c773a2c-0d0a-44ba-8680-d34244fea4d5',
      quantity: 0
    },
    {
      bookingId: bookingId,
      id: 'c5032166-bd7f-4a8a-bc4b-81d5c7fc5ff5',
      quantity: 0
    }
  ])

  useEffect(() => {
    const originalAddOns = [...addOns]

    addons?.forEach((addon) => {
      const index = originalAddOns.findIndex((originalAddon) => originalAddon.id === addon.bookingAddOn.addonId)
      if(index !== -1) {
        originalAddOns[index].quantity = addon.quantity
      }
    })
    setAddOns(originalAddOns)
  }, [addons])

  const [subscribeBookingAddOns] = useMutation(SUBSCRIBE_BOOKING_ADD_ONS, {
    onCompleted: (data) => {
      message.success('Add-ons updated successfully');
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    },
  })

  const handleSaveChanges = () => {
    subscribeBookingAddOns({
      variables: {
        input: {
          addOns
        }
      }
    })
  }

  const breakfast = addOns[0]
  const parking = addOns[1]
  const earlyCheckin = addOns[2]
  const lateCheckout = addOns[3]

  return (
    <Card>
      <CardHeader>
        <div className='type'>ADD-ONS</div>
      </CardHeader>
      <AddOnContainer>
        <div className='addOnsInput'>
          <Input
            mainPage
            title="BREAKFAST"
            value={breakfast.quantity}
            onChange={(e) => {
              const inputValue = e.target.value
              const filteredValue = inputValue.replace(/[^0-9]/g, '');
              const newAddOns = [...addOns]
              newAddOns[0].quantity = Number(filteredValue)
              setAddOns(newAddOns)
            }}
            additionalstyle={`
              flex-basis: 15%;
              margin-right: 16px;
            `}
          />
          <Input
            mainPage
            title="PARKING"
            value={parking.quantity}
            onChange={(e) => {
              const inputValue = e.target.value
              const filteredValue = inputValue.replace(/[^0-9]/g, '');
              const newAddOns = [...addOns]
              newAddOns[1].quantity = Number(filteredValue)
              setAddOns(newAddOns)
            }}
            additionalstyle={`
              flex-basis: 15%;
            `}
          />
        </div>
        <div className='addOnsCheckbox'>
          <Checkbox
            key={'EarlyCheckIn'}
            checked={earlyCheckin.quantity > 0}
            onChange={() => {
              const newAddOns = [...addOns]
              newAddOns[2].quantity = earlyCheckin.quantity > 0 ? 0 : 1
              setAddOns(newAddOns)
            }}
            title={'Early Checkin'}
          />
          <Checkbox
            key={'Late Checkout'}
            checked={lateCheckout.quantity > 0}
            onChange={() => {
              const newAddOns = [...addOns]
              newAddOns[3].quantity = lateCheckout.quantity > 0 ? 0 : 1
              setAddOns(newAddOns)
            }}
            title={'Late Checkout'}
          />
        </div>
      </AddOnContainer>
      <div className='buttonContainer'>
        <Button
          mainPage
          success
          onClick={handleSaveChanges}
          additionalstyle={`
            margin-left: 14px;
            width: 113px;
          `}
        >
          Save Changes
        </Button>
      </div>
    </Card>
  );
};

export default AddOns;
